import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Show,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Announcement } from "types/apiTypes";
import createPersistedState from "use-persisted-state";
import { getRole } from "utils/functions";
import useStore from "utils/store";
import { api } from "../../config";
import AnnouncementModal from "./AnnouncementModal";

const ViewAnnouncements: React.FC = () => {
  const useCounterState = createPersistedState("sortQueryAnnouncement");
  const [sortQueryAnnouncement, setSortQueryAnnouncement]: any =
    useCounterState("id:asc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();
  const [openModal, setOpenModal] = React.useState(false);
  const [announcement, setAnnouncement] = React.useState<Announcement>();
  const isClient = getRole() === "Client";
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)");

  const setAnnouncementsCount = useStore(
    (state) => state.setAnnouncementsCount
  );

  const fetchAnnouncementsAndCount = async (
    start: number,
    limit: number,
    sort: any,
    announcementName: string
  ) => {
    try {
      const response = await axios.get(
        isClient
          ? `${api}/announcements/find-distinct?_limit=${limit}&_start=${start}`
          : `${api}/announcements/find-my?_limit=${limit}&_start=${start}&_sort=dated:DESC`,
        { withCredentials: true }
      );
      const announcements = response.data.data;
      const totalCount = response.data.count;
      if (!isClient) {
        const count = announcements.filter(
          (announcement: any) => announcement.read === false
        ).length;
        setAnnouncementsCount(count);
      }

      return { announcements, totalCount };
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { announcements: [], totalCount: 0 };
    }
  };

  const handleAddClick = () => {
    history.push("/admin/announcement-form");
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await axios.delete(`${api}/announcements/${id}`, {
        withCredentials: true,
      });
      toast({
        title: "Announcement deleted successfully",
        position: "bottom-right",
        status: "success",
      });
      // @ts-ignore
      tableRef.current.onQueryChange();
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };

  const handleRowClick = async (event: any, rowData: any) => {
    if (!isClient) {
      setAnnouncement(rowData);
      setOpenModal(true);
      // console.log(rowData);
      await axios.put(
        `${api}/announcements/${rowData.id}`,
        { read: true },
        { withCredentials: true }
      );
      if (tableRef.current) {
        // @ts-ignore
        tableRef.current.onQueryChange();
      }
    }
  };

  return (
    <>
      {isClient && (
        <Button
          colorScheme="blue"
          onClick={handleAddClick}
          className="add-btn"
          m="3"
          size="sm"
          leftIcon={<AddIcon />}
          textTransform="uppercase"
        >
          Add Announcement
        </Button>
      )}
      <Flex flexDirection="row" px="2">
        <Box style={{ flexGrow: 4 }}>
          <MaterialTable
            tableRef={tableRef}
            title="Announcements"
            columns={[
              { title: "Date", field: "dated", sorting: false },
              { title: "Title", field: "title", sorting: false },
              { title: "Message", field: "message", sorting: false },
            ]}
            components={{
              Toolbar: (props) => (
                <Box display="flex" w="100%" justifyContent="space-between">
                  <Box flex="1">
                    <MTableToolbar {...props} />
                  </Box>
                  {/* <MaterialTableCustomSort
                  sort={sortQueryAnnouncement}
                  setSort={setSortQueryAnnouncement}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
                </Box>
              ),
            }}
            actions={
              isClient
                ? [
                    // {
                    //   icon: "edit",
                    //   tooltip: "View Announcement",
                    //   onClick: (event, rowData: any) => {
                    //     handleEditClick(rowData.id);
                    //   },
                    // },
                    {
                      icon: "delete",
                      tooltip: "Delete Announcement",
                      onClick: (event, rowData: any) => {
                        if (window.confirm("Are you sure?")) {
                          handleDeleteClick(rowData.messageCode);
                        }
                      },
                    },
                  ]
                : []
            }
            options={{
              actionsColumnIndex: -1,
              sorting: false,
              headerStyle: {
                zIndex: "unset",
              },
              rowStyle: (rowData: any) => ({
                backgroundColor: isClient
                  ? "white"
                  : rowData.read
                  ? "white"
                  : "#f6ad55",
              }),
              search: false,
            }}
            onRowClick={(event, rowData: any) => handleRowClick(event, rowData)}
            data={(query) =>
              new Promise((resolve, reject) => {
                fetchAnnouncementsAndCount(
                  query.page * query.pageSize,
                  query.pageSize,
                  sortQueryAnnouncement,
                  query.search
                )
                  .then(({ announcements, totalCount }) => {
                    const newData = announcements.map((item: any) => ({
                      id: item.id,
                      messageCode: item.messageCode,
                      title: item.title,
                      message: item.message,
                      dated: item.dated.substring(0, 16).replace("T", " "),
                      read: item.read,
                    }));

                    return resolve({
                      data: newData,
                      page: query.page,
                      totalCount,
                    });
                  })
                  .catch((err) => console.log(err));
              })
            }
          />
        </Box>
        {!isClient && isLargerThanMd && (
          <Show above="sm">
            <Box>
              <img src="../../undraw_happy_news_re_tsbd.svg" alt="no" />
            </Box>
          </Show>
        )}
      </Flex>

      <AnnouncementModal
        openModal={openModal}
        announcement={announcement}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default ViewAnnouncements;
