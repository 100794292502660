import { Container, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import { api } from "config";
import React from "react";

const PromoPage: React.FC = () => {
  const [codeData, setCodeDate] = React.useState<any>();
  const getMyCode = async () => {
    const res = await axios.get(`${api}/app-coupons/get-my-code`, {
      withCredentials: true,
    });
    setCodeDate(res.data[0]);
  };

  React.useEffect(() => {
    getMyCode();
  }, []);

  return (
    <>
      <Container>
        <Flex direction={"column"}>
          <Text fontSize="2xl" fontWeight="bold" mt="10" textAlign="center">
            Promotions
          </Text>
          <Text fontSize="xl" fontWeight="bold" mt="10" textAlign="center">
            Enjoy 12 months of free subscription on mobile app premium plan
          </Text>
          <Text fontSize="xl" fontWeight="bold" mt="10" textAlign="center">
            Use this code to get the discount
          </Text>
          <Text
            fontSize="xl"
            fontWeight="bold"
            mt="10"
            textAlign="center"
            backgroundColor="orange.300"
            color="white"
            p="3"
            borderRadius="full"
          >
            {codeData?.code}
          </Text>
          {codeData?.isValid ? (
            <Text
              fontSize="xl"
              fontWeight="bold"
              mt="10"
              textAlign="center"
              color={"white"}
              p="3"
              backgroundColor="green"
              borderRadius="full"
            >
              Available to redeem
            </Text>
          ) : (
            <Text
              fontSize="xl"
              fontWeight="bold"
              mt="10"
              textAlign="center"
              color="white"
              backgroundColor="red"
              p="3"
              borderRadius="full"
            >
              Already redeemed
            </Text>
          )}
        </Flex>
      </Container>
    </>
  );
};

export default PromoPage;
