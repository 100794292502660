import ViewClassLinks from "components/ClassLink/ViewClassLinks";
import React from "react";

interface Props {}

const ClassLinksPage: React.FC<Props> = () => {
  return <ViewClassLinks />;
};

export default ClassLinksPage;
