import {
  Box,
  Heading,
  Image,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import GoBackButton from "components/Misc/GoBackButton";
import { api } from "config";
import React from "react";
import { useRouteMatch } from "react-router-dom";

interface Props {}

const PaperSummary: React.FC<Props> = () => {
  const match = useRouteMatch<any>("/admin/paper-summary/:groupId/:id");
  const id = match?.params?.id;
  const groupId = match?.params?.groupId;
  const [quizzes, setQuizzes] = React.useState([]);

  React.useEffect(() => {
    const getQuizzes = async () => {
      try {
        const response = await axios.get(
          `${api}/groups/quizzes/${groupId}/${id}`,
          {
            withCredentials: true,
          },
        );
        console.log(response.data);
        setQuizzes(response.data);
      } catch (error: any) {}
    };
    getQuizzes();
  }, [id, groupId]);

  return (
    <Box>
      <GoBackButton />
      <Heading p="2">Quizzes</Heading>
      <TableContainer bg="white" shadow="base" whiteSpace={"normal"}>
        <Table variant="simple" size="sm">
          <TableCaption>Quizzes</TableCaption>
          <Thead>
            <Tr>
              <Th>Quiz No</Th>
              <Th>Image</Th>
              <Th>Question</Th>
              <Th>Answer</Th>
              <Th>Correct Answers</Th>
              <Th>Total Attempts</Th>
              <Th>Correct Percentage</Th>
            </Tr>
          </Thead>
          <Tbody>
            {quizzes.map((q: any, i) => (
              <Tr key={q.id}>
                <Td>{q.qNo}</Td>
                <Td>
                  {q.image && (
                    <Image
                      src={`${api}${q.image}`}
                      w="100px"
                      h="100px"
                      objectFit={"contain"}
                    />
                  )}
                </Td>
                <Td>
                  <Box wordBreak={"break-word"} display="inline-block">
                    {q.quiz}
                  </Box>
                </Td>
                <Td
                  color="white"
                  textAlign={"center"}
                  bg={q.isTrue ? "green.400" : "red.400"}
                >
                  {q.isTrue ? "Vero" : "Falso"}
                </Td>
                <Td>{q.correct}</Td>
                <Td>{q.total}</Td>
                <Td
                  bg="blue.400"
                  color="white"
                  fontSize={"lg"}
                  textAlign="center"
                >
                  {((parseInt(q.correct) / parseInt(q.total)) * 100).toFixed(0)}
                  %
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Quiz No</Th>
              <Th>Image</Th>
              <Th>Question</Th>
              <Th>Answer</Th>
              <Th>Correct Answers</Th>
              <Th>Total Attempts</Th>
              <Th>Correct Percentage</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaperSummary;
