import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";

const columns = [
  {
    id: "groupName",
    name: "Group Name",
  },
  {
    id: "fee",
    name: "Fee",
  },
];

const ViewGroups: React.FC = () => {
  const useCounterState = createPersistedState("sortQueryGroup");
  const [sortQueryGroup, setSortQueryGroup]: any =
    useCounterState("groupName:asc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();

  const fetchGroupsAndCount = async (
    start: number,
    limit: number,
    sort: any,
    groupName: string
  ) => {
    try {
      const { data: groups } = await axios.get(
        `${api}/groups?_limit=${limit}&_start=${start}&_sort=${sort}&groupName_contains=${
          groupName || ""
        }`,
        { withCredentials: true }
      );
      const { data: totalCount } = await axios.get(`${api}/groups/count`, {
        withCredentials: true,
      });
      return { groups, totalCount };
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { groups: [], totalCount: 0 };
    }
  };

  const handleEditClick = (id: string) => {
    history.push(`/admin/group-form/${id}`);
  };
  const handleManageClick = (id: string) => {
    history.push(`/admin/group-class-form/${id}`);
  };
  const handleAddClick = () => {
    history.push("/admin/group-form");
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Class
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Classes"
          columns={[
            { title: "Class Name", field: "groupName", sorting: false },
            { title: "Fee", field: "fee", sorting: false },
            {
              title: "Inactive",
              field: "inactive",
              sorting: false,
              render: (rowData) =>
                rowData.inactive ? (
                  <Box color="red.500">Inactive</Box>
                ) : (
                  <Box color="green.500">Active</Box>
                ),
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                {/* <MaterialTableCustomSort
                  sort={sortQueryGroup}
                  setSort={setSortQueryGroup}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
              </Box>
            ),
          }}
          actions={[
            {
              icon: "visibility",
              tooltip: "Manage Class",
              onClick: (event, rowData: any) => {
                handleManageClick(rowData.id);
              },
            },
            {
              icon: "edit",
              tooltip: "Edit Class",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchGroupsAndCount(
                query.page * query.pageSize,
                query.pageSize,
                sortQueryGroup,
                query.search
              )
                .then(({ groups, totalCount }) => {
                  const newData = groups.map((item: any) => ({
                    id: item.id,
                    groupName: item.groupName,
                    inactive: item.inactive,
                    fee: item.fee,
                  }));

                  return resolve({
                    data: newData,
                    page: query.page,
                    totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewGroups;
