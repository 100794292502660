import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
// import "./assets/css/material-dashboard-react.css?v=1.8.0";
import Home from "./pages/home";
// import { checkAuth } from "./utils/ProtectedRoute";
import Login from "./pages/login";
import ResetPassword from "./pages/reset-password";
import { ProtectedRoute } from "./utils/ProtectedRoute";
// import Dashboard from "./components/dashboard";
// import ViewProducts from "./pages/products";
// import ProductForm from "./components/ProductForm";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute path="/admin" component={Home} />
        <ProtectedRoute path="/class-links" component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        {/* <ProtectedRoute path="/product/:id" component={ProductForm} /> */}
        {/* <ProtectedRoute path="/products" exact component={ViewProducts} /> */}
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
