import { Box, Button, Flex, Grid, useToast } from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import Loading from "components/Misc/Loading";
import { api } from "config";
import * as React from "react";
import { Group, Month } from "types/apiTypes";
import { PAYMENT_TYPE } from "types/core";
import { getRole } from "utils/functions";

interface Props {
  group: Group;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
  setSelectedMonths?: React.Dispatch<React.SetStateAction<number[]>>;
  selectedMonthIds?: number[];
  setStep: (step: number) => void;
  userId?: number;
  isMulti?: boolean;
  paymentType: PAYMENT_TYPE;
  hideVoucherBtn?: boolean;
}

const SelectMonthPage: React.FC<Props> = ({
  group,
  setSelectedMonth,
  setSelectedMonths,
  setStep,
  userId,
  isMulti,
  selectedMonthIds,
  paymentType,
  hideVoucherBtn,
}) => {
  const [Months, setMonths] = React.useState<Month[]>([]);
  const role = getRole(); // localStorage.getItem("role");
  const [loading, setLoading] = React.useState(true);
  const toast = useToast();

  //get all  Month
  const getMonths = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        role === "Client"
          ? `${api}/payments/find-my-pending-months?userId=${userId}&_sort=year:asc,month:asc`
          : `${api}/payments/find-my-pending-months?_sort=year:asc,month:asc`,
        {
          withCredentials: true,
        },
      );

      const filterByGroup = response.data.filter(
        (month: Month) => month.group.id === group.id,
      );
      // console.log(sortedMonths);
      setMonths(filterByGroup);
      setLoading(false);
    } catch (error: any) {}
    setLoading(false);
  };

  const handleClickMonth = (id: number) => {
    if (isMulti && setSelectedMonths) {
      if (selectedMonthIds?.includes(id)) {
        setSelectedMonths((prev) => prev.filter((m) => m !== id));
      } else {
        if (role !== "Client") {
          if (selectedMonthIds?.length === 2) {
            toast({
              title: "You can only select upto two months",
              status: "warning",
              duration: 3000,
            });
            return;
          }
        }
        setSelectedMonths((prev) => [...prev, id]);
      }
    } else {
      setSelectedMonth(id);
      setStep(3);
    }
  };

  React.useEffect(() => {
    getMonths();
  }, [group]);

  const handleNext = () => {
    if (paymentType === PAYMENT_TYPE.RECEIPT) {
      setStep(4);
    } else if (
      paymentType === PAYMENT_TYPE.CARD ||
      paymentType === PAYMENT_TYPE.APPLE_GOOGLE_PAY
    ) {
      setStep(5);
    }
  };

  if (loading) return <Loading />;

  return (
    <Box my="8">
      <Flex justifyContent={"space-between"}>
        <Box fontSize="md" color="gray.600">
          Select the month:
        </Box>
        {!hideVoucherBtn && (
          <Button
            colorScheme="blue"
            onClick={() => {
              setStep(3);
            }}
            isDisabled={selectedMonthIds?.length === 0}
            variant="link"
          >
            coupen
          </Button>
        )}
      </Flex>
      <Box my="6">
        <Grid
          templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr" }}
          gridGap={"3"}
        >
          {Months.length > 0 ? (
            Months.map((month: Month, index) => {
              return (
                <Grid key={index}>
                  <Button
                    w="full"
                    onClick={() => handleClickMonth(month.id)}
                    borderRadius="md"
                    shadow="base"
                    bg={
                      selectedMonthIds?.includes(month.id)
                        ? "yellow.300"
                        : "gray.100"
                    }
                    _hover={{ bg: "yellow.300" }}
                  >
                    {months[month.month - 1]} | {month.year}
                  </Button>
                </Grid>
              );
            })
          ) : (
            <Box>You don't have any pending payments for this class</Box>
          )}
        </Grid>
      </Box>
      {isMulti && (
        <Box display={"flex"} justifyContent="flex-end" gridGap={"5px"}>
          <Button
            colorScheme="blue"
            onClick={handleNext}
            isDisabled={selectedMonthIds?.length === 0}
          >
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SelectMonthPage;
