import { Box, Grid } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface Props {}

const PaperCategoriesPage: React.FC<Props> = () => {
  return (
    <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap="3">
      <Category name="Theory Paper" type="tp" />
      <Category name="Theroy Weekend Paper" type="twp" />
      <Category name="Revision Paper" type="rp" />
      <Category name="Revision Weekend Paper" type="rwp" />
    </Grid>
  );
};

export default PaperCategoriesPage;

interface CategoryProps {
  name: string;
  type: string;
}

const Category = ({ name, type }: CategoryProps) => (
  <Link to={`/admin/papers/${type}`}>
    <Box shadow="sm" p="8" bg="white">
      {name}
    </Box>
  </Link>
);
