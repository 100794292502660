import { Box, Button } from "@chakra-ui/react"
import axios from "axios"
import { useRef, useState } from "react"
import { api } from "../config"

const DeleteStudentPapers = () => {
  const [papers, setPapers] = useState<any[]>([])
  const [selectedPapers, setSelectedPapers] = useState<number[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const userRef = useRef(null)

  const handleSearch = async () => {
    // TODO: Create a new route in the backend with auto populate false
    const result = await axios.get(
      `${api}/student-papers/student-papers-for-delete?code=${
        inputRef.current?.value as string
      }`,
      { withCredentials: true }
    )
    setPapers(result.data)
  }

  const handleSubmit = async () => {
    if (!window.confirm("Are you sure?")) return
    await axios.post(
      `${api}/student-papers/delete-papers`,
      {
        ids: selectedPapers,
        user: userRef.current
      },
      { withCredentials: true }
    )

    handleSearch()
    setSelectedPapers([])
  }

  return (
    <Box display={"flex"} flexDir="column" gridGap={4}>
      <Box fontSize={20} fontWeight="bold">
        Delete Student Papers
      </Box>
      <Box>
        <input placeholder="Student Code" type="text" ref={inputRef} />
        <Button onClick={handleSearch}>Search</Button>
      </Box>
      <Box display={"grid"} gridTemplateColumns="1fr 1fr" gridGap={"10px"}>
        {papers.map((p) => (
          <Box key={p.id} display="flex" alignItems={"center"} gridGap="5px">
            <input
              type="checkbox"
              onChange={(e) => {
                if (!e.target.checked) {
                  setSelectedPapers((papers) =>
                    papers.filter((id: number) => id !== p.id)
                  )
                } else {
                  setSelectedPapers((papers) => [...papers, p.id])
                }
                userRef.current = p.user
              }}
            />
            <Box>{p.topic}</Box>
          </Box>
        ))}
      </Box>
      <Button
        type="submit"
        colorScheme={"blue"}
        onClick={handleSubmit}
        disabled={selectedPapers.length === 0}
      >
        Submit
      </Button>
    </Box>
  )
}

export default DeleteStudentPapers
