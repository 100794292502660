import {
  Box,
  Button,
  HStack,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { api } from "config";
import MaterialTable from "material-table";
import React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";
interface Props {}

const AppAccessForm: React.FC<Props> = () => {
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = React.useState<number>();
  const [selectedPackage, setSelectedPackage] = React.useState<number>(-1);
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([]);
  const [password, setPassword] = React.useState<string>("");
  const toast = useToast();

  const generatePassword = () => {
    const passwordd = Math.random().toString(36).slice(-8);
    setPassword(passwordd);
  };
  const fetchGroups = async () => {
    try {
      const { data: groupsData } = await axios.get(`${api}/groups`, {
        withCredentials: true,
      });
      setGroups(groupsData);
      // console.log(groupsData);
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  React.useEffect(() => {
    fetchGroups();
  }, []);

  const handleSelectUsers = (rows: any) => {
    setSelectedUserIds(rows.map((row: any) => row.id));
  };
  const handleSubmit = async () => {
    try {
      if (!password) {
        throw new Error("Please set a password");
      }
      if (selectedGroupIndex === undefined || isNaN(selectedGroupIndex)) {
        throw new Error("Please select a class");
      }
      if (selectedUserIds.length === 0) {
        throw new Error("Please select at least one user");
      }

      const data: any = [];
      for (const userId of selectedUserIds) {
        const username = groups[
          selectedGroupIndex
        ].users_permissions_users.find((x) => x.id === userId)?.username;
        data.push({
          username,
          email: `${username?.toLowerCase()}@italyclasses.com`,
          package: selectedPackage,
          password,
          isAppAccount: true,
          isAppAccountPremium: selectedPackage !== 0 ? true : false,
          appAccountPremiumType: selectedPackage,
        });
      }
      const ress = await axios.post(
        `${api}/app-coupons/register-bulk-users-for-app`,
        data,
        {
          withCredentials: true,
        },
      );
      const { successUsernames, failedUsernames } = ress.data;
      if (failedUsernames.length > 0) {
        toast({
          title: `${failedUsernames.length} App Account(s) Failed to Create`,
          position: "bottom-right",
          status: "error",
        });
      }
      if (successUsernames.length > 0) {
        toast({
          title: `${successUsernames.length} App Account(s) Created Successfully`,
          position: "bottom-right",
          status: "success",
        });
      }
      if (ress.status === 400) {
        toast({
          title: `App Account(s) Failed to Create`,
          position: "bottom-right",
          status: "error",
        });
      }
      fetchGroups();
      // history.push("/admin/view-permission");
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  const handleSelectGroup = (e: any) => {
    setSelectedGroupIndex(parseInt(e.target.value));
    console.log(e.target.value);
    setSelectedUserIds([]);
    setSelectedPackage(6);
  };
  return (
    <>
      <div>
        <Box>
          <Text fontSize="xl">Give Access to Mobile Application</Text>
          <Box my="5">
            <Text>Select a class</Text>
            <Select
              placeholder="Select a class"
              onChange={(e) => handleSelectGroup(e)}
            >
              {groups.map((group, index) => (
                <option key={group.id} value={index}>
                  {group.groupName}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <MaterialTable
              columns={[
                {
                  title: "Codice",
                  field: "username",
                },
                {
                  title: "Nome",
                  field: "name",
                },
                {
                  title: "Email",
                  field: "email",
                },
              ]}
              options={{
                selection: true,
              }}
              onSelectionChange={(rows) => handleSelectUsers(rows)}
              title="Select students to give access"
              data={
                selectedGroupIndex !== undefined
                  ? groups[selectedGroupIndex]?.users_permissions_users.filter(
                      (x) => x.isAppAccount !== true,
                    )
                  : []
              }
            />
          </Box>
          <Box my="5">
            <Text>Select the package</Text>
            <HStack spacing={4}>
              <Select
                onChange={(e) => setSelectedPackage(parseInt(e.target.value))}
                defaultValue={6}
              >
                {[0, 6, 12].map((month) => (
                  <option key={month} value={month}>
                    {month} months
                  </option>
                ))}
              </Select>
              <Input
                placeholder="Password"
                size="md"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Button
                px="16"
                colorScheme="yellow"
                onClick={() => generatePassword()}
              >
                Generate
              </Button>
            </HStack>
          </Box>

          <Box my="5" display="flex" justifyContent="center">
            <Button onClick={handleSubmit} colorScheme="red">
              Submit New Access
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default AppAccessForm;
