import { useToast } from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import ChakraTextArea from "components/Misc/FormComponents/ChakraTextArea";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import { Announcement, Group, Student } from "../../types/apiTypes";
import SelectFormFieldChip from "../Misc/FormComponents/SelectFormFieldChip";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import announcementStyles from "./styles/groupStyles";

// form validations
const schema = yup.object({
  title: yup.string().required("Title is Required"),
});

interface Props {
  announcement?: Announcement;
}

const AnnouncementForm: React.FC<Props> = ({ announcement }) => {
  const isEdit = typeof announcement !== "undefined";
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState([]);
  const [students, setStudents] = useState([]);

  const classes = announcementStyles();

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      let stuIDS: any = [];
      if (values.groups.length > 0) {
        for (let i = 0; i < values.groups.length; i++) {
          // @ts-ignore
          const stuIds = groups
            .find((group: Group) => group.id === values.groups[i])
            .users_permissions_users.map((student: Student) => student.id);
          stuIDS = [...stuIDS, ...stuIds];
          // console.log(stuIds);
        }
      }

      if (values.students.length > 0) stuIDS = [...stuIDS, ...values.students];
      stuIDS = [...new Set(stuIDS)];
      if (stuIDS.length === 0) {
        toast({
          title: "Please select at least one student or group",
          status: "error",
          position: "bottom-right",
        });
        return;
      }
      const data = stuIDS.map((id: number) => {
        return {
          messageCode: values.messageCode,
          title: values.title,
          message: values.message,
          users_permissions_user: id,
        };
      });
      // console.log(data);
      await axios.post(`${api}/announcements`, data, { withCredentials: true });
      toast({
        title: `Announcement ${!isEdit ? "Added" : "Updated"} Succefully`,
        position: "bottom-right",
        status: "success",
      });

      history.push("/admin/announcements");
    } catch (error: any) {
      console.log(error);
      console.log(error.response.data.message[0].messages[0].message);
      toast({
        title: error.response.data.message[0].messages[0].message,
        position: "bottom-right",
        status: "error",
      });
    }
    setLoading(false);
  };

  const getGroups = async () => {
    try {
      const res = await axios.get(`${api}/groups`, { withCredentials: true });
      const mappedGroups = res.data;
      setGroups(mappedGroups);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getStudents = async () => {
    try {
      const res = await axios.get(`${api}/users?role=1`, {
        withCredentials: true,
      });
      const mappedStudents = res.data.map((student: any) => ({
        label: student.username,
        value: student.id,
      }));
      setStudents(mappedStudents);
    } catch (error: any) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getGroups();
    getStudents();
  }, []);

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                messageCode: isEdit
                  ? announcement?.messageCode
                  : Math.random().toString(36).slice(-8),
                title: isEdit ? announcement?.title : "",
                message: isEdit ? announcement?.message : "",
                groups: [],
                students: [],
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "View" : "Add"} Announcement
                  </h3>
                  <Field
                    name="messageCode"
                    component={ChakraInput}
                    label="Announcement Code"
                    fullWidth
                    disabled={true}
                  />
                  <Field
                    name="title"
                    component={ChakraInput}
                    label="Title"
                    fullWidth
                    disabled={isEdit}
                  />
                  <Field
                    name="message"
                    component={ChakraTextArea}
                    label="Message"
                    multiline
                    disabled={isEdit}
                  />
                  <Field
                    name="groups"
                    component={SelectFormFieldChip}
                    label="Groups"
                    placeholder="Groups"
                    multiple
                    options={groups.map((group: any) => ({
                      label: group.groupName,
                      value: group.id,
                    }))}
                    variant="outlined"
                    disabled={isEdit}
                  />
                  <Field
                    name="students"
                    component={SelectFormFieldChip}
                    label="Students"
                    placeholder="Students"
                    multiple
                    options={students}
                    variant="outlined"
                    disabled={isEdit}
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn
                    loading={loading}
                    w="100%"
                    my="3"
                    disabled={isEdit}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default AnnouncementForm;
