import {
  Box,
  Button,
  HStack,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";
import { api } from "../../config";

const AddPermissions: React.FC = () => {
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = React.useState<number>();
  const [newMonthIndexes, setNewMonthIndexes] = React.useState<number[]>([]);
  const [selectedNewMonthIndex, setSelectedNewMonthIndex] =
    React.useState<number>(-1);
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([]);
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();

  const fetchGroups = async () => {
    try {
      const { data: groupsData } = await axios.get(`${api}/groups`, {
        withCredentials: true,
      });
      setGroups(groupsData);
      // console.log(groupsData);
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  React.useEffect(() => {
    fetchGroups();
  }, []);
  const addMonth = () => {
    if (selectedNewMonthIndex !== -1) {
      setNewMonthIndexes([...newMonthIndexes, selectedNewMonthIndex]);
      setSelectedNewMonthIndex(-1);
    }
  };
  const removeMonth = (month: number) => {
    setNewMonthIndexes(newMonthIndexes.filter((m) => m !== month));
  };
  const handleSelectUsers = (rows: any) => {
    setSelectedUserIds(rows.map((row: any) => row.id));
  };
  const handleSubmit = async () => {
    try {
      if (selectedGroupIndex === undefined || isNaN(selectedGroupIndex)) {
        throw new Error("Please select a class");
      }
      if (selectedUserIds.length === 0) {
        throw new Error("Please select at least one user");
      }
      if (newMonthIndexes.length === 0) {
        throw new Error("Please select at least one month");
      }
      const data = [];
      for (const userId of selectedUserIds) {
        for (const month of newMonthIndexes) {
          data.push({
            users_permissions_user: userId,
            month: groups[selectedGroupIndex].months[month].id,
          });
        }
      }
      await axios.post(`${api}/blocks`, data, {
        withCredentials: true,
      });
      toast({
        title: "Successfully added",
        position: "bottom-right",
        status: "success",
      });
      history.push("/admin/view-permission");
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  const handleSelectGroup = (e: any) => {
    setSelectedGroupIndex(parseInt(e.target.value));
    console.log(e.target.value);
    setSelectedUserIds([]);
    setNewMonthIndexes([]);
    setSelectedNewMonthIndex(-1);
  };
  return (
    <>
      <div>
        <Box>
          <Text fontSize="xl">Restrict Access to Courses</Text>
          <Box my="5">
            <Text>Select a class</Text>
            <Select
              placeholder="Select a class"
              onChange={(e) => handleSelectGroup(e)}
            >
              {groups.map((group, index) => (
                <option key={group.id} value={index}>
                  {group.groupName}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <MaterialTable
              columns={[
                {
                  title: "Codice",
                  field: "username",
                },
                {
                  title: "Nome",
                  field: "name",
                },
                {
                  title: "Email",
                  field: "email",
                },
              ]}
              options={{
                selection: true,
              }}
              onSelectionChange={(rows) => handleSelectUsers(rows)}
              title="Select students to restrict"
              data={
                selectedGroupIndex !== undefined
                  ? groups[selectedGroupIndex].users_permissions_users
                  : []
              }
            />
          </Box>
          <Box my="5">
            <Text>Select and add months to restrict</Text>
            <HStack spacing={4}>
              <Select
                placeholder="Select month"
                onChange={(e) =>
                  setSelectedNewMonthIndex(parseInt(e.target.value))
                }
              >
                {selectedGroupIndex !== undefined ? (
                  groups[selectedGroupIndex].months?.map((month, index) => {
                    if (newMonthIndexes.includes(index)) {
                      return null;
                    }
                    return (
                      <option key={month.id} value={index}>
                        {months[month.month - 1]}
                      </option>
                    );
                  })
                ) : (
                  <> </>
                )}
              </Select>
              <Button colorScheme="blue" onClick={addMonth}>
                Add Month
              </Button>
            </HStack>
          </Box>
          <Box my="5">
            <HStack spacing={4}>
              {newMonthIndexes.map((monthIndex: number) => (
                <Tag
                  size="lg"
                  key={monthIndex}
                  borderRadius="full"
                  variant="solid"
                  bg="orange.300"
                >
                  <TagLabel>
                    {selectedGroupIndex !== undefined
                      ? months[
                          groups[selectedGroupIndex].months[monthIndex]?.month -
                            1
                        ]
                      : ""}
                  </TagLabel>
                  <TagCloseButton onClick={() => removeMonth(monthIndex)} />
                </Tag>
              ))}
            </HStack>
          </Box>
          <Box my="5" display="flex" justifyContent="center">
            <Button onClick={handleSubmit} colorScheme="red">
              Submit restrictions
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default AddPermissions;
