import React, { createContext, ReactNode, useContext, useState } from "react";

interface ContextData {
  message: string;
  isMsgOpen: boolean;
  displayPopMessage: (message: string, timeout?: number) => void;
}

const MessageStateContext = createContext({} as ContextData);
MessageStateContext.displayName = "MessageStateContext";

interface Props {
  children: ReactNode;
}

const MessageStateProvider: React.FC<Props> = ({ children }) => {
  const [isMsgOpen, setMsgOpen] = useState(false);
  const [message, setMessage] = useState("");

  const displayPopMessage = (message: string, timeout = 3000) => {
    setMsgOpen(true);
    setMessage(message);
    setTimeout(() => {
      setMessage("");
      setMsgOpen(false);
    }, timeout);
  };

  return (
    <MessageStateContext.Provider
      value={{ isMsgOpen, message, displayPopMessage }}
    >
      {children}
    </MessageStateContext.Provider>
  );
};

const useMessageState = () => {
  const data = useContext(MessageStateContext);
  return data;
};

export { MessageStateProvider, MessageStateContext, useMessageState };
