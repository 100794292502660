export const cloudApi = "https://api.app.italyclasses.com";
export const cloudMobileApi = "https://api.la.devsquadx-cloud.com";

export const frontendLink =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://app.italyclasses.com";

export const api =
  process.env.NODE_ENV === "development" ? "http://localhost:1337" : cloudApi;

export const mobileApi =
  process.env.NODE_ENV === "development"
    ? "http://localhost:1338"
    : cloudMobileApi;
// export const api = cloudApi;
export const recaptchaKey =
  process.env.NODE_ENV === "development"
    ? "6Lcn6OsbAAAAAENInrkvkXTd_EKaB7g5j_wwTKAG"
    : "6LdXXnAfAAAAAF9Z1PDkb1CGM1iHZo0cZ6-ayvZf";

/* 
NEW
pk_live_51MFgZ0BlxFgAHtmakSprHa6OqaJ1kx5kqNjcjLMyF7lBfM0YTTE4r98lqkMClV4MLn8Glchcvq7yilOKWCxLiRnX00NuTTOW7r

PREVIOUS
pk_live_51Kt8IDDeqhxBxW3vo8C5ODpMOcTDXvML5kChZhKvuTmY4yQSVZxJ044yhLg0endUcNbfQXlEhRyNFEK3H82IIDYp00LHOuJnqQ
*/

export const stripePublishableKey =
  process.env.NODE_ENV === "development"
    ? "pk_test_51KsIJOHAoYbhjd1tmE85JyuBNCDjHzCuXKfwzsul9PaEAn6je1EUSFwvsFzcaJR6b4BsZtF4qbeqH5X6xrSkKw6400vkBHkKB5"
    : "pk_live_51PaH0x2LaG5devwCi53WFOGojZGJCe5DcepQoHzi8UTLW89HaD1Zl5BzOVAkiRvk9ZXDY2B2AWiTgBa4EqQqWn6700xDN5lXlC";

export const apiLink = process.env.NODE_ENV === "development" ? api : cloudApi;

export const payhereMerchantId =
  process.env.NODE_ENV === "development" ? "1221928" : "219073";

export const apiPayhere =
  process.env.NODE_ENV === "development"
    ? "https://b788-2402-4000-21c0-638c-b05f-e0db-df71-56ee.ngrok-free.app"
    : api;
