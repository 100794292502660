import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import Loading from "components/Misc/Loading";
import { api } from "config";
import React, { useEffect, useState } from "react";
import Quiz from "./Quiz";

interface Props {
  paperId: string;
  paperConfig: any;
  setPaperConfig: (value: any) => any;
}

const QuizPaper = React.memo(
  ({ paperId, paperConfig, setPaperConfig }: Props) => {
    const [paper, setPaper] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const toast = useToast();

    useEffect(() => {
      const fetchPaper = async () => {
        setLoading(true);
        const res = await axios.get(
          `${api}/papers/paper-with-quizzes?id=${paperId}`,
          {
            withCredentials: true,
          }
        );
        const p = {
          ...res.data[0],
          paper_quizzes: res.data[0].paper_quizzes.sort(
            (a: any, b: any) => a.qNo - b.qNo
          ),
        };
        setPaper(p);
        setLoading(false);
      };

      if (paperId) {
        fetchPaper();
      }
    }, [paperId]);

    const setAnswer = (quizId: number, choice: string) => {
      const paperQuizzes = paper.paper_quizzes.map((q: any) => {
        if (q.id === quizId) {
          return {
            ...q,
            userChoice: choice,
          };
        }
        return q;
      });

      setPaper({ ...paper, paper_quizzes: paperQuizzes });
    };

    const handleSubmit = async () => {
      // if (!window.confirm("Are you sure?")) {
      //   return;
      // }

      setSubmitting(true);
      try {
        const res = await axios.post(
          `${api}/student-papers/submit`,
          { paper },
          { withCredentials: true }
        );

        toast({
          title: `Submitted Successfully`,
          position: "bottom-right",
          status: "success",
        });

        setSubmitting(false);
        setPaperConfig({
          stage: 1,
          paper: {
            ...paper,
            marks: res.data.marks,
            noOfQuizzes: res.data.noOfQuizzes,
          },
        });
      } catch (err: any) {
        setSubmitting(false);
        console.log(err);
        toast({
          title: `Error while processing the paper`,
          position: "bottom-right",
          status: "error",
        });
      }
    };

    if (loading) return <Loading />;

    return (
      <Box fontFamily={"Lexend"} w="full">
        <Text
          textAlign={"center"}
          fontSize={{ base: "2xl", md: "3xl" }}
          py="6"
          fontWeight={"bold"}
          color="orange.500"
        >
          {paper.topic}
        </Text>
        <Text
          textAlign={"center"}
          fontSize={{ base: "base", md: "lg" }}
          pb="6"
          color="gray.500"
        >
          {paper.subTopic}
        </Text>
        <Box>
          {paper.paper_quizzes.map((q: any) => (
            <Quiz quiz={q} key={q.id} setAnswer={setAnswer} />
          ))}
        </Box>
        <Flex justifyContent={"center"} mb="5">
          <Button
            w="90%"
            mx="auto"
            // loading={true}
            zIndex={999}
            isLoading={submitting}
            // disabled
            loadingText="Processing"
            colorScheme={"blue"}
            onClick={handleSubmit}
            py="8"
            fontSize={"lg"}
            mb="50px"
          >
            Submit
          </Button>
        </Flex>
      </Box>
    );
  }
);

export default QuizPaper;
