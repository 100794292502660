import ProfileForm from "components/Profile/ProfileForm";
import React from "react";
import GoBackButton from "../components/Misc/GoBackButton";

const ProfileFormPage: React.FC = () => {
  return (
    <div>
      <GoBackButton />
      <ProfileForm />
    </div>
  );
};

export default ProfileFormPage;
