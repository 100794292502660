import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import { api } from "../config";

interface Props {}

const PapersPage: React.FC<Props> = () => {
  const history = useHistory();
  const ref = React.useRef(null);
  const params: any = useParams();

  const getPapers = async ({ start = 0, limit = 10 }) => {
    const response = await axios.get(
      `${api}/papers?_sort=id:ASC&_start=${start}&_limit=${limit}&category=${params.type.toUpperCase()}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  };

  const getTotalCount = async ({ start = 0, limit = 10 }) => {
    const response = await axios.get(
      `${api}/papers/count?_sort=id:ASC&_start=${start}&_limit=${limit}&category=${params.type.toUpperCase()}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  };

  const handleAddClick = () => {
    history.push(`/admin/quizzes-form`);
  };

  if (!params.type) return <div>URL Parameters Required</div>;

  return (
    <div>
      <GoBackButton />
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Paper
      </Button>
      <br />
      <br />
      <MaterialTable
        tableRef={ref}
        title={`Papers`}
        columns={[
          { title: "Code", field: "code" },
          { title: "Topic", field: "topic" },
        ]}
        localization={{
          header: {
            actions: "",
          },
        }}
        actions={[
          // {
          //   icon: "delete",
          //   tooltip: "Delete Question",
          //   position: "auto",
          //   onClick: (event, rowData: any) => {
          //     handleDeleteClick(rowData.id);
          //     // editQuestion(rowData.id);
          //   },
          // },
          {
            icon: "edit",
            tooltip: "Edit Paper",
            position: "auto",
            onClick: (event, rowData: any) => {
              history.push(`/admin/quizzes-form/${rowData.id}`);
            },
          },
        ]}
        options={{
          actionsColumnIndex: 0,
          exportButton: false,
          filtering: false,
          search: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40],
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            Promise.all([
              getPapers({
                start: query.page * query.pageSize,
                limit: query.pageSize,
              }),
              getTotalCount({
                start: query.page * query.pageSize,
                limit: query.pageSize,
              }),
            ]).then((result) => {
              resolve({
                data: result[0],
                page: query.page,
                totalCount: result[1],
              });
            });
          })
        }
      />
    </div>
  );
};

export default PapersPage;
