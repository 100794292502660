import { Box, Divider, Grid, useToast } from "@chakra-ui/react";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import FileUpload from "components/Misc/FormComponents/FileUpload";
import SubmitBtn from "components/Misc/FormComponents/SubmitBtn";
import { api } from "config";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { Coupon, Group } from "types/apiTypes";
import { getErrors } from "utils/functions";
import * as yup from "yup";

interface Props {
  group: Group;
  selectedMonthId: number;
  setStep: (step: number) => void;
  selectedMonthIds: number[];
  isMulti?: boolean;
  voucherDetails?: Coupon;
  validateVoucher: () => void;
}
const schema = yup.object({
  paidAmount: yup.number().required("Paid amount is required"),
});

const PaymentSlipPage: React.FC<Props> = ({
  group,
  selectedMonthId,
  setStep,
  selectedMonthIds,
  isMulti,
  voucherDetails,
  validateVoucher,
}) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: any) => {
    if (!values.slip) {
      toast({
        title: `Please upload the payment slip`,
        position: "bottom-right",
        status: "error",
      });
      return;
    }

    try {
      setLoading(true);
      if (!isMulti) selectedMonthIds = [selectedMonthId];

      const data = {
        slip: values.slip,
        months: selectedMonthIds,
        status: 2,
        paidAmount: group.fee,
        type: "BS",
        paidDate: new Date().toISOString(),
        coupon: voucherDetails?.id,
      };
      await axios.post(`${api}/payments`, data, { withCredentials: true });
      setLoading(false);
      toast({
        title: `Payment Added Succefully`,
        position: "bottom-right",
        status: "success",
      });

      setStep(7);
    } catch (err: any) {
      const errors = getErrors(err);
      errors.forEach((e) => {
        toast({
          title: e,
          position: "bottom-right",
          status: "error",
        });
      });
      setStep(8);
    }
  };
  React.useEffect(() => {
    validateVoucher();
  }, []);
  return (
    <Box my="8">
      <Box fontSize="md">
        පහල නිල් පැහැති button එක ඔබා upload කර, ඉන්පසු submit කරන්න.
      </Box>
      <Box my="6">
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          initialValues={{
            slip: "",
            paidAmount: voucherDetails
              ? (group.fee *
                  selectedMonthIds.length *
                  (100 - voucherDetails.percentage)) /
                100
              : group.fee * selectedMonthIds.length,
          }}
          validationSchema={schema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                gap="3"
                alignItems={"center"}
              >
                <Box>
                  <FileUpload
                    handleSetFieldValue={setFieldValue}
                    handleFieldName={`slip`}
                    text={`Upload Receipt`}
                  />
                  {values.slip && (
                    <>
                      <img
                        src={`${api}${values.slip}`}
                        alt="Receipt පත"
                        style={{ height: "150px", marginLeft: "10px" }}
                      />
                    </>
                  )}
                </Box>
                <Box mb="5">
                  <Field
                    name="paidAmount"
                    component={ChakraInput}
                    label="Amount"
                    type="number"
                    fullWidth
                    disabled
                  />
                </Box>
              </Grid>

              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <Divider />

              <SubmitBtn loading={loading} w="100%" mb="3" />
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default PaymentSlipPage;
