import { Button, Grid, useToast } from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import CheckboxFormField from "components/Misc/FormComponents/CheckboxFormField";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import { Coupon } from "../../types/apiTypes";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import couponStyles from "./styles/couponStyles";

// form validations
const schema = yup.object({
  code: yup.string().required("Coupon Code is Required"),
  percentage: yup.number().required("Percentage is Required"),
  enddate: yup.string().required("Expire Date is Required"),
});

interface Props {
  coupon?: Coupon;
}

const CouponForm: React.FC<Props> = ({ coupon }) => {
  const isEdit = typeof coupon !== "undefined";
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const classes = couponStyles();
  const generateCode = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const code = Math.random().toString(36).slice(-8);
    setFieldValue("code", code);
  };
  const handleSubmit = async (values: any) => {
    const data = {
      code: values.code,
      percentage: values.percentage,
      enddate: values.enddate,
      active: values.active,
    };
    try {
      setLoading(true);
      if (isEdit) {
        await axios.put(`${api}/coupons/${coupon?.id}`, data, {
          withCredentials: true,
        });
      } else {
        await axios.post(`${api}/coupons`, data, { withCredentials: true });
      }
      setLoading(false);
      toast({
        title: `Coupon ${!isEdit ? "Added" : "Updated"} Succefully`,
        position: "bottom-right",
        status: "success",
      });

      history.push("/admin/coupons");
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                code: isEdit ? coupon?.code : "",
                percentage: isEdit ? coupon?.percentage : "",
                enddate: isEdit ? coupon?.enddate : "",
                active: isEdit ? coupon?.active : true,
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} Coupon
                  </h3>
                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 2fr" }}
                    gap="3"
                    alignItems={"center"}
                  >
                    <Button
                      wordBreak="break-word"
                      whiteSpace="pre-line"
                      mt="3"
                      onClick={() => generateCode(setFieldValue)}
                    >
                      Generate Code
                    </Button>
                    <Field
                      name="code"
                      component={ChakraInput}
                      label="Code"
                      fullWidth
                    />
                  </Grid>

                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 4fr" }}
                    gap="3"
                    alignItems={"center"}
                  >
                    <Field
                      name="percentage"
                      component={ChakraInput}
                      label="Percentage"
                      fullWidth
                    />
                    <Field
                      name="enddate"
                      component={ChakraInput}
                      label="Expiry Date"
                      fullWidth
                      type="date"
                    />
                    <Field
                      name="active"
                      component={CheckboxFormField}
                      label="Is Active"
                      defaultChecked={isEdit ? coupon?.active : false}
                    />
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" my="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default CouponForm;
