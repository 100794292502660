import { Badge } from "@chakra-ui/react";
//@ts-ignore
import dateFormat from "dateformat";
import React from "react";

interface Props {}

const DateBox: React.FC<Props> = () => {
  const now = new Date();
  return (
    <Badge
      colorScheme="blue"
      borderRadius="md"
      shadow="base"
      fontSize={{ sm: "sm", md: "base" }}
      fontFamily={"Lexend"}
      mt={{ sm: "2", md: "3" }}
      mb="2"
    >
      {dateFormat(now, "dddd, mmmm dS, yyyy")}
    </Badge>
  );
};

export default DateBox;
