import { gql, useMutation } from "@apollo/client";
// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import AnnouncementModal from "components/Announcement/AnnouncementModal";
// Custom Icons
import { LogoutIcon, ProfileIcon } from "components/Icons/Icons";
import Logo from "components/Layout/Logo";
import { ItemContent } from "components/Menu/ItemContent";
// Custom Components
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import { api } from "config";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { getRole } from "utils/functions";
import routes, { studentRoutes } from "utils/routes.js";
// Assets
import SidebarHelpImage from "../../assets/img/SidebarHelpImage.png";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const username = localStorage.getItem("username");
  const role = getRole(); // localStorage.getItem("role");
  const isClient = role === "Client";

  ////////announcements
  const [announcements, setAnnouncements] = React.useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState();
  const [openAnnouncement, setOpenAnnouncement] = React.useState(false);

  const settingsRef = React.useRef();
  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("white", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");

  const history = useHistory();
  const [logout] = useMutation(LOGOUT_MUTATION);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.clear();
      localStorage.clear();
      Cookies.remove("role", { path: "/", domain: ".app.italyclasses.com" });
      history.push("/login");
    } catch (err) {
      console.log(err);
    }
  };

  const getAnnouncements = async () => {
    try {
      const response = await axios.get(
        `${api}/announcements/find-my?_limit=${5}&_start=${0}&_sort=dated:DESC`,
        { withCredentials: true },
      );
      const totalCount = response.data.count;
      setAnnouncements(response.data.data);
    } catch (error) {}
  };

  //useeffect on interval
  React.useEffect(() => {
    if (!isClient) {
      const interval = setInterval(
        () => {
          getAnnouncements();
        },
        1000 * 60 * 60 * 2,
      );
      return () => clearInterval(interval);
    }
  });

  //function to get date and time as ago
  const getTimeAgo = (date) => {
    const date1 = new Date(date);
    const seconds = Math.floor((new Date() - date1) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  };

  const handleAnnouncementClick = async (announcement) => {
    if (!isClient) {
      setSelectedAnnouncement(announcement);
      setOpenAnnouncement(true);
      // console.log(rowData);
      await axios.put(
        `${api}/announcements/${announcement.id}`,
        { read: true },
        { withCredentials: true },
      );
      getAnnouncements();
    }
  };

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      justifyContent={"space-between"}
    >
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={role === "Client" ? routes : studentRoutes}
        // logo={logo}
        {...rest}
      />
      <Logo
        display={{ sm: "flex", md: "none" }}
        fontSize="xl"
        iconSize="30"
        mt="0"
        mb="0"
      />
      <InputGroup
        cursor="pointer"
        bg={inputBg}
        borderRadius="15px"
        display={{ sm: "none", md: "block" }}
        w={{
          sm: "128px",
          md: "200px",
        }}
        me={{ sm: "auto", md: "20px" }}
        _focus={{
          borderColor: { mainTeal },
        }}
        _active={{
          borderColor: { mainTeal },
        }}
      >
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
            ></IconButton>
          }
        />
        <Input
          fontSize="xs"
          py="11px"
          color={mainText}
          placeholder="Type here..."
          borderRadius="inherit"
          fontFamily="Lexend"
        />
      </InputGroup>

      <Flex alignItems={"center"} gridGap="2">
        <NavLink to="/admin/profile">
          <Button
            ms="0px"
            px={{ sm: "2", md: "4" }}
            bg="orange.300"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            text="white"
            borderRadius="full"
            size="sm"
            ml={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            fontWeight={"normal"}
          >
            <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            <Text
              display={{ sm: "flex", md: "flex" }}
              fontFamily="Lexend"
              textTransform="capitalize"
              color="white"
            >
              {username}
            </Text>
          </Button>
        </NavLink>
        {!isClient && (
          <Box display={{ sm: "block", md: "none" }}>
            <Menu>
              <Box position="relative">
                <MenuButton>
                  <BellIcon color={searchIcon} w="18px" h="18px" boxSize="8" />
                </MenuButton>
                {announcements.filter(
                  (announcement) => announcement.read === false,
                ).length > 0 && (
                  <Box
                    position="absolute"
                    right="-2"
                    top="-2"
                    bgColor="red.500"
                    color="white"
                    rounded="full"
                    fontSize="sm"
                    px="2"
                  >
                    {
                      announcements.filter(
                        (announcement) => announcement.read === false,
                      ).length
                    }
                  </Box>
                )}
              </Box>
              <MenuList p="16px 8px">
                <Flex flexDirection="column">
                  {announcements.map((announcement, index) => (
                    <MenuItem
                      borderRadius="8px"
                      mb="10px"
                      onClick={() => {
                        handleAnnouncementClick(announcement);
                      }}
                      bgColor={announcement.read ? "white" : "red.100"}
                    >
                      <ItemContent
                        time={getTimeAgo(announcement.dated)}
                        info={announcement.title}
                        boldInfo={announcement.read ? "" : "New"}
                        aName="Alicia"
                        aSrc={SidebarHelpImage}
                      />
                    </MenuItem>
                  ))}
                </Flex>
              </MenuList>
            </Menu>
          </Box>
        )}

        <LogoutIcon
          display={{ sm: "none", md: "block" }}
          cursor="pointer"
          // ms={{ base: "20px", xl: "0px" }}
          // me="16px"
          ref={settingsRef}
          onClick={handleLogout}
          color={"gray.600"}
          w="24px"
          h="24px"
        />
      </Flex>

      <AnnouncementModal
        openModal={openAnnouncement}
        setOpenModal={setOpenAnnouncement}
        announcement={selectedAnnouncement}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

export const LOGOUT_MUTATION = gql`
  mutation LOGOUT_MUTATION {
    logout
  }
`;
