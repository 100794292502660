// Chakra imports
import {
  ChakraProvider,
  Portal,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getRole } from "utils/functions";
import { useShowSidebar } from "utils/store";
import Configurator from "../components/Configurator/Configurator";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
// Layout components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar";
// Custom Chakra theme
import theme from "../theme/theme.js";
import routes, { studentRoutes } from "../utils/routes.js";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      Cookies.remove("role", { path: "/", domain: ".app.italyclasses.com" });
      window.location.href = "/login";
    }
  },
);

export default function Dashboard(props: any) {
  const role = getRole(); // localStorage.getItem("role");
  let defaultRoute = role === "Client" ? routes : studentRoutes;
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  const toast = useToast();
  const [linkNoti, setLinkNoti] = useState();

  const [sideBarVisible] = useShowSidebar(true);

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes: any) => {
    let activeRoute = "#";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute: any = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  // This changes navbar state(fixed or not)
  const getActiveNavbar: any = (routes: any) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (["/admin", "/class-links"].includes(prop.layout)) {
        // console.log(prop.layout);
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      {sideBarVisible && (
        <Sidebar
          routes={defaultRoute}
          logoText={"SL TEACH"}
          display="none"
          sidebarVariant={sidebarVariant}
          {...rest}
        />
      )}
      <MainPanel
        w={{
          base: "100%",
          xl: `calc(100% - ${sideBarVisible ? "275px" : "10px"})`,
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            logoText={"SL TEACH"}
            brandText={getActiveRoute(defaultRoute)}
            secondary={getActiveNavbar(defaultRoute)}
            fixed={fixed}
            {...rest}
          />
        </Portal>
        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <br />
              <br />
              <br />
              <Switch>
                {getRoutes(defaultRoute)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
        {/* <Footer /> */}
        {/* <Portal>
          <FixedPlugin
            //@ts-ignore
            secondary={getActiveNavbar(defaultRoute)}
            fixed={fixed}
            onOpen={onOpen}
          />
        </Portal> */}
        <Configurator
          secondary={getActiveNavbar(defaultRoute)}
          isOpen={isOpen}
          onClose={onClose}
          //@ts-ignore
          isChecked={fixed}
          onSwitch={(value: any) => {
            setFixed(value);
          }}
          onOpaque={() => setSidebarVariant("opaque")}
          onTransparent={() => setSidebarVariant("transparent")}
        />
      </MainPanel>
    </ChakraProvider>
  );
}
