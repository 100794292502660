/*eslint-disable*/
import { useMutation } from "@apollo/client";
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { CreativeTimLogo, LogoutIcon } from "components/Icons/Icons";
import Logo from "components/Layout/Logo";
import { LOGOUT_MUTATION } from "components/Navbars/AdminNavbarLinks";
import { Separator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import Cookies from "js-cookie";
import React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import useStore from "utils/store";

function SidebarResponsive(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const announcementsCount = useStore((state) => state.announcementsCount);
  const history = useHistory();
  const [logout] = useMutation(LOGOUT_MUTATION);

  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const createAnnouncementIcon = (path) => {
    return (
      <>
        {announcementsCount > 0 && path === "/announcements" ? (
          <Box
            bgColor="red.500"
            color="white"
            rounded="full"
            fontSize="sm"
            px="2"
            py="0.5"
            ml="2"
            display="inline-block"
          >
            {announcementsCount}
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  const createLinks = (routes) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");

    return routes
      .filter((rt) => rt.show === true)
      .map((prop, key) => {
        if (prop.redirect) {
          return null;
        }
        if (prop.category) {
          var st = {};
          st[prop["state"]] = !state[prop.state];
          return (
            <div key={prop.name}>
              <Text
                color={activeColor}
                fontWeight="bold"
                mb={{
                  xl: "12px",
                }}
                mx="auto"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
              >
                {prop.name}
                {createAnnouncementIcon(prop.path)}
              </Text>
              {createLinks(prop.views)}
            </div>
          );
        }
        return (
          <NavLink to={prop.layout + prop.path} key={prop.name}>
            {activeRoute(prop.layout + prop.path) === "active" ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg={activeBg}
                mb={{
                  xl: "12px",
                }}
                mx={{
                  xl: "auto",
                }}
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <></>
                  ) : (
                    <IconBox
                      bg="blue.300"
                      color="white"
                      h="32px"
                      w="32px"
                      me="12px"
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text
                    color={activeColor}
                    my="auto"
                    fontSize="sm"
                    fontFamily={"Lexend"}
                  >
                    {prop.name}
                    {createAnnouncementIcon(prop.path)}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={{
                  xl: "12px",
                }}
                mx={{
                  xl: "auto",
                }}
                py="12px"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg={inactiveBg}
                      color="blue.300"
                      h="30px"
                      w="30px"
                      me="12px"
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text
                    color={inactiveColor}
                    my="auto"
                    fontSize="sm"
                    fontFamily={"Lexend"}
                  >
                    {prop.name}
                    {createAnnouncementIcon(prop.path)}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      });
  };

  const { logoText, routes, ...rest } = props;

  var links = <>{createLinks(routes)}</>;
  let hamburgerColor = useColorModeValue("gray.600", "gray.200");
  if (props.secondary === true) {
    hamburgerColor = "white";
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handleLogout = async () => {
    try {
      await logout();
      sessionStorage.clear();
      localStorage.clear();
      Cookies.remove("role", { path: "/", domain: ".app.italyclasses.com" });
      history.push("/login");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Flex
      display={{ sm: "flex", xl: "none" }}
      ref={mainPanel}
      alignItems="center"
    >
      <HamburgerIcon
        color={hamburgerColor}
        w="25px"
        h="25px"
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
        >
          <DrawerCloseButton
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="250px" px="1rem">
            <Box maxW="100%" h="100vh">
              <Logo w="full" />
              {/* <Box>{brand}</Box> */}
              <Stack direction="column" mb="40px">
                <Box>
                  {links}
                  <Button
                    w="full"
                    mx="auto"
                    fontFamily={"Lexend"}
                    leftIcon={<LogoutIcon w="5" h="5" />}
                    fontWeight="base"
                    fontSize={"sm"}
                    shadow="base"
                    mt="2"
                    letterSpacing={"wide"}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Stack>
              <SidebarHelp></SidebarHelp>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default SidebarResponsive;
