import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";

const ViewClassLinks: React.FC = () => {
  const useCounterState = createPersistedState("sortQueryClassLinks");
  const [sortQueryClassLinks, setSortQueryClassLinks]: any =
    useCounterState("created_at:desc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();

  const fetchClassLinksAndCount = async (
    start: number,
    limit: number,
    sort: any,
    topic: string
  ) => {
    try {
      const result = await axios.get(
        `${api}/group-links?_limit=${limit}&_start=${start}&_sort=${sort}&topic_contains=${
          topic || ""
        }`,
        { withCredentials: true }
      );
      const classLinks = result.data;
      const { data: totalCount } = await axios.get(`${api}/group-links/count`, {
        withCredentials: true,
      });
      return { classLinks, totalCount };
    } catch (error: any) {
      console.log(error);
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { classLinks: [], totalCount: 0 };
    }
  };

  const handleEditClick = (id: string) => {
    history.push(`/admin/add-class-link/${id}`);
  };

  const handleAddClick = () => {
    history.push("/admin/add-class-link");
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Class Link
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Classes"
          columns={[
            { title: "Topic", field: "topic", sorting: false },
            { title: "Generated Link", field: "generatedLink", sorting: false },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                {/* <MaterialTableCustomSort
                  sort={sortQueryGroup}
                  setSort={setSortQueryGroup}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Class Link",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchClassLinksAndCount(
                query.page * query.pageSize,
                query.pageSize,
                sortQueryClassLinks,
                query.search
              )
                .then(({ classLinks, totalCount }) => {
                  console.log(classLinks);
                  const newData = classLinks.map((item: any) => ({
                    id: item.id,
                    topic: item.topic,
                    generatedLink: item.generatedLink,
                  }));

                  return resolve({
                    data: newData,
                    page: query.page,
                    totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewClassLinks;
