import { Box, Grid, Image, Text, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import ContentModal from "components/ClassView/ContentModal";
import PreviousPapers from "components/ClassView/PreviousPapers";
import QuizPaper from "components/ClassView/QuizPaper";
import ReviewPaper from "components/ClassView/ReviewPaper";
import { CurrentContent } from "components/ClassView/ViewClassContent";
import AlertBox from "components/PaymentStudent/AlertBox";
import { api } from "config";
import { useEffect, useState } from "react";

const MyPreviousPapersPage: React.FC = () => {
  const [papers, setPapers] = useState<any[]>([]);
  const [paperConfig, setPaperConfig] = useState({
    // 0 -> while doing paper
    // 1 -> reviewing the results
    stage: 0,
    paper: null,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentContent, setCurrentContent] = useState<CurrentContent>();
  const [openAlert, setOpenAlert] = useState(false);

  const fetchPapers = async () => {
    const res = await axios.get(`${api}/student-papers/my-previous-papers`, {
      withCredentials: true,
    });
    setPapers(res.data);
  };

  useEffect(() => {
    fetchPapers();
  }, []);

  return (
    <>
      {papers.length ? (
        <PreviousPapers
          setCurrentContent={setCurrentContent}
          paperConfig={paperConfig}
          setPaperConfig={setPaperConfig}
          papers={papers}
          onOpen={onOpen}
        />
      ) : (
        <></>
      )}
      {isOpen && currentContent && (
        <ContentModal
          isOpen={isOpen}
          onClose={() => {
            fetchPapers();
            setOpenAlert(true);
          }}
          title={currentContent?.title}
        >
          <Grid
            templateColumns={{ base: "1fr", md: "2fr 7fr" }}
            mr={{ base: "0", md: "7" }}
          >
            <Box>
              <Box textAlign={"center"}>
                <Image
                  src={`${api}${currentContent.image}`}
                  height="1/2"
                  w="full"
                  px="2"
                  display={{ base: "none", md: "block" }}
                />
                <Box bg="blue.600" p="2" py="3">
                  <Text
                    fontSize={"lg"}
                    fontWeight="normal"
                    px="5"
                    color="white"
                    w="80%"
                    mx="auto"
                  >
                    {currentContent.title}
                  </Text>

                  <Text color="gray.100">{currentContent.content}</Text>
                </Box>
              </Box>
              {/* {currentContent.quizChoice === "P" ? (
                <PreviousPapers
                  paperId={currentContent.paper as string}
                  paperConfig={paperConfig}
                  setPaperConfig={setPaperConfig}
                />
              ) : (
                <></>
              )} */}
            </Box>
            {currentContent.contentType === "quiz" ? (
              currentContent.quizChoice === "P" ? (
                <>
                  {paperConfig.stage === 0 && (
                    <QuizPaper
                      paperId={currentContent?.paper as string}
                      paperConfig={paperConfig}
                      setPaperConfig={setPaperConfig}
                    />
                  )}
                  {paperConfig.stage === 1 && (
                    <ReviewPaper
                      paperConfig={paperConfig}
                      setPaperConfig={setPaperConfig}
                    />
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  maxW="100vw"
                  dangerouslySetInnerHTML={{
                    __html: currentContent?.link as string,
                  }}
                ></Box>
              )
            ) : (
              <Box
                pr={{ base: "0", md: "3" }}
                pt={{ base: "3", md: "0" }}
                dangerouslySetInnerHTML={{
                  __html: currentContent?.link as string,
                }}
              ></Box>
            )}
          </Grid>
        </ContentModal>
      )}

      <AlertBox
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        handleConfirm={onClose}
      />
    </>
  );
};

export default MyPreviousPapersPage;
