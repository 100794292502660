import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import ContentModal from "components/ClassView/ContentModal";
import QuizPaper from "components/ClassView/QuizPaper";
import ReviewPaper from "components/ClassView/ReviewPaper";
import { CurrentContent } from "components/ClassView/ViewClassContent";
import AlertBox from "components/PaymentStudent/AlertBox";
import { api } from "config";
import React from "react";
import { FaQuestion, FaVideo } from "react-icons/fa";
import { Quiz, Video } from "types/apiTypes";

interface Props {}

const LatestQuizzesVideos: React.FC<Props> = () => {
  const [quizzes, setQuizzes] = React.useState<Quiz[]>([]);
  const [videos, setVideos] = React.useState<Video[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentContent, setCurrentContent] = React.useState<CurrentContent>();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [paperConfig, setPaperConfig] = React.useState({
    // 0 -> while doing paper
    // 1 -> reviewing the results
    stage: 0,
    paper: null,
  });

  //get all  Month
  const getLatestData = async () => {
    try {
      const response = await axios.get(`${api}/groups/latest-quizzes-videos`, {
        withCredentials: true,
      });

      setQuizzes(response.data.latestTwoQuizzes);
      setVideos(response.data.latestTwoVideos);
    } catch (error: any) {}
  };

  React.useEffect(() => {
    getLatestData();
  }, []);

  return (
    <Box py="4" bg="white" p="4" my="3" shadow="sm">
      <Text
        fontWeight={"semibold"}
        color="green.700"
        textTransform={"uppercase"}
        letterSpacing="wider"
        py="2"
        pl="2"
        mb="2"
        bg="green.100"
        alignItems={"center"}
      >
        <Flex gridGap={"2"} alignItems="center">
          Latest Quizzes, Videos
        </Flex>
      </Text>
      <TableContainer bg="white" maxW={"80vw"} mx="0" px="0">
        <Table variant="simple" overflowX="scroll" px="0">
          <Thead>
            <Tr>
              <Th bg="gray.100" display="flex" alignItems={"center"}>
                <FaQuestion /> <Text ml="2">Quizzes</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"sm"}>
            {quizzes.map((quiz) => (
              <Tr key={quiz.id}>
                <Td
                  cursor={"pointer"}
                  wordBreak={"break-all"}
                  onClick={() => {
                    setCurrentContent({ ...quiz, contentType: "quiz" });
                    onOpen();
                  }}
                >
                  {quiz.title}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <br />
        <Table variant="simple" overflowX="scroll" px="0">
          <Thead>
            <Tr>
              <Th bg="gray.100" display="flex" alignItems={"center"}>
                <FaVideo /> <Text ml="2">Videos</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"sm"}>
            {videos.map((video) => (
              <Tr key={video.id}>
                <Td
                  cursor={"pointer"}
                  wordBreak={"break-all"}
                  onClick={() => {
                    setCurrentContent({ ...video, contentType: "video" });
                    onOpen();
                  }}
                >
                  {video.title}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {isOpen && currentContent && (
        <ContentModal
          isOpen={isOpen}
          onClose={() => {
            setOpenAlert(true);
          }}
          title={currentContent?.title}
        >
          <Grid
            templateColumns={{ base: "1fr", md: "2fr 7fr" }}
            mr={{ base: "0", md: "7" }}
          >
            <Box>
              <Box textAlign={"center"}>
                <Image
                  src={`${api}${currentContent.image}`}
                  height="1/2"
                  w="full"
                  px="2"
                  display={{ base: "none", md: "block" }}
                />
                <Box bg="blue.600" p="2" py="3">
                  <Text
                    fontSize={"lg"}
                    fontWeight="normal"
                    px="5"
                    color="white"
                  >
                    {currentContent.title}
                  </Text>

                  <Text color="gray.100">{currentContent.content}</Text>
                </Box>
              </Box>
              {/* {currentContent.quizChoice === "P" ? (
                <PreviousPapers
                  paperId={currentContent.paper as string}
                  paperConfig={paperConfig}
                  setPaperConfig={setPaperConfig}
                />
              ) : (
                <></>
              )} */}
            </Box>
            {currentContent.contentType === "quiz" ? (
              currentContent.quizChoice === "P" ? (
                <>
                  {paperConfig.stage === 0 && (
                    <QuizPaper
                      paperId={currentContent?.paper as string}
                      paperConfig={paperConfig}
                      setPaperConfig={setPaperConfig}
                    />
                  )}
                  {paperConfig.stage === 1 && (
                    <ReviewPaper
                      paperConfig={paperConfig}
                      setPaperConfig={setPaperConfig}
                    />
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  maxW="100vw"
                  dangerouslySetInnerHTML={{
                    __html: currentContent?.link as string,
                  }}
                ></Box>
              )
            ) : (
              <Box
                pr={{ base: "0", md: "3" }}
                pt={{ base: "3", md: "0" }}
                dangerouslySetInnerHTML={{
                  __html: currentContent?.link as string,
                }}
              ></Box>
            )}
          </Grid>
        </ContentModal>
      )}
      <AlertBox
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        handleConfirm={onClose}
      />
    </Box>
  );
};

export default LatestQuizzesVideos;
