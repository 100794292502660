import { Button } from "@chakra-ui/react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  [x: string]: any;
}

const GoBackButton: React.FC<Props> = ({ ...props }) => {
  const history = useHistory();

  return (
    <>
      <Button
        colorScheme="yellow"
        boxShadow="base"
        size="sm"
        mb="4"
        mt="5"
        fontWeight="normal"
        {...props}
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon fontSize="small" />
        Go Back
      </Button>
    </>
  );
};

export default GoBackButton;
