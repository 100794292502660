import { Badge, FormLabel } from "@chakra-ui/react";
import {
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FieldProps, getIn } from "formik";
import React from "react";
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const SelectFormFieldChip: React.FC<
  FieldProps & {
    label?: string;
    options: Array<{ label: string; value: string }>;
  }
> = ({ field, form, label, options, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const classes = useStyles();
  return (
    <div>
      {/* {label && <InputLabel style={{ paddingLeft: 5 }}>{label}</InputLabel>} */}

      {label && (
        <FormLabel
          fontFamily="Lexend, sans-serif"
          color="gray.700"
          htmlFor={`${field.name}-id`}
        >
          {label}
        </FormLabel>
      )}
      <FormControl fullWidth error={!!errorText}>
        <Select
          id={`${field.name}-id`}
          fullWidth
          {...field}
          {...props}
          renderValue={(selected: any) => (
            <div className={classes.chips}>
              {selected.map((value: any, index: number) => (
                <Badge
                  key={index}
                  borderRadius="3xl"
                  colorScheme={"blue"}
                  px="2"
                  py="1"
                >
                  {options.find((a) => a.value === value)?.label}
                </Badge>
              ))}
            </div>
          )}
        >
          {options.map((op) => (
            <MenuItem key={op.value} value={op.value}>
              {op.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default SelectFormFieldChip;
