import { Button } from "@chakra-ui/button";
import {
  Box,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { default as axios, default as Axios } from "axios";
import { api } from "config";
import React from "react";
import { Student } from "types/apiTypes";
import { useUser } from "utils/store";

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const AddReviewModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const toast = useToast();
  const [review, setReview] = React.useState("");
  const [city, setCity] = React.useState("");
  const [reviewId, setReviewId] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false);
  const [user] = useUser<Student | undefined>();
  const [loading, setLoading] = React.useState(true);

  const loadReview = async () => {
    setLoading(true);
    setIsEdit(false);
    const { data } = await Axios.get(
      `${api}/reviews?users_permissions_user=${user?.id}&_sort=created_at:desc&_limit=9`
    );
    if (data.length > 0) {
      setReview(data[0].description);
      setCity(data[0].city);
      setReviewId(data[0].id);
      setIsEdit(true);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (user) {
      loadReview();
    }
  }, [user]);

  const handleSubmitReview = async () => {
    const reviewData = {
      description: review,
      city: city,
      users_permissions_user: user?.id,
    };

    if (!reviewData.description) {
      toast({
        title: "Review is required",
        status: "error",
        position: "bottom-right",
      });
      return;
    }

    if (!reviewData.city) {
      toast({
        title: "City is required",
        status: "error",
        position: "bottom-right",
      });
      return;
    }

    try {
      if (isEdit) {
        await axios.put(`${api}/reviews/${reviewId}`, reviewData, {
          withCredentials: true,
        });
      } else {
        await axios.post(`${api}/reviews`, reviewData, {
          withCredentials: true,
        });
      }
      // customerRefetch();
      setIsOpen(false);
    } catch (error: any) {
      console.log(error);
      setIsOpen(false);
    }
  };

  if (loading) return <></>;
  else
    return (
      <div>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>අපගේ පංතිය ඔබගේ අදහස ලියන්න</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box
                w="full"
                rounded="md"
                border="1px"
                borderColor="gray.300"
                p="5"
                mb="5"
              >
                <Textarea
                  placeholder="We love to hear from you"
                  onChange={(e: any) => {
                    setReview(e.target.value);
                  }}
                  value={review}
                  required
                />
                <Input
                  mt="2"
                  placeholder="Your City"
                  onChange={(e: any) => {
                    setCity(e.target.value);
                  }}
                  value={city}
                  required
                />
                <Button
                  onClick={handleSubmitReview}
                  marginY="20px"
                  border="1px"
                  borderColor="gray.500"
                >
                  Submit Review
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
};

export default AddReviewModal;
