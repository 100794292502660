import axios from "axios";
import GroupClassForm from "components/Group/GroupClassForm";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import { api } from "../config";
import { Month } from "../types/apiTypes";

const GroupClassFormPage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(0);
  const [groupMonths, setGroupMonths] = React.useState<Month[]>([]);
  const [selectedMonth, setSelectedMonth] = React.useState<number>(-1);
  const match = useRouteMatch("/admin/group-class-form/:id");
  const isEdit = match !== null;
  let id = "";

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }
  const fetchGroup = React.useCallback(async () => {
    setLoading(true);
    const { data } = await axios.get(
      `${api}/months?group=${id}&_sort=year:asc,month:asc`,
      {
        withCredentials: true,
      }
    );
    if (selectedMonth === -1) {
      setSelectedMonth(data[0].month);
    }
    setGroupMonths(data);
    setLoading(false);
  }, [id, selectedMonth]);

  React.useEffect(() => {
    if (id) {
      fetchGroup();
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  if (loading) return <Loading />;

  if (id && groupMonths.length && selectedMonth !== -1) {
    return (
      <div>
        <GoBackButton />
        <GroupClassForm
          groupMonths={groupMonths}
          setRefresh={setRefresh}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      </div>
    );
  }
  return (
    <div>
      <GoBackButton />
    </div>
  );
};

export default GroupClassFormPage;
