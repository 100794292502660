import Cookies from "js-cookie";

const getNotiTime = (createdDate: string) => {
  const date1: any = new Date(createdDate);
  const curDate: any = new Date();
  const seconds = Math.floor((curDate - date1) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

const getErrors = (err: any) => {
  const errMessages = [];
  if (err.response) {
    const messages = err.response.data.message;
    if (Array.isArray(messages)) {
      messages.forEach((msg) => {
        msg.messages.forEach((inMsg: any) => {
          errMessages.push(inMsg.message);
        });
      });
    } else {
      errMessages.push(messages);
    }
  } else {
    errMessages.push(err.message);
  }

  return errMessages;
};

const getRole = () => {
  return Cookies.get("role") || sessionStorage.getItem("role");
};

const convertUserChoiceToBoolean = (userChoice: string) => {
  return userChoice ? (userChoice === "1" ? true : false) : null;
};

export { getNotiTime, getErrors, getRole, convertUserChoiceToBoolean };
