import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { api } from "config";
import React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";

interface Props {
  group: Group;
}

const ClassItem: React.FC<Props> = ({ group }) => {
  const month = new Date().getMonth() + 1;
  let completedMonths = group.months
    .map((month) => month.month)
    .filter((m) => m < month);
  const history = useHistory();
  const handleClickClass = (id: number) => {
    history.push(`/admin/class-view-content/${id}`);
  };
  return (
    <Flex
      alignItems={"center"}
      justifyContent="center"
      textAlign={"center"}
      direction="column"
      cursor="pointer"
      shadow="base"
      p="2"
      mt="3"
      py="8"
      borderRadius={"3xl"}
      _hover={{
        shadow: "base",
      }}
      onClick={() => handleClickClass(group.id)}
      bg="gray.50"
    >
      <Image src={`${api}${group.image}`} maxH="44" />
      <Text pt="2" fontWeight={"semibold"}>
        {group.groupName}
      </Text>
      <Button size={"sm"} mt="3" bgColor={"orange.400"} color="white">
        View More
      </Button>
    </Flex>
  );
};

export default ClassItem;
