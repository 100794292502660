import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { ClockIcon, MessageIcon } from "components/Icons/Icons";
import React from "react";
import { getNotiTime } from "utils/functions";

interface Props {
  title: string;
  message: string;
  date: string;
  onClick: () => void;
}

const AnnouncementItem: React.FC<Props> = ({
  title,
  message,
  date,
  onClick,
}) => {
  return (
    <Grid
      templateColumns={"1fr 4fr"}
      gridGap="4"
      py="3"
      cursor="pointer"
      alignItems={"center"}
      bg="white"
      px="2"
      onClick={onClick}
    >
      <Flex
        alignItems={"center"}
        justifyContent="center"
        p="2"
        bg="purple.100"
        borderRadius={"xl"}
      >
        <MessageIcon color="purple.500" w="5" h="5" />
      </Flex>
      <Box>
        <Text fontWeight={"bold"}>{title}</Text>
        <Text color="gray.600" fontSize={"xs"} mt="1">
          {message}
        </Text>
        <Flex alignItems={"center"} pt="2">
          <ClockIcon color={"gray.500"} w="13px" h="13px" me="3px" />
          <Text fontSize={"xs"} color="gray.500">
            {getNotiTime(date)}
          </Text>
        </Flex>
      </Box>
    </Grid>
  );
};

export default AnnouncementItem;
