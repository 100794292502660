import { Box, Button, Divider, Flex, Grid, Text } from "@chakra-ui/react";
import React from "react";

interface Props {
  papers: any;
  handleRestart: any;
  handleReview: any;
}

const PapersListMobile: React.FC<Props> = ({
  papers,
  handleRestart,
  handleReview,
}) => {
  return (
    <Box>
      {papers.map((p: any) => {
        const scores = p.scores.split(",");
        return (
          <>
            <Flex
              key={p.id}
              direction="column"
              bg="white"
              shadow="base"
              mb="3"
              p="2"
              gridGap={3}
            >
              <Box fontSize="lg" pt="3" fontWeight={"bold"}>
                {p.topic}
              </Box>
              <Grid templateColumns={"1fr 1fr 1fr"} gridGap={2} mb="2">
                <Box>
                  <Text>Last 1</Text>
                  <MarkBox mark={scores[scores.length - 1]} />
                </Box>
                <Box>
                  <Text>Last 2</Text>
                  <MarkBox mark={scores[scores.length - 2]} />
                </Box>
                <Box>
                  <Text>Last 3</Text>
                  <MarkBox mark={scores[scores.length - 3]} />
                </Box>
              </Grid>
              <Flex w="full" gridGap={2}>
                <Button
                  w="full"
                  colorScheme={"blue"}
                  onClick={() => handleReview(p.id)}
                >
                  Review
                </Button>
                <Button
                  w="full"
                  colorScheme={"yellow"}
                  onClick={() => handleRestart(p.id)}
                >
                  Try Again
                </Button>
              </Flex>
            </Flex>
            <Divider mb="2" border="1px" bg="gray.700" />
          </>
        );
      })}
    </Box>
  );
};

export default PapersListMobile;

const MarkBox = ({ mark }: any) => {
  let status = "null";
  if (mark) {
    const markSplit = mark.split("/");
    const marks = parseInt(markSplit[0]);
    const noOfQuizzes = parseInt(markSplit[1]);
    status = (marks / noOfQuizzes) * 100 >= 90 ? "pass" : "fail";
  }

  return (
    <Flex
      bg={status === "pass" ? "green.500" : status === "fail" ? "red.500" : ""}
      p="2"
      color="gray.100"
      justifyContent={"center"}
      alignItems="center"
    >
      {mark}
    </Flex>
  );
};
