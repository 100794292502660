import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  onClose: any;
  isOpen: boolean;
  title?: string;
  [x: string]: any;
}

const ContentModal: React.FC<Props> = ({
  onClose,
  isOpen,
  title,
  children,
  ...props
}) => {
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen} {...props}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>{title}</ModalHeader> */}
        <ModalCloseButton bg="red.500" color="white" />
        <ModalBody p={{ base: "0", md: "3" }}>{children}</ModalBody>
        {/* <ModalFooter> */}
        {/* <Button onClick={onClose}>Close</Button> */}
        {/* </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default ContentModal;
