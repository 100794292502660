import { Box } from "@chakra-ui/react";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface Props {}

const PaymentErrorPage: React.FC<Props> = () => {
  const history = useHistory();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/");
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Box my="8">
      <Box
        fontSize={"2xl"}
        fontWeight="extrabold"
        textAlign="center"
        color="gray.600"
      >
        Sorry
      </Box>
      <Box my="6">
        <Box>
          <img
            src="../../close.png"
            alt="correct"
            width="120px"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>
        <Box
          fontSize={"xl"}
          fontWeight="extrabold"
          textAlign="center"
          color="gray.600"
          mt="5"
        >
          Payment Failed
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentErrorPage;
