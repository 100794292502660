import axios from "axios";
import AddClassLink from "components/ClassLink/AddClassLink";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import Loading from "../components/Misc/Loading";
import { api } from "../config";

const ClassLinkFormPage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [classLink, setClassLink] = React.useState<any>();
  const match = useRouteMatch("/admin/add-class-link/:id");
  const isEdit = match !== null;
  let id = "";

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }
  const fetchClassLink = React.useCallback(async () => {
    const { data } = await axios.get(`${api}/group-links/${id}`, {
      withCredentials: true,
    });
    setClassLink(data);
    setLoading(false);
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetchClassLink();
    } else {
      setLoading(false);
    }
  }, [fetchClassLink, id]);

  if (loading) return <Loading />;

  if (id) {
    return (
      <div>
        <AddClassLink classLink={classLink} />
      </div>
    );
  }
  return (
    <div>
      <AddClassLink />
    </div>
  );
};

export default ClassLinkFormPage;
