import { Box, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import { api } from "config";
import React from "react";
import PapersListMobile from "./PapersListMobile";
import PapersTable from "./PapersTable";
import { CurrentContent } from "./ViewClassContent";

interface Props {
  setCurrentContent: React.Dispatch<
    React.SetStateAction<CurrentContent | undefined>
  >;
  paperConfig: any;
  setPaperConfig: any;
  papers: any[];
  onOpen: any;
}

const PreviousPapers: React.FC<Props> = ({
  setCurrentContent,
  paperConfig,
  setPaperConfig,
  papers,
  onOpen,
}) => {
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleReview = async (id: number) => {
    const result = await axios.get(
      `${api}/student-papers/my-paper?paperId=${id}`,
      { withCredentials: true }
    );
    const paper = result.data;
    console.log(paper);
    const config = {
      stage: 1,
      paper: {
        ...paper.paper,
        marks: paper.marks,
        noOfQuizzes: paper.noOfQuizzes,
        paper_quizzes: paper.student_paper_quizs
          .map((q: any) => ({
            ...q.paper_quiz,
            userChoice:
              q.answer !== null ? (q.answer === true ? "1" : "0") : null,
          }))
          .sort((a: any, b: any) => a.qNo - b.qNo),
      },
    };
    console.log({ config });

    setPaperConfig(config);
    //@ts-ignore
    setCurrentContent({
      contentType: "quiz",
      paper: id.toString(),
      title: paper.paper.topic,
      content: "",
      quizChoice: "P",
    });
    onOpen();
  };
  // console.log(papers);
  const handleRestart = async (id: number) => {
    const result = await axios.get(
      `${api}/student-papers/my-paper?paperId=${id}`,
      { withCredentials: true }
    );
    const paper = result.data;
    const config = {
      stage: 0,
      paper: {
        ...paper.paper,
        marks: paper.marks,
        noOfQuizzes: paper.noOfQuizzes,
        paper_quizzes: paper.student_paper_quizs
          .map((q: any) => ({
            ...q.paper_quiz,
            userChoice:
              q.answer !== null ? (q.answer === true ? "1" : "0") : null,
          }))
          .sort((a: any, b: any) => a.qNo - b.qNo),
      },
    };

    setPaperConfig(config);
    //@ts-ignore
    setCurrentContent({
      contentType: "quiz",
      paper: id.toString(),
      title: paper.paper.topic,
      content: "",
      quizChoice: "P",
    });
    onOpen();
  };

  return (
    <Box textAlign={"center"} fontFamily="Lexend">
      <Box display={{ base: "none", md: "block" }}>
        <PapersTable
          papers={papers}
          handleRestart={handleRestart}
          handleReview={handleReview}
        />
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        <PapersListMobile
          papers={papers}
          handleRestart={handleRestart}
          handleReview={handleReview}
        />
      </Box>
    </Box>
  );
};

export default PreviousPapers;
