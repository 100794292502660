import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsBook } from "react-icons/bs";
import { Link } from "react-router-dom";

interface Props {
  iconSize: string;
  fontSize: string;
  mt: string;
  mb: string;
}

const Logo: React.FC<Props> = ({
  iconSize = "60",
  fontSize = "3xl",
  mt = "8",
  mb = "5",
  ...props
}) => {
  return (
    <>
      <Link to="/">
        <Flex alignItems={"center"} mt={mt} cursor="pointer" {...props}>
          <BsBook size={iconSize} color="#4299E1" />
          {/* <BookIcon w="10" h="32" fontSize={"2xl"} color="orange.400" /> */}
          <Box
            color="blue.400"
            fontWeight={"bold"}
            letterSpacing="wider"
            fontSize={fontSize}
            lineHeight="90%"
            ml="3"
          >
            SL TEACH
          </Box>
        </Flex>
        <Text
          textAlign={"center"}
          fontFamily="Lexend"
          textTransform={"uppercase"}
          fontSize={{ base: "xs", md: "sm" }}
          letterSpacing="wide"
          color="gray.600"
          mb={mb}
          //@ts-ignore
          display={props.display}
        >
          Online Classes
        </Text>
      </Link>
    </>
  );
};

export default Logo;
