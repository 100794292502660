import {
  Box,
  Button,
  Divider,
  Grid,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import FileUpload from "components/Misc/FormComponents/FileUpload";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import { Group, Month } from "../../types/apiTypes";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import { months } from "./GroupClassBox";
import groupStyles from "./styles/groupStyles";

// form validations
const schema = yup.object({
  groupName: yup.string().required("Class Name is Required"),
  fee: yup.number().required("Fee is Required"),
});

interface Props {
  group?: Group;
}

interface MonthYear {
  month: number;
  year: number;
}

const GroupForm: React.FC<Props> = ({ group }) => {
  const isEdit = typeof group !== "undefined";
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [monthsData, setMonthData] = React.useState<Month[]>([]);
  const [newMonths, setNewMonths] = React.useState<MonthYear[]>([]);
  const [selectedNewMonth, setSelectedNewMonth] = React.useState<number>(0);
  const [selectedYear, setSelectedYear] = React.useState<number>(
    new Date().getFullYear()
  );

  const year = new Date().getFullYear() - 3;
  const years = Array.from(new Array(10), (val, index) => index + year);

  const classes = groupStyles();

  const handleSubmit = async (values: any) => {
    const data = {
      groupName: values.groupName,
      inactive: values.inactive,
      image: values.image,
      fee: values.fee,
      months: newMonths,
    };
    try {
      setLoading(true);
      if (isEdit) {
        await axios.put(`${api}/groups/${group?.id}`, data, {
          withCredentials: true,
        });
      } else {
        await axios.post(`${api}/groups`, data, { withCredentials: true });
      }
      setLoading(false);
      toast({
        title: `Class ${!isEdit ? "Added" : "Updated"} Succefully`,
        position: "bottom-right",
        status: "success",
      });

      history.push("/admin/groups");
    } catch (err: any) {
      console.log(err);
    }
  };

  const getMonths = async () => {
    try {
      if (group) {
        const res = await axios.get(`${api}/months?group=${group.id}`, {
          withCredentials: true,
        });
        setMonthData(res.data);
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getMonths();
  }, [group]);

  const addMonth = () => {
    if (selectedNewMonth !== 0) {
      setNewMonths([
        ...newMonths,
        { month: selectedNewMonth, year: selectedYear },
      ]);
      setSelectedNewMonth(0);
    }
  };
  const removeMonth = (month: MonthYear) => {
    setNewMonths(
      newMonths.filter((m) => m.month !== month.month && m.year !== month.year)
    );
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                groupName: isEdit ? group?.groupName : "",
                inactive: isEdit ? group?.inactive : false,
                image: isEdit ? group?.image : "",
                fee: isEdit ? group?.fee : 0,
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} Class
                  </h3>
                  <Grid
                    templateColumns={{ base: "1fr", md: "2fr 1fr" }}
                    gap={{ base: "1", md: "3" }}
                  >
                    <Field
                      name="groupName"
                      component={ChakraInput}
                      label="Class Name"
                      fullWidth
                      autoFocus
                    />
                    <Field
                      name="fee"
                      component={ChakraInput}
                      label="Fee"
                      type="number"
                      fullWidth
                    />
                  </Grid>

                  <Grid templateColumns={"2fr 1fr"} gap="3">
                    <Box my="3">
                      <FileUpload
                        handleSetFieldValue={setFieldValue}
                        handleFieldName={`image`}
                        text={`Upload thumbnail`}
                      />
                      {values.image && (
                        <img
                          src={`${api}${values.image}`}
                          alt="a"
                          style={{ height: "150px", marginLeft: "10px" }}
                        />
                      )}
                    </Box>
                    <div>
                      <Field
                        name="inactive"
                        component={CheckboxFormField}
                        label="Inactive"
                        variant="outlined"
                        defaultChecked={values.inactive}
                      />
                    </div>
                  </Grid>

                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <Divider />
                  <Box my="5">
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={4}
                    >
                      {monthsData.map((month: Month) => (
                        <Tag
                          size="lg"
                          key={month.id}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="gray"
                        >
                          <TagLabel>
                            {months[month.month - 1]} | {month.year}
                          </TagLabel>
                        </Tag>
                      ))}
                    </Stack>
                  </Box>
                  <Box my="5">
                    <Grid
                      templateColumns={{ base: "1fr", md: "2fr 2fr 1fr" }}
                      gridGap="3"
                    >
                      <Select
                        placeholder="Select month"
                        onChange={(e) =>
                          setSelectedNewMonth(parseInt(e.target.value))
                        }
                      >
                        {months.map((month: string, index: number) => {
                          if (newMonths.find((m) => m.month === index + 1))
                            return null;
                          else if (
                            monthsData.find((m) => m.month === index + 1)
                          )
                            return null;
                          else
                            return (
                              <option key={index} value={index + 1}>
                                {month}
                              </option>
                            );
                        })}
                      </Select>
                      <Select
                        placeholder="Select year"
                        onChange={(e) =>
                          setSelectedYear(parseInt(e.target.value))
                        }
                        value={selectedYear}
                      >
                        {years.map((year: number) => {
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </Select>
                      <Button colorScheme="blue" onClick={addMonth}>
                        Add Month
                      </Button>
                    </Grid>
                  </Box>
                  <Text color="red.500" fontWeight={"bold"}>
                    Please note that once you add and submit a month you can't
                    change it back
                  </Text>
                  <Box my="5">
                    <Stack
                      spacing={4}
                      direction={{ base: "column", md: "row" }}
                    >
                      {newMonths.map((month) => (
                        <Tag
                          size="lg"
                          key={month.month}
                          borderRadius="full"
                          variant="solid"
                          bg="orange.300"
                        >
                          <TagLabel>
                            {months[month.month - 1]} | {month.year}
                          </TagLabel>
                          <TagCloseButton onClick={() => removeMonth(month)} />
                        </Tag>
                      ))}
                    </Stack>
                  </Box>

                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default GroupForm;
