import create from "zustand";
import createPersistedState from "use-persisted-state";
import { devtools } from "zustand/middleware";

type Store = {
  role: string;
  username: string;
  setUserRole: (role: string, username: string) => void;
  announcementsCount: number;
  setAnnouncementsCount: (count: number) => void;
};

const useStore = create<Store>(
  devtools((set: any) => ({
    role: "",
    username: "",
    setUserRole: (role: string, username: string) =>
      set(() => ({ role, username }), "SET_USER_ROLE"),
    announcementsCount: 0,
    setAnnouncementsCount: (count: number) => {
      set({ announcementsCount: count });
    },
  }))
);

const useShowSidebar = createPersistedState("show-side-bar");
const useUser = createPersistedState("user", localStorage);

export { useShowSidebar, useUser };

export default useStore;
