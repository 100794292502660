import { AddIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import * as React from "react";
import { useHistory } from "react-router-dom";

const ViewAppAccesss: React.FC = () => {
  const history = useHistory();
  const toast = useToast();

  const handleEditClick = (id: string) => {
    history.push(`/admin/app-access-form/${id}`);
  };

  const handleAddClick = () => {
    history.push("/admin/app-access-form");
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add app access to class
      </Button>
      <div></div>
    </>
  );
};

export default ViewAppAccesss;
