export const GooglePayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 105 42"
      width={50}
    >
      <path
        fill="#3C4043"
        d="M49.029 20.84v12.1h-3.83V3.06h10.153c2.573 0 4.767.86 6.563 2.58 1.835 1.72 2.752 3.82 2.752 6.3 0 2.54-.917 4.64-2.752 6.34-1.776 1.7-3.97 2.54-6.563 2.54H49.03v.02Zm0-14.1v10.42h6.403c1.516 0 2.792-.52 3.79-1.54 1.017-1.02 1.536-2.26 1.536-3.66 0-1.38-.519-2.6-1.536-3.62-.998-1.06-2.254-1.58-3.79-1.58h-6.403v-.02ZM74.68 11.82c2.833 0 5.067.76 6.703 2.28 1.636 1.52 2.454 3.6 2.454 6.24v12.6h-3.65V30.1h-.16c-1.576 2.34-3.69 3.5-6.323 3.5-2.254 0-4.13-.66-5.645-2-1.516-1.34-2.274-3-2.274-5 0-2.12.797-3.8 2.393-5.04 1.596-1.26 3.73-1.88 6.383-1.88 2.274 0 4.15.42 5.605 1.26v-.88c0-1.34-.518-2.46-1.575-3.4-1.058-.94-2.294-1.4-3.71-1.4-2.135 0-3.83.9-5.067 2.72l-3.371-2.12c1.855-2.7 4.608-4.04 8.238-4.04Zm-4.946 14.84c0 1 .419 1.84 1.277 2.5.838.66 1.835 1 2.972 1 1.615 0 3.052-.6 4.308-1.8 1.257-1.2 1.895-2.6 1.895-4.22-1.197-.94-2.852-1.42-4.986-1.42-1.556 0-2.853.38-3.89 1.12-1.057.78-1.576 1.72-1.576 2.82ZM104.661 12.48 91.895 41.92h-3.95l4.748-10.3-8.418-19.14h4.169l6.064 14.68h.08l5.904-14.68h4.169Z"
      ></path>
      <path
        fill="#4285F4"
        d="M33.796 18.4c0-1.251-.111-2.45-.319-3.601H17.424v6.6l9.245.002a7.942 7.942 0 0 1-3.43 5.316v4.282h5.503c3.213-2.982 5.054-7.39 5.054-12.598Z"
      ></path>
      <path
        fill="#34A853"
        d="M23.24 26.717c-1.531 1.036-3.504 1.642-5.812 1.642-4.458 0-8.24-3.012-9.594-7.072H2.157v4.416C4.969 31.3 10.75 35.14 17.428 35.14c4.616 0 8.493-1.522 11.316-4.142l-5.503-4.28Z"
      ></path>
      <path
        fill="#FABB05"
        d="M7.299 18.01c0-1.14.19-2.242.534-3.278v-4.416H2.157A17.081 17.081 0 0 0 .339 18.01c0 2.768.657 5.38 1.818 7.694l5.676-4.416A10.356 10.356 0 0 1 7.3 18.01Z"
      ></path>
      <path
        fill="#E94235"
        d="M17.428 7.66c2.52 0 4.775.87 6.556 2.57l4.877-4.885C25.9 2.579 22.038.88 17.428.88c-6.676 0-12.459 3.84-15.271 9.436l5.677 4.416c1.354-4.06 5.136-7.072 9.594-7.072Z"
      ></path>
    </svg>
  );
};
