import SnackBar from "@material-ui/core/Snackbar";
import React from "react";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";

interface Props {}

const PopMessage: React.FC<Props> = () => {
  const { isMsgOpen, message } = useMessageState();

  return (
    <SnackBar
      open={isMsgOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">{message}</span>}
      style={{
        zIndex: 100000,
      }}
    />
  );
};

export default PopMessage;
