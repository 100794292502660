import {
  Box,
  Button,
  Divider,
  Link,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import * as React from "react";
import { BsCreditCard } from "react-icons/bs";
import { Student } from "types/apiTypes";
import { PAYMENT_TYPE } from "types/core";
import { getErrors } from "utils/functions";
import { useUser } from "utils/store";
import "./css/CardSectionStyles.css";

interface Props {
  clientSecret: string;
  setStep: (step: number) => void;
  amount: number;
  paymentType: PAYMENT_TYPE;
}

const StripeCheckoutForm: React.FC<Props> = ({
  clientSecret,
  setStep,
  amount,
  paymentType,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [user] = useUser<Student>();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!stripe || !elements) {
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          //@ts-ignore
          card: elements.getElement("cardNumber"),
          billing_details: {
            name: user?.username || "User",
          },
        },
      });

      if (result.error) {
        // Show error to your customer (for example, insufficient funds)
        toast({
          title: result.error.message,
          position: "bottom-right",
          status: "error",
        });
        setLoading(false);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          toast({
            title: "Payment Succeeded",
            position: "bottom-right",
            status: "success",
          });

          setStep(7);
        }
      }
    } catch (err: any) {
      const errors = getErrors(err);
      errors.forEach((e) => {
        toast({
          title: e,
          position: "bottom-right",
          status: "error",
        });
      });
      setLoading(false);
      setStep(8);
    }
  };

  React.useEffect(() => {
    if (!elements || paymentType !== PAYMENT_TYPE.CARD) return;
    const cardNumberElement = elements?.create("cardNumber");
    const cardExpiryElement = elements?.create("cardExpiry");
    const cardCvcElement = elements?.create("cardCvc");

    cardNumberElement?.mount("#card-number");
    cardExpiryElement?.mount("#card-expiry");
    cardCvcElement?.mount("#card-cvc");
  }, [elements, paymentType]);

  React.useEffect(() => {
    if (
      !stripe ||
      !elements ||
      !clientSecret ||
      paymentType !== PAYMENT_TYPE.APPLE_GOOGLE_PAY
    )
      return;
    const paymentRequest = stripe.paymentRequest({
      country: "IT",
      currency: "eur",
      total: {
        label: "Total",
        amount: amount * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const prButton = elements.create("paymentRequestButton", {
      paymentRequest: paymentRequest,
    });
    paymentRequest.canMakePayment().then(function (result) {
      if (result) {
        prButton.mount("#payment-request-button");
      } else {
        const paymentRequestBtn = document.getElementById(
          "payment-request-button",
        );
        if (paymentRequestBtn) {
          paymentRequestBtn.style.display = "none";
        }
      }
    });

    paymentRequest.on("paymentmethod", async (e) => {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: e.paymentMethod.id,
        },
        {
          handleActions: false,
        },
      );

      if (error) {
        e.complete("fail");
        toast({
          title: error.message,
          position: "bottom-right",
          status: "error",
        });
        setLoading(false);
        setStep(8);
        return;
      }

      e.complete("success");

      if (paymentIntent.status === "requires_action") {
        await stripe.confirmCardPayment(clientSecret);
      }

      toast({
        title: "Payment Succeeded",
        position: "bottom-right",
        status: "success",
      });

      setStep(7);
    });
  }, [stripe, elements, amount, clientSecret, toast, setStep, paymentType]);

  return (
    <Box my="8">
      {paymentType === PAYMENT_TYPE.APPLE_GOOGLE_PAY && (
        <Box mb="8" id="payment-request-button" />
      )}
      <Divider />

      {paymentType === PAYMENT_TYPE.CARD && (
        <Box mt="8">
          <Box textAlign={"center"} fontSize="lg">
            Pay securely with{" "}
            <Link
              href="https://stripe.com"
              color="blue"
              borderBottom="1px"
              target="_blank"
            >
              Stripe
            </Link>
          </Box>
          <form onSubmit={handleSubmit}>
            <Text mb="2" color="gray.600" fontSize={"base"} textAlign="center">
              Please Enter your Card Details
            </Text>
            <VStack spacing={"3"} mb="3">
              <Box id="card-number"></Box>
              <Box id="card-expiry"></Box>
              <Box id="card-cvc"></Box>
            </VStack>

            <Button
              w="full"
              borderRadius={"sm"}
              bg="purple.100"
              color="purple.700"
              disabled={!stripe || loading}
              type="submit"
              leftIcon={<BsCreditCard size="20" />}
              isLoading={loading}
              loadingText={
                "Payment is Processing... Please don't close this window"
              }
            >
              Pay Now
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default StripeCheckoutForm;
