import { CalendarIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Grid,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { months } from "components/Group/GroupClassBox";
import AlertBox from "components/PaymentStudent/AlertBox";
import { api } from "config";
import * as React from "react";
import { FaQuestion, FaVideo } from "react-icons/fa";
import { Group, Month, Quiz, Video } from "types/apiTypes";
import { useShowSidebar } from "utils/store";
import ContentModal from "./ContentModal";
import QuizPaper from "./QuizPaper";
import ReviewPaper from "./ReviewPaper";
import "./styles/index.css";

interface Props {
  groupMonths: Month[];
  group?: Group;
}

export interface CurrentContent extends Quiz, Video {
  contentType: string;
}

const ViewClassContent: React.FC<Props> = ({ groupMonths, group }) => {
  const [selectedMonthIndex, setSelectedMonthIndex] = React.useState<number>(0);
  const [, showSidebar] = useShowSidebar(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentContent, setCurrentContent] = React.useState<CurrentContent>();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)");
  const [paperConfig, setPaperConfig] = React.useState({
    // 0 -> while doing paper
    // 1 -> reviewing the results
    stage: 0,
    paper: null,
  });

  React.useEffect(() => {
    showSidebar(false);

    return () => showSidebar(true);
  }, []);

  return (
    <Box mb="8" ml={{ base: "0", md: "4" }}>
      <Tabs
        mt={{ base: "1", md: "6" }}
        variant="soft-rounded"
        orientation={isLargerThanMd ? "vertical" : "horizontal"}
        isLazy
        defaultIndex={0}
      >
        <TabList
          bg="white"
          p="3"
          overflowX={{ base: "auto", md: "hidden" }}
          minW={"60"}
        >
          {groupMonths.map((groupMonth, index) => {
            return (
              <Tab
                mb="3"
                color="blue.700"
                key={groupMonth.month - 1}
                onClick={() => setSelectedMonthIndex(index)}
                isDisabled={groupMonth.blocked}
              >
                {groupMonth.blocked ? (
                  <LockIcon mr="2" />
                ) : (
                  <CalendarIcon mr="2" />
                )}
                <Text
                  fontSize={{ base: "sm", md: "base" }}
                  textAlign="left"
                  px="1"
                >
                  {months[groupMonth.month - 1]} {groupMonth.year}
                </Text>
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          {groupMonths.map((groupMonth, index) => (
            <TabPanel mt="-5" key={groupMonth.month - 1}>
              <Box mb="4">
                <Box display="inline-block" mb="1">
                  <Text
                    // fontWeight={"bold"}
                    color="gray.600"
                    px="2"
                    borderRadius={"md"}
                    my="2"
                    py="1"
                    mb="2"
                    fontSize={"xl"}
                    fontFamily="Lexend"
                    display="flex"
                    alignItems={"center"}
                    bg="yellow.300"
                  >
                    <FaQuestion />
                    <Text ml="3">Quizzes</Text>
                  </Text>
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)",
                    md: "repeat(7, 1fr)",
                  }}
                  gap="2"
                >
                  {groupMonths[selectedMonthIndex].quizzes
                    .sort((a, b) => a.quizNo - b.quizNo)
                    .map((quiz, index) => (
                      <Flex
                        shadow="base"
                        py="2"
                        key={quiz.id}
                        bg="white"
                        direction={"column"}
                        alignItems="center"
                        cursor="pointer"
                        textAlign={"center"}
                        onClick={() => {
                          setCurrentContent({ ...quiz, contentType: "quiz" });
                          onOpen();
                        }}
                      >
                        <Image src={`${api}${quiz.image}`} h="full" w="full" />
                        <Text
                          fontSize={{ base: "sm", md: "base" }}
                          color="gray.700"
                          fontWeight="normal"
                        >
                          {quiz.title}
                        </Text>
                        <Text
                          color="gray.500"
                          fontSize={{ base: "xs", md: "sm" }}
                        >
                          {quiz.content}
                        </Text>
                      </Flex>
                    ))}
                </Grid>
              </Box>
              {/* <Divider /> */}
              <Box mt="3">
                <Box display="inline-block" mb="1">
                  <Text
                    mb="2"
                    color="gray.600"
                    py="1"
                    my="2"
                    px="4"
                    fontSize={"xl"}
                    fontFamily="Lexend"
                    display="flex"
                    alignItems={"center"}
                    bg="yellow.300"
                    borderRadius={"md"}
                  >
                    <FaVideo />
                    <Text ml="3">Videos</Text>
                  </Text>
                </Box>

                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)",
                    md: "repeat(7, 1fr)",
                  }}
                  gap="2"
                >
                  {groupMonths[selectedMonthIndex].videos
                    .sort((a, b) => a.quizNo - b.quizNo)
                    .map((video, index) => (
                      <Flex
                        shadow="base"
                        py="2"
                        key={video.id}
                        bg="white"
                        direction={"column"}
                        alignItems="center"
                        cursor="pointer"
                        textAlign={"center"}
                        onClick={() => {
                          setCurrentContent({ ...video, contentType: "video" });
                          onOpen();
                        }}
                      >
                        <Image src={`${api}${video.image}`} h="full" w="full" />
                        <Text
                          fontSize={{ base: "sm", md: "base" }}
                          fontWeight="normal"
                          px="2"
                        >
                          {video.title}
                        </Text>

                        <Text color="gray.500" fontSize={"sm"}>
                          {video.content}
                        </Text>
                      </Flex>
                    ))}
                </Grid>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      {isOpen && currentContent && (
        <ContentModal
          isOpen={isOpen}
          onClose={() => {
            setOpenAlert(true);
          }}
          title={currentContent?.title}
        >
          <Grid
            templateColumns={{ base: "1fr", md: "2fr 7fr" }}
            mr={{ base: "0", md: "7" }}
          >
            <Box>
              <Box textAlign={"center"}>
                <Image
                  src={`${api}${currentContent.image}`}
                  height="1/2"
                  w="full"
                  px="2"
                  display={{ base: "none", md: "block" }}
                />
                <Box bg="blue.600" p="2" py="3">
                  <Text
                    fontSize={"lg"}
                    fontWeight="normal"
                    px="5"
                    color="white"
                  >
                    {currentContent.title}
                  </Text>

                  <Text color="gray.100">{currentContent.content}</Text>
                </Box>
              </Box>
              {/* {currentContent.quizChoice === "P" ? (
                <PreviousPapers
                  paperId={currentContent.paper as string}
                  paperConfig={paperConfig}
                  setPaperConfig={setPaperConfig}
                />
              ) : (
                <></>
              )} */}
            </Box>
            {currentContent.contentType === "quiz" ? (
              currentContent.quizChoice === "P" ? (
                <>
                  {paperConfig.stage === 0 && (
                    <QuizPaper
                      paperId={currentContent?.paper as string}
                      paperConfig={paperConfig}
                      setPaperConfig={setPaperConfig}
                    />
                  )}
                  {paperConfig.stage === 1 && (
                    <ReviewPaper
                      paperConfig={paperConfig}
                      setPaperConfig={setPaperConfig}
                    />
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  maxW="100vw"
                  dangerouslySetInnerHTML={{
                    __html: currentContent?.link as string,
                  }}
                ></Box>
              )
            ) : (
              <Box
                pr={{ base: "0", md: "3" }}
                pt={{ base: "3", md: "0" }}
                dangerouslySetInnerHTML={{
                  __html: currentContent?.link as string,
                }}
              ></Box>
            )}
          </Grid>
        </ContentModal>
      )}
      <AlertBox
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        handleConfirm={onClose}
      />
    </Box>
  );
};

export default ViewClassContent;
