import { Box, Center, Grid, Heading, useToast } from "@chakra-ui/react";
import axios from "axios";
import { api } from "config";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";

const AllClasses: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const [groups, setGroups] = React.useState<Group[]>([]);

  //get all group classes
  const getGroups = async () => {
    try {
      const response = await axios.get(`${api}/groups`, {
        withCredentials: true,
      });
      setGroups(response.data);
    } catch (error: any) {}
  };

  React.useEffect(() => {
    getGroups();
  }, []);

  const handleClickClass = (id: number) => {
    history.push(`/admin/group-papers/${id}`);
  };

  return (
    <Box my="8">
      <Box my="6" mx="3">
        <Grid
          templateColumns={{
            sm: "1fr",
            md: groups.length === 1 ? "1fr" : "1fr 1fr 1fr 1fr 1fr",
          }}
          gridGap="3"
        >
          {groups.map((group, index) => {
            return (
              <Box
                key={index}
                w="full"
                onClick={() => handleClickClass(group.id)}
                shadow="base"
                cursor={"pointer"}
                bg="white"
                _hover={{
                  scale: "1.2",
                }}
                p="4"
              >
                <Center>
                  {/* <Image
                    src={`${api}${group.image}`}
                    height={{ sm: "32", md: "52" }}
                  /> */}
                  <Heading
                    fontFamily={"Lexend"}
                    fontSize={{ sm: "xl", md: "xl" }}
                    fontWeight={"normal"}
                    textAlign="center"
                  >
                    {group.groupName}
                  </Heading>
                </Center>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default AllClasses;
