import { Grid, Heading } from "@chakra-ui/react";
import { Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import SelectFormField from "components/Misc/FormComponents/SelectFormField";
import SubmitBtn from "components/Misc/FormComponents/SubmitBtn";
import { Field, Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api, apiLink } from "../../config";
import QuizzesSubForm from "./QuizzesSubForm";

yup.addMethod(
  yup.array,
  "unique",
  function (message: any, mapper = (a: any) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  },
);

const schema = yup.object({
  code: yup.string().required("Code is required"),
  topic: yup.string().required("Topic is required"),
  category: yup.string().required("Category is required"),
  quizzes: yup
    .array()
    .of(
      yup.object().shape({
        qNo: yup.string().required("Quiz No is required"),
        question: yup.string().required("Question is required"),
        isTrue: yup.boolean(),
      }),
    )
    .required("Quizzes are required")
    .min(1)
    .typeError("At least 1 quiz must be selected"),
});

type Paper = {
  id: number;
  topic: string;
  subTopic: string;
  code: string;
  category: string;
  paper_quizzes: {
    qNo: string;
    question: string;
    isTrue: boolean;
    image: string;
    italianVoiceRecord: string;
    sinhalaVoiceRecord: string;
    sinhalaText: string;
  }[];
};

type Props = {
  paper?: Paper;
};

type SubmitType = {
  topic: string;
  subTopic: string;
  code: string;
  category: string;
  quizzes: {
    qNo: string;
    question: string;
    isTrue: boolean;
    image: string;
    italianVoiceRecord: string;
    sinhalaVoiceRecord: string;
    sinhalaText: string;
  }[];
};

const QuizzesForm: React.FC<Props> = ({ paper }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({
    topic,
    subTopic,
    code,
    category,
    quizzes,
  }: SubmitType) => {
    setLoading(true);
    const data = quizzes.map((quiz) => ({
      qNo: parseInt(quiz.qNo),
      question: quiz.question,
      isTrue: quiz.isTrue,
      image: quiz.image,
      italianVoiceRecord: quiz.italianVoiceRecord,
      sinhalaVoiceRecord: quiz.sinhalaVoiceRecord,
      sinhalaText: quiz.sinhalaText,
    }));

    try {
      if (paper) {
        const res = await axios.put(
          `${apiLink}/papers/${paper?.id}`,
          {
            topic,
            subTopic,
            code,
            category,
            paper_quizzes: data,
          },
          { withCredentials: true },
        );
      } else {
        const response = await axios.post(
          `${api}/papers`,
          {
            topic,
            code,
            subTopic,
            category,
            paper_quizzes: data,
          },
          {
            withCredentials: true,
          },
        );
      }
      history.push(`/admin/papers/${category.toLowerCase()}`);
    } catch (err: any) {
      console.log(err);
    }
    setLoading(false);
  };

  const paperQuizzes = useMemo(() => {
    return paper
      ? paper?.paper_quizzes.sort((a: any, b: any) => a.qNo - b.qNo)
      : [
          {
            qNo: "",
            question: "",
            isTrue: false,
            image: "",
            italianVoiceRecord: "",
            sinhalaVoiceRecord: "",
            sinhalaText: "",
          },
        ];
  }, [paper]);

  return (
    <div>
      <Paper className={""}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                code: paper?.code || "",
                topic: paper?.topic || "",
                subTopic: paper?.subTopic || "",
                category: paper?.category || "",
                quizzes: paperQuizzes,
              }}
              validationSchema={schema}
              validateOnChange={false}
            >
              {({ values, errors, isValid, validateForm, setFieldValue }) => (
                <Form>
                  {/* {JSON.stringify(values)} */}
                  <Heading
                    fontSize="xl"
                    textAlign={"center"}
                    pt="4"
                    fontFamily="Lexend, sans-serif"
                  >
                    Add Quizzes
                  </Heading>
                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 2fr" }}
                    gap="3"
                    alignItems={"center"}
                  >
                    <Field
                      label="Code"
                      name="code"
                      component={ChakraInput}
                      fullWidth
                    />
                    <Field
                      label="Topic"
                      name="topic"
                      component={ChakraInput}
                      fullWidth
                    />
                    <Field
                      name="category"
                      component={SelectFormField}
                      label="Category"
                      options={[
                        { label: "Theory Paper", value: "TP" },
                        { label: "Theory Weekend Paper", value: "TWP" },
                        { label: "Revision Paper", value: "RP" },
                        { label: "Revision Weekend Paper", value: "RWP" },
                      ]}
                    />
                    <Field
                      label="Sub Topic"
                      name="subTopic"
                      component={ChakraInput}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <QuizzesSubForm
                    quizzes={values.quizzes}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                  />
                  <div>
                    {typeof errors.quizzes !== "undefined" &&
                    typeof errors.quizzes === "string" ? (
                      <div style={{ color: "red" }}>
                        {JSON.stringify(errors.quizzes, null, 2)?.replace(
                          /"/g,
                          "",
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <SubmitBtn
                    loading={loading}
                    disabled={!isValid || loading}
                    w="100%"
                    my="3"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default QuizzesForm;
