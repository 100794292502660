import { useMutation } from "@apollo/client";
import { Box, Heading, Image, useToast } from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import FileUpload from "components/Misc/FormComponents/FileUpload";
import { LOGOUT_MUTATION } from "components/Navbars/AdminNavbarLinks";
import { Field, Form, Formik } from "formik";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getErrors } from "utils/functions";
import { useUser } from "utils/store";
import * as yup from "yup";
import { api } from "../../config";
import { Student } from "../../types/apiTypes";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";

// form validations
const schema = yup.object({
  name: yup.string().required("Name is Required"),
});

interface Props {}

const ProfileForm: React.FC<Props> = () => {
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useUser<Student | undefined>();
  const [profile, setProfile] = useState<Student | undefined>();
  const [logout] = useMutation(LOGOUT_MUTATION);

  const handleLogout = async () => {
    try {
      await logout();
      sessionStorage.clear();
      localStorage.clear();
      Cookies.remove("role", { path: "/", domain: ".app.italyclasses.com" });
      history.push("/login");
    } catch (err: any) {
      console.log(err);
    }
  };
  const handleSubmit = async (values: any) => {
    if (!values.image) {
      toast({
        title: "Profile Image is required",
        position: "bottom-right",
        status: "error",
      });
      return;
    }
    if (values.email !== profile?.email) {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          "Changing email address will require you to re-login. Do you want to continue?",
        ) === false
      ) {
        return;
      }
    }
    setLoading(true);
    try {
      let data = {
        name: values.name,
        image: values.image,
        email: values.email,
        password: values.password,
      };
      if (values.password) {
        if (values.password !== values.confirmPassword) {
          toast({
            title: "Passwords do not match",
            position: "bottom-right",
            status: "error",
          });
          setLoading(false);
          return;
        }
      } else {
        delete data.password;
      }
      // console.log(data);
      const result = await axios.put(`${api}/users/${user?.id}`, data, {
        withCredentials: true,
      });

      setUser(result.data);

      toast({
        title: `Profile Updated Succefully`,
        position: "bottom-right",
        status: "success",
      });
      if (values.email !== profile?.email) handleLogout();

      history.push("/");
    } catch (error: any) {
      const errors = getErrors(error);
      errors.forEach((e) => {
        toast({
          title: e,
          position: "bottom-right",
          status: "error",
        });
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const getProfile = async () => {
    const result = await axios.get(`${api}/users/me`, {
      withCredentials: true,
    });
    setProfile(result.data);
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  return (
    <div>
      <Paper>
        <Container component="main">
          <CssBaseline />
          <div>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                name: profile?.name || "",
                image: profile?.image || "",
                code: profile?.username,
                email: profile?.email || "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={schema}
              enableReinitialize
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Heading
                    fontSize={"xl"}
                    py="3"
                    fontFamily={"Lexend"}
                    color="gray.600"
                  >
                    Update Profile
                  </Heading>
                  <Field
                    name="code"
                    component={ChakraInput}
                    label="Codice Id"
                    disabled
                  />
                  <Field
                    name="name"
                    component={ChakraInput}
                    label="First Name"
                    w="full"
                  />
                  <Field
                    name="email"
                    component={ChakraInput}
                    label="Email"
                    w="full"
                    type="email"
                  />
                  <Field
                    name="password"
                    component={ChakraInput}
                    label="New Password"
                    w="full"
                    type="password"
                  />
                  <Field
                    name="confirmPassword"
                    component={ChakraInput}
                    label="Confirm New Password"
                    w="full"
                    type="password"
                  />
                  <Box mt="5">
                    {values.image && (
                      <Image
                        src={`${api}${values.image}`}
                        alt="a"
                        style={{ height: "100px" }}
                        ml="5"
                        mb="4"
                      />
                    )}
                    <FileUpload
                      handleSetFieldValue={setFieldValue}
                      handleFieldName={`image`}
                      text={`Upload Profile Image`}
                    />
                  </Box>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" my="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default ProfileForm;
