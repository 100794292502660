import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MessageStateProvider } from "./components/LocalStateProviders/MessageStateProvider";
import PopMessage from "./components/Misc/PopMessage";
import { api, cloudApi } from "./config";
import "./index.css";
// 2. Extend the theme to include custom colors, fonts, etc

const colors = {
  font: {
    body: "Roboto",
  },
  // brand: {
  //   900: "#1a365d",
  //   800: "#153e75",
  //   700: "#2a69ac",
  // },
};

const fonts = {
  heading: "Lexend",
  button: "Lexend, sans-serif",
};

const styles = {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
  },
};

const theme = extendTheme({ colors, styles, fonts });

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: new HttpLink({
    uri: process.env.NODE_ENV === "development" ? api : cloudApi, //"http://localhost:4000/graphql",
    credentials: "include",
  }),
  resolvers: {},
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <MessageStateProvider>
        <App />
        <PopMessage />
      </MessageStateProvider>
    </ChakraProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
