import { Box, Button } from "@chakra-ui/react";
import { ApplePayIcon } from "icons/ApplePayIcon";
import { GooglePayIcon } from "icons/GooglePayIcon";

interface Props {
  onClick: () => void;
}

export const AppleGooglePayButton = ({ onClick }: Props) => {
  return (
    <Button
      // leftIcon={<PayIcon w="6" h="6" />}
      bg="gray.100"
      fontSize={{ sm: "xs", md: "sm" }}
      mt={{ base: "3", md: 2 }}
      fontWeight={"normal"}
      onClick={onClick}
      border="1px solid #d3d3d3"
    >
      <Box
        display={"grid"}
        gridTemplateColumns="2fr 1fr 2fr"
        alignItems={"center"}
        gridGap="1"
      >
        <ApplePayIcon />
        /
        <GooglePayIcon />
      </Box>
    </Button>
  );
};
