/*eslint-disable*/
// chakra imports
import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Img,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "components/Layout/Logo";
import useStore from "utils/store";

// this function creates the links and collapses that appear in the sidebar (left menu)

const SidebarContent = ({ logoText, routes }) => {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});

  const announcementsCount = useStore((state) => state.announcementsCount);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const createAnnouncementIcon = (path) => {
    return (
      <>
        {announcementsCount > 0 && path === "/announcements" ? (
          <Box
            bgColor="red.500"
            color="white"
            rounded="full"
            fontSize="sm"
            px="2"
            py="0.5"
            ml="2"
            display="inline-block"
          >
            {announcementsCount}
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  const createLinks = (routes) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");

    return routes
      .filter((rt) => rt.show === true)
      .map((prop, key) => {
        if (prop.redirect) {
          return null;
        }
        if (prop.category) {
          var st = {};
          st[prop["state"]] = !state[prop.state];
          return (
            <div key={prop.name}>
              <Text
                color={activeColor}
                fontFamily="body"
                fontWeight="bold"
                mb={{
                  xl: "12px",
                }}
                mx="auto"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
              >
                {prop.name}
                {createAnnouncementIcon(prop.path)}
              </Text>
              {createLinks(prop.views)}
            </div>
          );
        }
        return (
          <NavLink to={prop.layout + prop.path} key={prop.name}>
            {activeRoute(prop.layout + prop.path) === "active" ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg={activeBg}
                mb={{
                  xl: "12px",
                }}
                mx={{
                  xl: "auto",
                }}
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                py="12px"
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "md",
                }}
                shadow="md"
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <></>
                  ) : (
                    <IconBox
                      bg="blue.300"
                      color="white"
                      h="32px"
                      w="32px"
                      me="12px"
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text
                    color={activeColor}
                    my="auto"
                    fontSize="sm"
                    fontFamily={"Lexend"}
                  >
                    {prop.name}
                    {createAnnouncementIcon(prop.path)}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={{
                  xl: "12px",
                }}
                mx={{
                  xl: "auto",
                }}
                py="12px"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                borderRadius="15px"
                _hover="none"
                w="100%"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
              >
                <Flex>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg={inactiveBg}
                      color="blue.300"
                      h="30px"
                      w="30px"
                      me="12px"
                    >
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text
                    color={inactiveColor}
                    my="auto"
                    fontSize="sm"
                    fontFamily={"Lexend"}
                  >
                    {prop.name}
                    {createAnnouncementIcon(prop.path)}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      });
  };

  const links = <>{createLinks(routes)}</>;

  return (
    <>
      <Box mb="22px" fontFamily="body">
        <Logo />
        <Separator></Separator>
      </Box>
      <Stack direction="column" mb="40px">
        <Box>{links}</Box>
      </Stack>
      <SidebarHelp />
    </>
  );
};

export default SidebarContent;
