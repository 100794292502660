import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import * as React from "react";

interface Props {
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
  openAlert: boolean;
  handleCancel?: () => void;
  handleConfirm?: () => void;
}
const AlertBox: React.FC<Props> = ({
  setOpenAlert,
  openAlert,
  handleCancel,
  handleConfirm,
}) => {
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)");

  const onCancel = () => {
    setOpenAlert(false);
    if (handleCancel) handleCancel();
  };
  const onConfirm = () => {
    setOpenAlert(false);
    if (handleConfirm) handleConfirm();
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCancel}
        isOpen={openAlert}
        isCentered
        size={isLargerThanMd ? "md" : "xs"}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Exit?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Are you sure you want to cancel?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={onConfirm}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AlertBox;
