import { Flex, Grid, Heading, Image } from "@chakra-ui/react";
import { Button } from "@material-ui/core";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import ChakraTextArea from "components/Misc/FormComponents/ChakraTextArea";
import { apiLink } from "config";
import { Field, FieldArray } from "formik";
import React from "react";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import FileUpload from "../Misc/FormComponents/FileUpload";

interface Props {
  quizzes: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  validateForm: any;
}

const QuizzesSubForm: React.FC<Props> = ({
  quizzes,
  setFieldValue,
  validateForm,
}) => {
  return (
    <FieldArray name="quizzes">
      {(arrayHelpers) => (
        <div>
          <Heading fontSize="lg" color="gray.700" fontWeight={"medium"}>
            Quizzes
          </Heading>
          {quizzes && quizzes.length > 0 ? (
            quizzes.map((quiz: string, index: number) => (
              <Flex
                border={"1px solid gray"}
                alignItems="center"
                key={index}
                flexDir="column"
                width={"100%"}
                p={2}
                m={1}
                borderRadius={5}
              >
                <Grid
                  gridTemplateColumns={{
                    base: "1fr",
                    md: "0.5fr 2fr 2fr 0.5fr",
                  }}
                  gap="4"
                >
                  <Field
                    label="Quiz No"
                    component={ChakraInput}
                    // type="number"
                    name={`quizzes.${index}.qNo`}
                  />
                  <Field
                    label="Question"
                    component={ChakraTextArea}
                    // type="number"
                    name={`quizzes.${index}.question`}
                    multiline
                  />
                  <Field
                    label="Sinhala Text"
                    component={ChakraTextArea}
                    // type="number"
                    name={`quizzes.${index}.sinhalaText`}
                    multiline
                  />
                  <Field
                    label="True Or False"
                    component={CheckboxFormField}
                    checked={quizzes[index].isTrue}
                    // type="number"
                    name={`quizzes.${index}.isTrue`}
                  />
                </Grid>

                <Grid
                  gridTemplateColumns={{
                    base: "1fr",
                    md: "0.2fr 2fr 0.5fr 0.5fr",
                  }}
                  alignItems="center"
                  gap="4"
                >
                  <Flex alignItems={"center"} direction="column">
                    {quizzes[index].image && (
                      <Image
                        src={`${apiLink}${quizzes[index].image}`}
                        height="50px"
                        width="50px"
                        alt=""
                        objectFit={"cover"}
                      />
                    )}

                    <FileUpload
                      text="Upload Image"
                      handleSetFieldValue={setFieldValue}
                      handleFieldName={`quizzes.${index}.image`}
                    />
                  </Flex>
                  <Flex alignItems={"center"} direction="column">
                    {quizzes[index].italianVoiceRecord && (
                      <audio controls>
                        <source
                          src={`${apiLink}${quizzes[index].italianVoiceRecord}`}
                          type="audio/mpeg"
                        />
                        <source
                          src={`${apiLink}${quizzes[index].italianVoiceRecord}`}
                          type="audio/x-m4a"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    )}

                    <FileUpload
                      text="Upload Italian Voice Record"
                      handleSetFieldValue={setFieldValue}
                      handleFieldName={`quizzes.${index}.italianVoiceRecord`}
                      acceptFileTypes="audio/mpeg,audio/x-m4a"
                    />
                  </Flex>
                  <Flex alignItems={"center"} direction="column">
                    {quizzes[index].sinhalaVoiceRecord && (
                      <audio controls>
                        <source
                          src={`${apiLink}${quizzes[index].sinhalaVoiceRecord}`}
                          type="audio/mpeg"
                        />
                        <source
                          src={`${apiLink}${quizzes[index].sinhalaVoiceRecord}`}
                          type="audio/x-m4a"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    )}

                    <FileUpload
                      text="Upload Sinhala Voice Record"
                      handleSetFieldValue={setFieldValue}
                      handleFieldName={`quizzes.${index}.sinhalaVoiceRecord`}
                      acceptFileTypes="audio/mpeg,audio/x-m4a"
                    />
                  </Flex>
                </Grid>

                <Flex justifyContent={"center"}>
                  <Button
                    style={{
                      fontWeight: "bold",
                      justifySelf: "flex-end",
                      // fontSize: "1.2rem"
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      arrayHelpers.remove(index);
                      setTimeout(() => validateForm(), 200);
                    }}
                  >
                    -
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      fontWeight: "bold",
                      // fontSize: "1.2rem"
                    }}
                    onClick={() =>
                      arrayHelpers.insert(index + 1, {
                        qNo: "",
                        question: "",
                        isTrue: false,
                        image: "",
                        italianVoiceRecord: "",
                        sinhalaVoiceRecord: "",
                        sinhalaText: "",
                      })
                    } // insert an empty string at a position
                  >
                    +
                  </Button>
                </Flex>
              </Flex>
            ))
          ) : (
            <>
              <Button
                variant="contained"
                fullWidth
                onClick={() =>
                  arrayHelpers.push({
                    qNo: "",
                    question: "",
                    isTrue: false,
                  })
                }
              >
                Add Quizzes
              </Button>
            </>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default QuizzesSubForm;
