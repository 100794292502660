import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import { PayIcon } from "components/Icons/Icons";
import { AppleGooglePayButton } from "components/Misc/AppleGooglePayButton";
import GoBackButton from "components/Misc/GoBackButton";
import { api } from "config";
//@ts-ignore
import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { Payment } from "types/apiTypes";
import { PAYMENT_TYPE } from "types/core";
import PaymentModal from "./PaymentModal";

interface Props {}

const MyPaymentHistory: React.FC<Props> = () => {
  const [history, setHistory] = useState<Payment[]>([]);
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(
    PAYMENT_TYPE.CARD,
  );

  const getHistory = async () => {
    const res = await axios.get(`${api}/payments/my-payment-history`, {
      withCredentials: true,
    });
    setHistory(res.data);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Box px="2">
      <Flex
        justifyContent={"space-between"}
        // flexDir={{ base: "column", md: "row" }}
        alignItems="center"
        my={{ sm: "2", md: "2" }}
      >
        <GoBackButton />
        <div>
          <Button
            leftIcon={<BsCloudUpload size={18} />}
            bg="purple.700"
            color="white"
            border="1px"
            _hover={{
              bg: "purple.800",
            }}
            // borderColor="yellow.700"
            fontSize={{ sm: "xs", md: "sm" }}
            mt={{ base: "3", md: 2 }}
            fontWeight={"normal"}
            // w={{ base: "full", md: "auto" }}
            // w="full"
            onClick={() => {
              setPaymentModalOpen(true);
              setPaymentType(PAYMENT_TYPE.RECEIPT);
            }}
          >
            Receipt පත upload කරන්න.
          </Button>
          <Button
            leftIcon={<PayIcon w="6" h="6" />}
            bg="red.600"
            color="white"
            border="1px"
            fontSize={{ sm: "xs", md: "sm" }}
            mt={{ base: "3", md: 2 }}
            fontWeight={"normal"}
            // w={{ base: "full", md: "auto" }}
            _hover={{
              bg: "purple.800",
            }}
            onClick={() => {
              setPaymentModalOpen(true);
              setPaymentType(PAYMENT_TYPE.CARD);
            }}
          >
            Card Payment
          </Button>

          <AppleGooglePayButton
            onClick={() => {
              setPaymentModalOpen(true);
              setPaymentType(PAYMENT_TYPE.APPLE_GOOGLE_PAY);
            }}
          />
        </div>
      </Flex>
      <Heading fontFamily={"Lexend"} fontSize={"2xl"} my="3">
        My Payment History
      </Heading>
      <TableContainer bg="white" shadow="base">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Class</Th>
              <Th>Month</Th>
              <Th>Payment Date</Th>
              <Th>Payment Method</Th>
              <Th isNumeric>Paid Amount (Euro)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {history.map((h) => (
              <Tr>
                <Td>{h.month?.group.groupName}</Td>
                <Td>{months[(h.month?.month as number) - 1]}</Td>
                <Td>{dateFormat(new Date(h.paidDate), "yyyy-mm-dd")}</Td>
                <Td>
                  {h.type === "BS"
                    ? "Payment Slip"
                    : h.type === "ST"
                    ? "Stripe"
                    : h.type === "PH"
                    ? "Payhere"
                    : ""}
                </Td>
                <Td isNumeric>{h.paidAmount}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <PaymentModal
        openModal={paymentModalOpen}
        setOpenModal={setPaymentModalOpen}
        paymentType={paymentType}
      />
    </Box>
  );
};

export default MyPaymentHistory;
