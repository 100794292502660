import MyPaymentHistory from "components/PaymentStudent/MyPaymentHistory";
import React from "react";

interface Props {}

const MyPaymentHistoryPage: React.FC<Props> = () => {
  return <MyPaymentHistory />;
};

export default MyPaymentHistoryPage;
