import { Box, useStyleConfig } from "@chakra-ui/react";
function PanelContainer(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("PanelContainer", { variant });
  // Pass the computed styles into the `__css` prop

  styles.padding = "0";
  styles.paddingTop = "10";

  return (
    <Box __css={styles} {...rest} fontFamily="Lexend, sans-serif">
      {children}
    </Box>
  );
}

export default PanelContainer;
