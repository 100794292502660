import { Button, Grid, useToast } from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import SelectFormField from "components/Misc/FormComponents/SelectFormField";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../config";
import { Student } from "../../types/apiTypes";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import SelectFormFieldChip from "../Misc/FormComponents/SelectFormFieldChip";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import studentStyles from "./styles/groupStyles";

// form validations
const schema = yup.object({
  username: yup.string().required("Codice is Required"),
  email: yup.string().email("Invalid Email").required("Email is Required"),
  // password: yup.string().required("Password is Required"),
  // confirmPassword: yup.string().required("Confirm Password is Required"),
});

interface Props {
  student?: Student;
}

const StudentForm: React.FC<Props> = ({ student }) => {
  const isEdit = typeof student !== "undefined";
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState([]);

  const classes = studentStyles();

  const generatePassword = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const password = Math.random().toString(36).slice(-8);
    setFieldValue("password", password);
    setFieldValue("confirmPassword", password);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    toast({
      title: "Please wait...",
      position: "bottom-right",
      status: "info",
    });

    const data = {
      username: values.username,
      password: values.password,
      email: values.email,
      blocked: values.blocked,
      role: 1,
      groups: values.groups,
    };
    try {
      if (values.password !== values.confirmPassword) {
        toast({
          title: "Password Mismatch",
          position: "bottom-right",
          status: "error",
        });
        return;
      }
      if (data.password === "") delete data.password;
      setLoading(true);
      if (isEdit) {
        await axios.put(`${api}/users/${student?.id}`, data, {
          withCredentials: true,
        });
      } else {
        await axios.post(`${api}/users`, data, { withCredentials: true });
      }
      toast({
        title: `Student ${!isEdit ? "Added" : "Updated"} Successfully`,
        position: "bottom-right",
        status: "success",
      });

      if (!isEdit && values.isAppAccount) {
        toast({
          title: "Please wait while we create app accounts ...",
          position: "bottom-right",
          status: "info",
          duration: 5000,
        });

        const data = {
          username: values.username,
          email: values.email,
          password: values.password,
          isAppAccount: values.isAppAccount,
          isAppAccountPremium: values.isAppAccountPremium,
          appAccountPremiumType: values.appAccountPremiumType,
        };
        const ress = await axios.post(
          `${api}/app-coupons/register-users-for-app`,
          data
        );
        console.log(ress.data);
        const { successUsernames, failedUsernames } = ress.data;
        if (failedUsernames.length > 0) {
          toast({
            title: `${failedUsernames.length} App Account(s) Failed to Create`,
            position: "bottom-right",
            status: "error",
          });
        }
        if (successUsernames.length > 0) {
          toast({
            title: `${successUsernames.length} App Account(s) Created Successfully`,
            position: "bottom-right",
            status: "success",
          });
        }
        if (ress.status === 400) {
          toast({
            title: `App Account(s) Failed to Create`,
            position: "bottom-right",
            status: "error",
          });
        }
      }

      history.push("/admin/students");
    } catch (error: any) {
      console.log(error.response.data.message[0].messages[0].message);
      toast({
        title: error.response.data.message[0].messages[0].message,
        position: "bottom-right",
        status: "error",
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const getGroups = async () => {
    try {
      const res = await axios.get(`${api}/groups`, { withCredentials: true });
      const mappedGroups = res.data.map((group: any) => ({
        label: group.groupName,
        value: group.id,
      }));
      setGroups(mappedGroups);
    } catch (error: any) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getGroups();
  }, []);

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                username: isEdit ? student?.username : "",
                email: isEdit ? student?.email : "",
                blocked: isEdit ? student?.blocked : false,
                groups: isEdit ? student?.groups.map((a) => a.id) : [],
                isAppAccount: isEdit ? student?.isAppAccount : false,
                isAppAccountPremium: isEdit
                  ? student?.isAppAccountPremium
                  : false,
                appAccountPremiumType: isEdit
                  ? student?.appAccountPremiumType
                  : 6,
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} Student
                  </h3>
                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                    gap="3"
                  >
                    <Field
                      name="username"
                      component={ChakraInput}
                      label="Codice"
                      fullWidth
                      autoFocus
                    />
                    <Field
                      name="email"
                      component={ChakraInput}
                      label="Email"
                      fullWidth
                      type="email"
                    />
                  </Grid>

                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
                    alignItems="center"
                    gap="3"
                  >
                    <Button
                      wordBreak="break-word"
                      whiteSpace="pre-line"
                      mt="3"
                      onClick={() => generatePassword(setFieldValue)}
                    >
                      Generate Password
                    </Button>
                    <Field
                      name="password"
                      component={ChakraInput}
                      label="Password"
                      fullWidth
                      type="text"
                    />
                    <Field
                      name="confirmPassword"
                      component={ChakraInput}
                      label="Confirm Password"
                      fullWidth
                      type="text"
                    />
                  </Grid>
                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
                    gap="3"
                    rounded="md"
                    p="3"
                    my="2"
                    bgColor="orange.100"
                    hidden={isEdit}
                  >
                    <Field
                      name="isAppAccount"
                      component={CheckboxFormField}
                      label="Create app account"
                      variant="outlined"
                      defaultChecked={values.isAppAccount}
                      disabled={isEdit}
                    />
                    <Field
                      name="isAppAccountPremium"
                      component={CheckboxFormField}
                      label="Enable Premium"
                      variant="outlined"
                      defaultChecked={values.isAppAccountPremium}
                      disabled={(!isEdit && !values.isAppAccount) || isEdit}
                    />
                    <Field
                      name="appAccountPremiumType"
                      component={SelectFormField}
                      label="Premium Type"
                      placeholder="Select Premium Type"
                      options={[
                        { label: "6 Months", value: 6 },
                        { label: "12 Months", value: 12 },
                      ]}
                      defaultValue={6}
                      variant="outlined"
                      disabled={
                        (!isEdit && !values.isAppAccount) ||
                        isEdit ||
                        !values.isAppAccountPremium
                      }
                    />
                  </Grid>
                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 5fr" }}
                    alignItems="center"
                    gap="3"
                  >
                    <Field
                      name="blocked"
                      component={CheckboxFormField}
                      label="Inactive"
                      variant="outlined"
                      defaultChecked={values.blocked}
                    />
                    <Field
                      name="groups"
                      component={SelectFormFieldChip}
                      label="Groups"
                      placeholder="Select Groups"
                      multiple
                      options={groups}
                      variant="outlined"
                    />
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" my="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default StudentForm;
