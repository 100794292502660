import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
//@ts-ignore
import dateFormat from "dateformat";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Announcement } from "types/apiTypes";

interface Props {
  announcement?: Announcement;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
}
const AnnouncementModal: React.FC<Props> = ({
  setOpenModal,
  announcement,
  openModal,
}) => {
  const { pathname } = useLocation();
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)");
  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        size={isLargerThanMd ? "sm" : "xs"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Announcement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                {announcement?.title}
              </Text>
            </Box>
            <Box mb="3">
              <Text fontSize="sm" color="gray.600">
                {pathname === "/admin/announcements"
                  ? announcement?.dated
                  : dateFormat(
                      announcement?.dated,
                      "dddd, mmmm dS, yyyy, h:MM:ss TT"
                    )}
              </Text>
            </Box>
            <Box>
              <Text fontSize="base" color="gray.600" fontFamily={"Lexend"}>
                {announcement?.message}
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setOpenModal(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnnouncementModal;
