import { Box, Center, Grid, Heading, Image, useToast } from "@chakra-ui/react";
import axios from "axios";
import { api } from "config";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";

const ViewClasses: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const [groupClassess, setGroupClassess] = React.useState<Group[]>([]);

  //get all group classes
  const getGroupClassess = async () => {
    try {
      const response = await axios.get(`${api}/groups/my-classes`, {
        withCredentials: true,
      });
      console.log(response.data);
      setGroupClassess(response.data);
    } catch (error: any) {}
  };
  React.useEffect(() => {
    getGroupClassess();
  }, []);

  const handleClickClass = (id: number) => {
    history.push(`/admin/class-view-content/${id}`);
  };

  return (
    <Box my="8">
      <Box my="6" mx="3">
        <Grid
          templateColumns={{
            sm: "1fr",
            md: groupClassess.length === 1 ? "1fr" : "1fr 1fr",
          }}
        >
          {groupClassess.map((group, index) => {
            return (
              <Box
                key={index}
                w="full"
                onClick={() => handleClickClass(group.id)}
                shadow="base"
                cursor={"pointer"}
                bg="white"
                _hover={{
                  scale: "1.2",
                }}
              >
                <Center>
                  <Image
                    src={`${api}${group.image}`}
                    height={{ sm: "32", md: "52" }}
                  />
                  <Heading
                    fontFamily={"Lexend"}
                    fontSize={{ sm: "xl", md: "3xl" }}
                    fontWeight={"normal"}
                    textAlign="left"
                  >
                    {group.groupName}
                  </Heading>
                </Center>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewClasses;
