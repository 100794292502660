import {
  Box,
  Button,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import ChakraDropdown from "components/Misc/FormComponents/ChakraDropdown";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import ChakraRadio from "components/Misc/FormComponents/ChakraRadio";
import ChakraTextArea from "components/Misc/FormComponents/ChakraTextArea";
import FileUpload from "components/Misc/FormComponents/FileUpload";
import { api } from "config";
import { Field, Form, Formik } from "formik";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { FaQuestion } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Month, Quiz } from "../../types/apiTypes";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";

interface Props {
  groupMonths: Month[];
  selectedMonth: number;
  quizzes: any;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}
const schema = yup.object({
  title: yup.string().required("Required"),
  link: yup.string().required("Required"),
  content: yup.string().required("Required"),
});

const GroupClassQuizBox: React.FC<Props> = ({
  groupMonths,
  selectedMonth,
  quizzes,
  setRefresh,
}) => {
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz>();
  const [code, setCode] = useState("");
  const [foundPaper, setFoundPaper] = useState<any>();

  const handleRowClick = (event: any, quiz: Quiz) => {
    setSelectedQuiz(quiz);
    setOpenModal(true);
  };
  const handleSubmit = async (values: any) => {
    try {
      if (values.quizChoice === "P" && !foundPaper) {
        alert("Please select a paper");
        return;
      }

      const data = {
        title: values.title,
        quizNo: values.quizNo,
        content: values.content,
        group: groupMonths[0].group.id,
        month: groupMonths.find((m) => m.month === selectedMonth)?.id,
        link: values.link,
        image: values.image,
        quizChoice: values.quizChoice,
        paper: foundPaper ? foundPaper.id : null,
        category: values.category,
      };
      if (selectedQuiz) {
        const response = await axios.put(
          `${api}/quizzes/${selectedQuiz.id}`,
          data,
          { withCredentials: true }
        );
      } else {
        const response = await axios.post(`${api}/quizzes`, data, {
          withCredentials: true,
        });
      }
    } catch (error: any) {
      console.log(error);
    }
    //random integer to force refresh
    setOpenModal(false);
    setRefresh(Math.floor(Math.random() * 100));
  };

  const addQuiz = async () => {
    setSelectedQuiz(undefined);
    setOpenModal(true);
  };

  const deleteQuiz = async (id: string) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    try {
      await axios.delete(`${api}/quizzes/${id}`, {
        withCredentials: true,
      });
    } catch (error: any) {}
    //random integer to force refresh
    setRefresh(Math.floor(Math.random() * 100));
  };

  const searchCode = async (category: string) => {
    const result = await axios.get(
      `${api}/papers/basic-paper?code=${code}&category=${category}`,
      { withCredentials: true }
    );
    if (result.data.length) {
      setFoundPaper(result.data[0]);
    } else {
      setFoundPaper(null);
    }
  };

  useEffect(() => {
    const findPaper = async () => {
      const result = await axios.get(
        `${api}/papers/basic-paper?id=${selectedQuiz?.paper}&category=${selectedQuiz?.category}`,
        { withCredentials: true }
      );
      if (result.data.length) {
        setFoundPaper(result.data[0]);
        setCode(result.data[0].code);
      } else {
        setFoundPaper(null);
      }
    };
    if (selectedQuiz && selectedQuiz.paper) {
      findPaper();
    }
  }, [selectedQuiz]);

  return (
    <div>
      <Button
        my="3"
        bg="blue.300"
        fontWeight="normal"
        leftIcon={<FaQuestion />}
        color="gray.100"
        size="sm"
        onClick={addQuiz}
        _hover={{
          bg: "blue.300",
        }}
      >
        Add Quiz
      </Button>
      <Box>
        <MaterialTable
          title="Quizzes"
          columns={[
            // { title: "ID", field: "id" },
            { title: "Title", field: "title" },
          ]}
          data={quizzes}
          options={{
            rowStyle: {
              backgroundColor: "#EEE",
            },
            actionsColumnIndex: -1,
            pageSizeOptions: [10, 20, 30, 100],
            pageSize: 10,
          }}
          onRowClick={(event: any, rowData: any) =>
            handleRowClick(event, rowData)
          }
          actions={[
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event: any, rowData: any) => {
                deleteQuiz(rowData.id);
              },
            },
          ]}
        />
        <Box zIndex={4}>
          <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{selectedQuiz ? "Edit" : "Add"} Quiz</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Formik
                  onSubmit={(values) => handleSubmit(values)}
                  initialValues={{
                    title: selectedQuiz?.title || "",
                    quizNo: selectedQuiz?.quizNo || "",
                    content: selectedQuiz?.content || "",
                    link: selectedQuiz?.link || "",
                    image: selectedQuiz?.image || "",
                    quizChoice: selectedQuiz?.quizChoice || "EL",
                    paper: selectedQuiz?.paper || "",
                    category: selectedQuiz?.category || "TP",
                  }}
                  validationSchema={schema}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Field
                        name="quizNo"
                        component={ChakraInput}
                        label="Quiz No"
                        fullWidth
                      />
                      <Field
                        name="title"
                        component={ChakraInput}
                        label="Title"
                        fullWidth
                      />

                      <Field
                        name="content"
                        component={ChakraTextArea}
                        label="Content"
                        fullWidth
                        multiline
                      />
                      <Field
                        name="link"
                        component={ChakraTextArea}
                        label="Quiz Embed Link"
                        multiline
                        fullWidth
                      />
                      <Field
                        name="quizChoice"
                        component={ChakraRadio}
                        label="Quiz Choice"
                        type="radio"
                        options={[
                          { label: "Embedded Link", value: "EL" },
                          { label: "Paper", value: "P" },
                        ]}
                        defaultValue={values.quizChoice}
                        onChange={(choice: string) => {
                          setFieldValue("quizChoice", choice);
                        }}
                      />
                      <Grid
                        justifyContent={"space-between"}
                        gap="9px"
                        alignItems={"center"}
                        templateColumns="1.5fr 1fr 1fr"
                      >
                        <Field
                          name="category"
                          component={ChakraDropdown}
                          options={[
                            { label: "Theory Paper", value: "TP" },
                            { label: "Theory Weekend Paper", value: "TWP" },
                            { label: "Revision Paper", value: "RP" },
                            {
                              label: "Revision Weekend Paper",
                              value: "RWP",
                            },
                          ]}
                        />
                        <Input
                          placeholder="Search Code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <Button
                          size="md"
                          fontSize={"xs"}
                          onClick={() => searchCode(values.category)}
                        >
                          Search
                        </Button>
                      </Grid>
                      {foundPaper && <Box>{foundPaper.topic}</Box>}
                      <Box mt="5">
                        {values.image && (
                          <img
                            src={`${api}${values.image}`}
                            alt="a"
                            style={{ height: "100px", marginLeft: "10px" }}
                          />
                        )}
                        <FileUpload
                          handleSetFieldValue={setFieldValue}
                          handleFieldName={`image`}
                          text={`Upload thumbnail`}
                        />
                      </Box>
                      <SubmitBtn
                        loading={loading}
                        w="100%"
                        my="3"
                        btnText={selectedQuiz ? "Update" : "Add"}
                      />

                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    </div>
  );
};

export default GroupClassQuizBox;
