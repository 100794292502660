import React from "react";
import AppAccessForm from "../components/AppAccess/AppAccessForm";
import Loading from "../components/Misc/Loading";

const AppAccessFormPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  if (loading) return <Loading />;

  return (
    <div>
      {/* <GoBackButton /> */}
      <AppAccessForm />
    </div>
  );
};

export default AppAccessFormPage;
