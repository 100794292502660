import { Box, Button } from "@chakra-ui/react";
import { Grid } from "@material-ui/core";
import { CalendarIcon } from "components/Icons/Icons";
import { useState } from "react";
import { Month } from "../../types/apiTypes";
import GroupClassQuizBox from "./GroupClassQuizBox";
import GroupClassVideoBox from "./GroupClassVideoBox";

interface Props {
  groupMonths: Month[];
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  selectedMonth: number;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
}

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const GroupClassBox: React.FC<Props> = ({
  groupMonths,
  setRefresh,
  selectedMonth,
  setSelectedMonth,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Box w="100%">
      <Box my="3" mb="0">
        <Grid container spacing={3}>
          {groupMonths.map((groupMonth, index) => {
            return (
              <Grid item md={2} xs={6} sm={4} key={index}>
                <Button
                  w="full"
                  _hover={{
                    bg:
                      selectedMonth === groupMonth.month
                        ? "orange.300"
                        : "gray.200",
                    textColor:
                      selectedMonth === groupMonth.month ? "white" : "gray.700",
                  }}
                  bgColor={
                    selectedMonth === groupMonth.month
                      ? "orange.300"
                      : "gray.200"
                  }
                  textColor={
                    selectedMonth === groupMonth.month ? "white" : "gray.700"
                  }
                  onClick={() => setSelectedMonth(groupMonth.month)}
                  leftIcon={<CalendarIcon />}
                  fontWeight="normal"
                  fontSize={"sm"}
                >
                  {months[groupMonth.month - 1]} | {groupMonth.year}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box mb="6">
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <GroupClassQuizBox
              groupMonths={groupMonths}
              selectedMonth={selectedMonth}
              // quizzes={groupMonths.quizzes.filter(
              //   (quiz) => quiz.month === selectedMonth
              // )}
              quizzes={groupMonths
                .filter((groupMonth) => groupMonth.month === selectedMonth)[0]
                .quizzes.sort((a, b) => a.quizNo - b.quizNo)}
              setRefresh={setRefresh}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <GroupClassVideoBox
              groupMonths={groupMonths}
              selectedMonth={selectedMonth}
              videos={groupMonths
                .filter((groupMonth) => groupMonth.month === selectedMonth)[0]
                .videos.sort((a, b) => a.quizNo - b.quizNo)}
              setRefresh={setRefresh}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GroupClassBox;
