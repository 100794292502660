import { Avatar, Box, Flex, Heading, Text } from "@chakra-ui/react";
import { api } from "config";
import React from "react";
import { Student } from "types/apiTypes";
import { useUser } from "utils/store";

interface Props {}

const Greeting: React.FC<Props> = () => {
  const [user] = useUser<Student | undefined>();
  const name = user?.name || "Student";

  return (
    <Flex alignItems={"center"} mt="2">
      <Avatar name={name} size="xl" src={`${api}${user?.image}`} />
      <Box fontFamily="Lexend" w="100%" px="2" ml="3">
        <Heading
          fontSize={{ sm: "xl", md: "2xl" }}
          letterSpacing={"wide"}
          w="100%"
          fontFamily="Lexend"
        >
          {name}
        </Heading>
        <Text fontSize={{ sm: "base", md: "lg" }} color="gray.600">
          උත්සහයෙන් ජයගන්න!
        </Text>
      </Box>
    </Flex>
  );
};

export default Greeting;
