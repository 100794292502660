import { Box, Button, Input } from "@chakra-ui/react";
import { useEffect } from "react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { PAYMENT_TYPE } from "types/core";

interface Props {
  setStep: (step: number) => void;
  setVoucher: (voucher: string) => void;
  isVoucherValidate: number;
  validateVoucher: () => void;
  voucher: string;
  paymentType: PAYMENT_TYPE;
}

export const PaymentVoucherForm = ({
  setStep,
  setVoucher,
  isVoucherValidate,
  validateVoucher,
  voucher,
  paymentType,
}: Props) => {
  useEffect(() => {
    setVoucher("");
  }, [setVoucher]);

  useEffect(() => {
    if (voucher === "") {
      validateVoucher();
    }
  }, [voucher, validateVoucher]);

  const handleNext = () => {
    if (paymentType === PAYMENT_TYPE.RECEIPT) {
      setStep(4);
    } else if (
      paymentType === PAYMENT_TYPE.CARD ||
      paymentType === PAYMENT_TYPE.APPLE_GOOGLE_PAY
    ) {
      setStep(5);
    }
  };

  return (
    <>
      {isVoucherValidate !== -1 && (
        <Box>
          <Box fontSize="md" color="gray.600">
            Add a coupon
          </Box>
          <Box mt="2" mb="4">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Input
                placeholder="Coupon Code"
                variant="filled"
                onChange={(e) => setVoucher(e.target.value)}
              />
              <Button
                ml="2"
                marginRight="20px"
                onClick={validateVoucher}
                disabled={isVoucherValidate === 1 ? true : false}
              >
                Apply
              </Button>
              {isVoucherValidate === 1 ? (
                <CheckCircleIcon fontSize="3xl" color="green" />
              ) : isVoucherValidate === 2 ? (
                <CloseIcon fontSize="xl" color="red" />
              ) : (
                <></>
              )}
            </Box>
          </Box>

          <Button float="right" colorScheme="blue" onClick={handleNext}>
            Next
          </Button>
        </Box>
      )}
    </>
  );
};
