import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Month } from "../../types/apiTypes";
import GroupClassBox from "./GroupClassBox";
import groupStyles from "./styles/groupStyles";

interface Props {
  groupMonths: Month[];
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  selectedMonth: number;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
}

const GroupClassForm: React.FC<Props> = ({
  groupMonths,
  setRefresh,
  selectedMonth,
  setSelectedMonth,
}) => {
  const classes = groupStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <GroupClassBox
              groupMonths={groupMonths}
              setRefresh={setRefresh}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default GroupClassForm;
