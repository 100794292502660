import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { api, stripePublishableKey } from "config";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Coupon, Group } from "types/apiTypes";
import { PAYMENT_TYPE } from "types/core";
import AlertBox from "./AlertBox";
import PaymentErrorPage from "./PaymentErrorPage";
import PaymentSlipPage from "./PaymentSlipPage";
import PaymentStripePage from "./PaymentStripePage";
import PaymentSuccessPage from "./PaymentSuccessPage";
import { PaymentVoucherForm } from "./PaymentVoucherForm";
import SelectClassPage from "./SelectClassPage";
import SelectMonthPage from "./SelectMonthPage";

const stripePromise = loadStripe(stripePublishableKey);

interface Props {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  paymentType: PAYMENT_TYPE;
}

const PaymentModal: React.FC<Props> = ({
  setOpenModal,
  openModal,
  paymentType,
}) => {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [selectedClassIndex, setSelectedClassIndex] = React.useState(0);
  const [selectedMonthId, setSelectedMonthId] = React.useState(0);
  const [selectedMonthIds, setSelectedMonthIds] = React.useState<number[]>([]);
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)");
  const history = useHistory();
  const isMulti = true;
  const [groupClassess, setGroupClassess] = React.useState<Group[]>([]);

  const [voucher, setVoucher] = React.useState("");
  const [isVoucherValidate, setIsVoucherValidate] = React.useState(0);
  const [voucherDetails, setVoucherDetails] = React.useState<Coupon>();

  const validateVoucher = async () => {
    if (voucher !== "") {
      const res = await axios.get(`${api}/coupons/validate?coupon=${voucher}`, {
        withCredentials: true,
      });
      if (res.data) {
        setVoucherDetails(res.data);
        if (res.data.active) {
          setIsVoucherValidate(1);
        }
      } else {
        setIsVoucherValidate(2);
      }
    } else {
      setIsVoucherValidate(0);
    }
  };

  //get all group classes
  const getGroupClassess = async () => {
    try {
      const response = await axios.get(`${api}/groups/my-classes`, {
        withCredentials: true,
      });
      setGroupClassess(response.data);
    } catch (error: any) {}
  };

  React.useEffect(() => {
    getGroupClassess();
  }, []);

  React.useEffect(() => {
    if (step === 3) {
      setVoucher("");
      setVoucherDetails(undefined);
      setIsVoucherValidate(0);
    }
  }, [step]);

  const getStepContent = () => {
    switch (step) {
      case 1:
        return (
          <SelectClassPage
            groupClassess={groupClassess}
            setSelectedClass={setSelectedClassIndex}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <SelectMonthPage
            group={groupClassess[selectedClassIndex]}
            setSelectedMonth={setSelectedMonthId}
            setSelectedMonths={setSelectedMonthIds}
            selectedMonthIds={selectedMonthIds}
            isMulti={isMulti}
            setStep={setStep}
            paymentType={paymentType}
          />
        );
      case 3: {
        return (
          <PaymentVoucherForm
            paymentType={paymentType}
            setStep={setStep}
            setVoucher={setVoucher}
            isVoucherValidate={isVoucherValidate}
            validateVoucher={validateVoucher}
            voucher={voucher}
          />
        );
      }
      case 4:
        return (
          <PaymentSlipPage
            selectedMonthId={selectedMonthId}
            group={groupClassess[selectedClassIndex]}
            setStep={setStep}
            selectedMonthIds={selectedMonthIds}
            isMulti={isMulti}
            voucherDetails={voucherDetails}
            validateVoucher={validateVoucher}
          />
        );
      case 5:
        //payhere
        // return (
        //   <PayherePage
        //     selectedMonthId={selectedMonthId}
        //     selectedPaymentType={selectedPaymentType}
        //     group={groupClassess[selectedClassIndex]}
        //     setStep={setStep}
        //     selectedMonthIds={selectedMonthIds}
        //     isMulti={isMulti}
        //     voucherDetails={voucherDetails}
        //     validateVoucher={validateVoucher}
        //     setOpenModal={setOpenModal}
        //   />
        // );
        // // stripe
        return (
          <Elements stripe={stripePromise}>
            <PaymentStripePage
              paymentType={paymentType}
              selectedMonthId={selectedMonthId}
              group={groupClassess[selectedClassIndex]}
              setStep={setStep}
              selectedMonthIds={selectedMonthIds}
              isMulti={isMulti}
              voucherDetails={voucherDetails}
              validateVoucher={validateVoucher}
            />
          </Elements>
        );
      case 6:
        //card
        return <></>;
      case 7:
        //success
        return <PaymentSuccessPage />;
      case 8:
        //error
        return <PaymentErrorPage />;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setStep(1);
    setSelectedMonthIds([]);
    setVoucher("");
    setVoucherDetails(undefined);
    setIsVoucherValidate(0);
  };

  const handleCloseModalRefresh = () => {
    handleCloseModal();
    history.push("/");
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() =>
          step < 7 ? setOpenAlert(true) : handleCloseModalRefresh()
        }
        size={isLargerThanMd ? "2xl" : "xs"}
        isCentered
      >
        <ModalOverlay
          bg="none"
          backdropFilter="auto"
          backdropInvert="10%"
          backdropBlur="2px"
        />
        <ModalContent fontFamily={"Lexend"}>
          <ModalHeader bg="blue.100" color="blue.700" letterSpacing={"wider"}>
            Make your payments here
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{getStepContent()}</Box>
            <Box
              display="flex"
              flexDirection="row"
              // justifyContent="space-around"
            >
              {step > 1 && step < 7 && (
                <IconButton
                  icon={<ArrowBackIcon />}
                  aria-label={""}
                  size="lg"
                  colorScheme="yellow"
                  // disabled={step === 1}
                  onClick={() =>
                    setStep(step === 4 || step === 5 ? 2 : step - 1)
                  }
                />
              )}
              {/* <IconButton
                icon={<ArrowForwardIcon />}
                aria-label={""}
                size="lg"
                colorScheme="green"
                disabled={step === 2}
                onClick={() => setStep(step + 1)}
              /> */}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              bg="red.100"
              color="red.700"
              borderRadius={"md"}
              mr={3}
              onClick={() =>
                step < 7 ? setOpenAlert(true) : handleCloseModalRefresh()
              }
              leftIcon={<CloseIcon />}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertBox
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        handleConfirm={handleCloseModal}
      />
    </>
  );
};

export default PaymentModal;
