import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import axios from "axios";
import { UserGroupIcon } from "components/Icons/Icons";
import { api } from "config";
import React from "react";
import { FiPaperclip } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Group } from "types/apiTypes";
import ClassItem from "./ClassItem";

interface Props {}

const MyClasses: React.FC<Props> = () => {
  const [groupClassess, setGroupClassess] = React.useState<Group[]>([]);

  //get all group classes
  const getGroupClassess = async () => {
    try {
      const response = await axios.get(`${api}/groups/my-classes`, {
        withCredentials: true,
      });
      console.log(response.data);
      setGroupClassess(response.data);
    } catch (error: any) {}
  };

  React.useEffect(() => {
    getGroupClassess();
  }, []);

  return (
    <Box py="4" bg="white" p="4" my="3" shadow="sm" boxSizing="border-box">
      <Text
        fontWeight={"semibold"}
        color="orange.700"
        textTransform={"uppercase"}
        letterSpacing="wider"
        py="2"
        pl="2"
        mb="2"
        bg="orange.100"
        alignItems={"center"}
      >
        <Flex alignItems="center">
          <UserGroupIcon w="6" h="6" />
          <Text ml="3">My Classes</Text>
        </Flex>
      </Text>
      <Grid
        templateColumns={groupClassess.length === 1 ? "1fr" : "1fr 1fr"}
        gridGap="2"
      >
        {groupClassess.map((gp) => (
          <ClassItem group={gp} />
        ))}
      </Grid>
      <Flex justifyContent={"center"} w="full" mt="8">
        <Link to="/admin/my-previous-papers">
          <Button
            w="100%"
            m="0 auto"
            bg={"blue.100"}
            _hover={{ bg: "blue.100" }}
            color="blue.800"
            shadow="base"
            leftIcon={<FiPaperclip />}
          >
            My Previous Papers
          </Button>
        </Link>
      </Flex>
    </Box>
  );
};

export default MyClasses;
