import axios from "axios";
import Loading from "components/Misc/Loading";
import { apiLink } from "config";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import QuizzesForm from "../components/Quiz/QuizzesForm";

interface Props {}

const QuizzesFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/quizzes-form/:id");
  const [loading, setLoading] = useState(false);
  const [paper, setPaper] = useState();
  const isEdit = match !== null;
  let id: any = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  useEffect(() => {
    const fetchPaper = async () => {
      setLoading(true);
      const res = await axios.get(`${apiLink}/papers/${id}`, {
        withCredentials: true,
      });
      setPaper(res.data);
      setLoading(false);
    };

    if (id) {
      fetchPaper();
    }
  }, [id]);

  if (loading) return <Loading />;

  if (id) {
    return (
      <div>
        <GoBackButton />
        <QuizzesForm paper={paper} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <QuizzesForm />
      </div>
    );
  }
};

export default QuizzesFormPage;
