import RefreshPermission from "components/Permission/RefreshPermission";
import React from "react";

interface Props {}

const ManualPaymentBlockingsPage: React.FC<Props> = () => {
  return <RefreshPermission />;
};

export default ManualPaymentBlockingsPage;
