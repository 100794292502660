import { Box, Divider, Stack } from "@chakra-ui/react";
import axios from "axios";
import AnnouncementModal from "components/Announcement/AnnouncementModal";
import { api } from "config";
import React, { useEffect, useState } from "react";
import { Announcement } from "types/apiTypes";
import { getRole } from "utils/functions";
import useStore from "utils/store";
import AnnouncementItem from "./AnnouncementItem";

interface Props {}

const AnnouncementsBox: React.FC<Props> = () => {
  const role = getRole(); // localStorage.getItem("role");
  const [anns, setAnns] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState<
    Announcement | undefined
  >();
  const [openAnnouncement, setOpenAnnouncement] = React.useState(false);
  const setAnnouncementsCount = useStore(
    (state) => state.setAnnouncementsCount
  );

  const getMyAnnouncements = async () => {
    const limit = 10;
    const start = 0;
    const result = await axios.get(
      `${api}/announcements/find-my?_limit=${limit}&_start=${start}&_sort=dated:DESC`,
      { withCredentials: true }
    );
    const data = result.data.data.filter(
      (announcement: any) => announcement.read === false
    );
    setAnnouncementsCount(data.length);
    setAnns(result.data.data);
  };

  const handleClick = async (announcement: Announcement) => {
    setSelectedAnnouncement(announcement);
    setOpenAnnouncement(true);
    await axios.put(
      `${api}/announcements/${announcement.id}`,
      { read: true },
      { withCredentials: true }
    );
    getMyAnnouncements();
  };

  useEffect(() => {
    let timeout: any;
    if (role === "Authenticated") {
      getMyAnnouncements();
      timeout = setInterval(() => {
        getMyAnnouncements();
        // 2 hours
      }, 1000 * 60 * 60 * 2);
    }

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, []);

  return (
    <>
      <Box
        borderLeft="1px"
        minH={"80vh"}
        borderColor={"gray.200"}
        px="3"
        display={{ sm: "none", md: "block" }}
      >
        <Box
          pl="2"
          fontWeight={"bold"}
          fontSize="lg"
          color="gray.700"
          letterSpacing={"wide"}
          pb="3"
        >
          Announcements
          {anns.filter((announcement: any) => announcement.read === false)
            .length > 0 && (
            <Box
              bgColor="red.500"
              color="white"
              rounded="full"
              fontSize="sm"
              px="2"
              py="0.5"
              ml="2"
              display="inline-block"
            >
              {
                anns.filter((announcement: any) => announcement.read === false)
                  .length
              }
            </Box>
          )}
        </Box>
        <Stack>
          {anns.map((an: any) => (
            <>
              <AnnouncementItem
                onClick={() => handleClick(an)}
                title={an.title}
                message={an.message}
                date={an.dated}
                key={an.id}
              />
              <Divider orientation="horizontal" />
            </>
          ))}
        </Stack>
      </Box>

      <AnnouncementModal
        openModal={openAnnouncement}
        setOpenModal={setOpenAnnouncement}
        announcement={selectedAnnouncement}
      />
    </>
  );
};

export default AnnouncementsBox;
