import { IconButton } from "@chakra-ui/react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  [x: string]: any;
}

const GoBackIcon: React.FC<Props> = ({ ...props }) => {
  const history = useHistory();

  return (
    <>
      <IconButton
        aria-label="Go Back Button"
        icon={<ArrowBackIcon fontSize="small" />}
        bg="transparent"
        {...props}
        onClick={() => history.goBack()}
      />
    </>
  );
};

export default GoBackIcon;
