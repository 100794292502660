import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Heading, Stack } from "@chakra-ui/react";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import { LOGOUT_MUTATION } from "components/Navbars/AdminNavbarLinks";
import { Field, Form, Formik } from "formik";
import Cookies from "js-cookie";
import React from "react";
import { useHistory } from "react-router-dom";
import { Student } from "types/apiTypes";
import { useUser } from "utils/store";
import * as yup from "yup";
import { api, cloudApi } from "../../config";
import ChakraInput from "../Misc/FormComponents/ChakraInput";
import FormError from "../Misc/FormComponents/FormError";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";

const schema = yup.object().shape({
  email: yup.string().required("Email/Code is required"),
  password: yup.string().required("Password is required"),
});

interface Props {}
const LoginForm: React.FC<Props> = () => {
  const history = useHistory();
  const client = useApolloClient();
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [, setUser] = useUser<Student | undefined>();
  const [logout] = useMutation(LOGOUT_MUTATION);

  const handleLogout = async () => {
    try {
      await logout();
      sessionStorage.clear();
      localStorage.clear();
      Cookies.remove("role", { path: "/", domain: ".app.italyclasses.com" });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleSubmit = async (values: any) => {
    setSubmitLoading(true);
    try {
      await handleLogout();
      const res = await axios({
        url: `${process.env.NODE_ENV === "development" ? api : cloudApi}`,
        method: "POST",
        withCredentials: true,
        data: {
          query: `
            mutation LOGIN {
              login(input: {identifier: "${values.email}", password: "${values.password}"}) {
                user {
                  id
                  username
                  name
                  image
                  email
                  role {
                    name
                  }
                }
              }
            }
          `,
        },
      });

      const data = res.data.data.login;

      const role = data.user.role.name;
      const username = data.user.username;

      localStorage.setItem("role", role);
      localStorage.setItem("username", username);
      setUser(data.user);

      setSubmitLoading(false);

      await client.cache.reset();

      history.push("/");
    } catch (err: any) {
      setSubmitLoading(false);
      console.log((err as any).message);
      setSubmitLoading(false);
      setErrMsg("Email or Password is Invalid");
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
    }
  };

  return (
    <Box>
      <CssBaseline />
      {/* <img src={logo} alt="company logo" className={classes.logo} /> */}
      <Stack spacing={4} w={"full"} maxW={"md"}>
        {/* <Typography variant="button" className={classes.heading}>
            P.G.Martin Admin Panel
          </Typography>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
        <Heading fontSize={"3xl"} textAlign="center" fontFamily="heading">
          Sign in to your account
        </Heading>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={schema}
        >
          {({ isValid }) => (
            <Form>
              <Field
                name="email"
                label="Codice ID"
                component={ChakraInput}
                containerProps={{ mb: "5" }}
                // autoFocus
              />
              <Field
                name="password"
                label="Password"
                type="password"
                component={ChakraInput}
              />
              <FormError message={errMsg} style={{ textAlign: "center" }} />
              <SubmitBtn
                loading={submitLoading}
                btnText="SIGN IN"
                mt="3"
                w="100%"
                disabled={!isValid || submitLoading}
                colorScheme="blue"
                bg="blue.400"
                letterSpacing="wide"
              />
            </Form>
          )}
        </Formik>
      </Stack>
      {/* <ForgotPasswordDialog setOpen={setOpen} open={open} /> */}
    </Box>
  );
};
export default LoginForm;
