import { Box } from "@chakra-ui/react";
import axios from "axios";
import ViewClassContent from "components/ClassView/ViewClassContent";
import Loading from "components/Misc/Loading";
import { api } from "config";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Group, Month, Quiz, Video } from "types/apiTypes";

const ClassViewContentPage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [groupMonths, setGroupMonths] = React.useState<Month[]>([]);
  const [group, setGroup] = React.useState<Group>();
  const match = useRouteMatch("/admin/class-view-content/:id");
  const isEdit = match !== null;
  let id = "";

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const fetchGroup = React.useCallback(async () => {
    const result = await axios.get(`${api}/groups/my-classes?id=${id}`, {
      withCredentials: true,
    });
    setGroup(result.data[0]);
  }, [id]);

  const fetchGroupMonths = React.useCallback(async () => {
    const { data } = await axios.get(
      `${api}/months/my-months?group=${id}&_sort=year:asc,month:asc`,
      {
        withCredentials: true,
      }
    );
    const newData = data.map((m: Month) => {
      return {
        ...m,
        videos: (m.videos as Video[]).sort((a, b) => a.quizNo - b.quizNo),
        quizzes: (m.quizzes as Quiz[]).sort((a, b) => a.quizNo - b.quizNo),
      };
    });
    setGroupMonths(newData);
  }, [id]);

  React.useEffect(() => {
    if (id) {
      Promise.all([fetchGroupMonths(), fetchGroup()]).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [fetchGroupMonths, fetchGroup, id]);

  if (loading) return <Loading />;

  if (groupMonths.length > 0 && id) {
    return (
      <div>
        {/* <GoBackButton /> */}
        <ViewClassContent groupMonths={groupMonths} group={group} />
      </div>
    );
  }
  return (
    <div>
      {/* <GoBackButton /> */}
      <Box>
        <img src="../../undraw_clean_up_ucm0.svg" alt="no" />
      </Box>
    </div>
  );
};

export default ClassViewContentPage;
