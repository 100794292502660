import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { getRole } from "utils/functions";
import LoginForm from "../components/LoginPage";

interface Props {}

const Login: React.FC<Props> = () => {
  const history = useHistory();
  const role = getRole(); // localStorage.getItem("role");

  if (
    role !== null &&
    (role === "Client" || role === "Authenticated" || role === "Cashier")
  ) {
    history.push("/");
    return <></>;
  }

  return (
    <Stack minH={"100vh"} direction={{ base: "column-reverse", md: "row" }}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <LoginForm />
      </Flex>
    </Stack>
  );
};

export default Login;
