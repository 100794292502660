import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AppleGooglePayButton } from "components/Misc/AppleGooglePayButton";
import Loading from "components/Misc/Loading";
import PaymentModal from "components/PaymentStudent/PaymentModal";
import { api } from "config";
import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useRouteMatch } from "react-router-dom";
import { PAYMENT_TYPE } from "types/core";
import { PayIcon } from "../components/Icons/Icons";

interface Props {}

const ViewMyLinkPage: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(true);
  const [classLink, setClassLink] = React.useState<any>();
  const match = useRouteMatch("/class-links/:id");
  const isEdit = match !== null;
  const toast = useToast();
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);
  let id = "";
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(
    PAYMENT_TYPE.CARD,
  );

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }
  const fetchClassLink = React.useCallback(async () => {
    try {
      const { data } = await axios.get(`${api}/group-links/my-link/${id}`, {
        withCredentials: true,
      });
      setClassLink(data);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      toast({
        status: "error",
        title: (err as any).message,
        position: "bottom-right",
      });
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetchClassLink();
    } else {
      setLoading(false);
    }
  }, [fetchClassLink, id]);

  if (loading) return <Loading />;

  return (
    <Flex justifyContent={"center"} alignItems="center" h="50vh" mt="6">
      <Box
        bg="white"
        shadow="base"
        p={5}
        textAlign="center"
        borderRadius={"lg"}
      >
        <Heading
          fontSize={"2xl"}
          letterSpacing="wider"
          py="4"
          color="gray.700"
          bg="yellow.200"
          px="2"
        >
          {classLink.data.topic}
        </Heading>
        {classLink.allowed ? (
          <>
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="left"
              minHeight="200px"
              minW={{ base: "90vw", md: "40vw" }}
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} fontSize="lg" mb="4">
                Your Zoom Credentials
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                <Flex direction={"column"} alignItems="center" gridGap="3">
                  <div>
                    <chakra.a
                      href={classLink.data.zoomLink}
                      my="3"
                      display="block"
                      target="_blank"
                      bg="orange"
                      borderRadius={"5px"}
                      shadow="base"
                      px="3"
                      color="gray.700"
                      py="7"
                      mb="8"
                    >
                      Join to Class / පංතියට පිවිසෙන්න.
                    </chakra.a>
                  </div>
                  {classLink.data.meetingId && classLink.data.passCode && (
                    <>
                      <div>
                        Meeting ID:{" "}
                        <chakra.span ml="4">
                          {classLink.data.meetingId}
                        </chakra.span>
                      </div>
                      <div>
                        Pass Code:{" "}
                        <chakra.span ml="4">
                          {classLink.data.passCode}
                        </chakra.span>
                      </div>
                    </>
                  )}
                </Flex>
              </AlertDescription>
            </Alert>
          </>
        ) : (
          <>
            <Alert status="error" textAlign={"center"}>
              <AlertIcon />
              <Box textAlign={"center"}>{classLink.msg}</Box>
            </Alert>
            <Button
              leftIcon={<BsCloudUpload size={18} />}
              bg="purple.700"
              color="white"
              border="1px"
              _hover={{
                bg: "purple.800",
              }}
              // borderColor="yellow.700"
              fontSize={{ sm: "xs", md: "sm" }}
              mt={{ base: "3", md: 2 }}
              fontWeight={"normal"}
              // w={{ base: "full", md: "auto" }}
              // w="full"
              onClick={() => {
                setPaymentModalOpen(true);
                setPaymentType(PAYMENT_TYPE.RECEIPT);
              }}
            >
              Receipt පත upload කරන්න.
            </Button>
            <Button
              leftIcon={<PayIcon w="6" h="6" />}
              bg="red.600"
              color="white"
              border="1px"
              fontSize={{ sm: "xs", md: "sm" }}
              mt={{ base: "3", md: 2 }}
              fontWeight={"normal"}
              // w={{ base: "full", md: "auto" }}
              _hover={{
                bg: "purple.800",
              }}
              onClick={() => {
                setPaymentModalOpen(true);
                setPaymentType(PAYMENT_TYPE.CARD);
              }}
            >
              Card Payment
            </Button>

            <AppleGooglePayButton
              onClick={() => {
                setPaymentModalOpen(true);
                setPaymentType(PAYMENT_TYPE.APPLE_GOOGLE_PAY);
              }}
            />
          </>
        )}
      </Box>
      <PaymentModal
        openModal={paymentModalOpen}
        setOpenModal={setPaymentModalOpen}
        paymentType={paymentType}
      />
    </Flex>
  );
};

export default ViewMyLinkPage;
