import { DeleteIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Grid } from "@chakra-ui/react";
import { MessageIcon, PayIcon } from "components/Icons/Icons";
import { AppleGooglePayButton } from "components/Misc/AppleGooglePayButton";
import PaymentModal from "components/PaymentStudent/PaymentModal";
import { ApplePayIcon } from "icons/ApplePayIcon";
import { GooglePayIcon } from "icons/GooglePayIcon";
//@ts-ignore
import React, { useState } from "react";
import { BsCloudUpload, BsEyeFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { PAYMENT_TYPE } from "types/core";
import { getRole } from "utils/functions";
import AddReviewModal from "./AddReviewModal";
import AllClasses from "./AllClasses";
import AnnouncementsBox from "./AnnouncementsBox";
import DateBox from "./DateBox";
import Greeting from "./Greeting";
import LatestQuizzesVideos from "./LatestQuizzesVideos";
import MyClasses from "./MyClasses";
import PendingPayments from "./PendingPayments";

const Dashboard = () => {
  const role = getRole(); // localStorage.getItem("role");
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(
    PAYMENT_TYPE.CARD,
  );
  const history = useHistory();

  return (
    <>
      {role === "Authenticated" && (
        <>
          <Grid templateColumns={{ sm: "1fr", md: "3fr 1fr" }} gridGap="3">
            <Box>
              <Flex
                justifyContent={"space-between"}
                flexDir={{ sm: "column-reverse", md: "row" }}
                alignItems="center"
                mx="2"
                mb="5"
              >
                <Greeting />
                <DateBox />
              </Flex>

              <Divider />
              <Flex
                justifyContent={"center"}
                flexDir={"column"}
                alignItems="center"
                my={{ sm: "2", md: "2" }}
                // maxW="100vw"
                w="full"
              >
                <Button
                  leftIcon={<MessageIcon w="6" h="6" />}
                  bg="yellow.100"
                  color="yellow.700"
                  border="1px"
                  borderColor="yellow.700"
                  fontSize={{ sm: "sm", md: "lg" }}
                  mt={{ base: "3", md: 2 }}
                  fontWeight={"normal"}
                  w="full"
                  onClick={() => setReviewModalOpen(true)}
                  whiteSpace="pre"
                >
                  අපගේ පංතිය ගැන ඔබගේ අදහස ලියන්න
                </Button>
                <Grid
                  gridGap={{ sm: "1", md: "3" }}
                  direction={{ sm: "column", md: "row" }}
                  w="full"
                  gridTemplateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
                >
                  <Button
                    leftIcon={<BsCloudUpload size={18} />}
                    bg="purple.700"
                    color="white"
                    border="1px"
                    _hover={{
                      bg: "purple.800",
                    }}
                    // borderColor="yellow.700"
                    fontSize={{ sm: "xs", md: "sm" }}
                    mt={{ base: "3", md: 2 }}
                    fontWeight={"normal"}
                    // w={{ base: "full", md: "auto" }}
                    // w="full"
                    onClick={() => {
                      setPaymentModalOpen(true);
                      setPaymentType(PAYMENT_TYPE.RECEIPT);
                    }}
                  >
                    Receipt පත upload කරන්න.
                  </Button>
                  <Button
                    leftIcon={<PayIcon w="6" h="6" />}
                    bg="red.600"
                    color="white"
                    border="1px"
                    fontSize={{ sm: "xs", md: "sm" }}
                    mt={{ base: "3", md: 2 }}
                    fontWeight={"normal"}
                    // w={{ base: "full", md: "auto" }}
                    _hover={{
                      bg: "purple.800",
                    }}
                    onClick={() => {
                      setPaymentModalOpen(true);
                      setPaymentType(PAYMENT_TYPE.CARD);
                    }}
                  >
                    Card Payment
                  </Button>
                  <AppleGooglePayButton
                    onClick={() => {
                      setPaymentModalOpen(true);
                      setPaymentType(PAYMENT_TYPE.APPLE_GOOGLE_PAY);
                    }}
                  />
                </Grid>
              </Flex>
              <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr" }} gridGap="3">
                <MyClasses />
                <LatestQuizzesVideos />
              </Grid>
              <Grid
                templateColumns={{ sm: "1fr", md: "1fr" }}
                gridGap="3"
                maxW={"100vw"}
              >
                <PendingPayments />
              </Grid>
            </Box>
            <AnnouncementsBox />
          </Grid>

          <PaymentModal
            openModal={paymentModalOpen}
            setOpenModal={setPaymentModalOpen}
            paymentType={paymentType}
          />
          <AddReviewModal
            isOpen={reviewModalOpen}
            setIsOpen={setReviewModalOpen}
          />
        </>
      )}

      {role === "Client" && (
        <Box>
          <h3>Dashboard</h3>
          <AllClasses />
          <Flex gridGap={4}>
            <Button
              leftIcon={<PlusSquareIcon />}
              shadow="base"
              onClick={() => history.push("/admin/add-class-link")}
            >
              Add Class Link
            </Button>
            <Button
              leftIcon={<BsEyeFill />}
              shadow="base"
              onClick={() => history.push("/admin/view-class-links")}
            >
              View Class Links
            </Button>
            <Button
              leftIcon={<DeleteIcon />}
              shadow="base"
              onClick={() => history.push("/admin/delete-student-papers")}
            >
              Delete Student Papers
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
