import { Box, Text } from "@chakra-ui/react";
import axios from "axios";
import Loading from "components/Misc/Loading";
import { api } from "config";
import * as React from "react";
import { Coupon, Group } from "types/apiTypes";
import { PAYMENT_TYPE } from "types/core";
import StripeCheckoutForm from "./StripeCheckoutForm";

interface Props {
  group: Group;
  selectedMonthId: number;
  setStep: (step: number) => void;
  selectedMonthIds: number[];
  isMulti?: boolean;
  voucherDetails?: Coupon;
  validateVoucher: () => void;
  paymentType: PAYMENT_TYPE;
}

const PaymentStripePage: React.FC<Props> = ({
  group,
  selectedMonthId,
  setStep,
  selectedMonthIds,
  isMulti,
  voucherDetails,
  paymentType,
}) => {
  const [loadingCS, setLoadingCS] = React.useState(true);

  const [clientSecret, setClientSecret] = React.useState<string>("");

  const getClientSecret = async () => {
    setLoadingCS(true);
    if (!isMulti) selectedMonthIds = [selectedMonthId];
    const response = await axios.get(
      `${api}/payments/get-secret?monthids=${selectedMonthIds.join(
        ",",
      )}&coupon=${voucherDetails?.code}`,
      { withCredentials: true },
    );
    setClientSecret(response.data.client_secret);
    setLoadingCS(false);
  };

  React.useEffect(() => {
    getClientSecret();
  }, []);

  if (loadingCS) {
    return <Loading />;
  }

  const amount = voucherDetails
    ? (group.fee *
        (isMulti ? selectedMonthIds.length : 1) *
        (100 - voucherDetails.percentage)) /
      100
    : group.fee * (isMulti ? selectedMonthIds.length : 1);

  return (
    <Box my="8" fontFamily={"Lexend"}>
      <Box textAlign={"center"} fontSize="lg">
        <Text
          fontWeight={"bold"}
          color="gray.500"
          fontSize={"2xl"}
          lineHeight="1"
        >
          {amount}
          Euros
        </Text>
      </Box>

      <Box mt="0" mb="6">
        {/* <Alert bg="red.400" borderRadius={"md"} color="white">
          Payment කිරීමේ කාර්මික දෝශයක් පවතින අතර එය සාදා දැනුම් දෙන තුරු
          Payment කිරීමෙන් වලකින්න. ස්තූතියි!
        </Alert> */}
        <StripeCheckoutForm
          paymentType={paymentType}
          clientSecret={clientSecret}
          setStep={setStep}
          amount={amount}
        />
      </Box>
    </Box>
  );
};

export default PaymentStripePage;
