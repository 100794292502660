import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";

const columns = [
  {
    id: "couponName",
    name: "Coupon Name",
  },
];

const ViewCoupons: React.FC = () => {
  const useCounterState = createPersistedState("sortQueryCoupon");
  const [sortQueryCoupon, setSortQueryCoupon]: any = useCounterState("id:asc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();

  const fetchCouponsAndCount = async (
    start: number,
    limit: number,
    sort: any,
    couponName: string
  ) => {
    try {
      const { data: coupons } = await axios.get(
        `${api}/coupons?_limit=${limit}&_start=${start}&_sort=${sort}`,
        { withCredentials: true }
      );
      const { data: totalCount } = await axios.get(`${api}/coupons/count`, {
        withCredentials: true,
      });
      return { coupons, totalCount };
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { coupons: [], totalCount: 0 };
    }
  };

  const handleEditClick = (id: string) => {
    history.push(`/admin/coupon-form/${id}`);
  };

  const handleAddClick = () => {
    history.push("/admin/coupon-form");
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Coupon
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Coupons"
          columns={[
            { title: "ID", field: "id", sorting: false },
            { title: "Code", field: "code", sorting: false },
            {
              title: "Expiry Date",
              field: "enddate",
              sorting: false,
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                {/* <MaterialTableCustomSort
                  sort={sortQueryCoupon}
                  setSort={setSortQueryCoupon}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Coupon",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchCouponsAndCount(
                query.page * query.pageSize,
                query.pageSize,
                sortQueryCoupon,
                query.search
              )
                .then(({ coupons, totalCount }) => {
                  const newData = coupons.map((item: any) => ({
                    id: item.id,
                    code: item.code,
                    enddate: item.enddate,
                  }));

                  return resolve({
                    data: newData,
                    page: query.page,
                    totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewCoupons;
