import { Button } from "@chakra-ui/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    buttonProgress: {
      color: grey[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface Props {
  loading: boolean;
  btnText?: string;
  [x: string]: any;
}

const SubmitBtn: React.FC<Props> = ({
  loading = false,
  btnText = "Submit",
  ...props
}) => {
  const classes = useStyles({});
  return (
    <Button
      colorScheme="teal"
      type="submit"
      disabled={loading}
      fontFamily="body"
      {...props}
    >
      {btnText}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};

export default SubmitBtn;
