import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import ChakraTextArea from "components/Misc/FormComponents/ChakraTextArea";
import FileUpload from "components/Misc/FormComponents/FileUpload";
import { api } from "config";
import { Field, Form, Formik } from "formik";
import MaterialTable from "material-table";
import { useState } from "react";
import { FaVideo } from "react-icons/fa";
import * as yup from "yup";
import { Month, Video } from "../../types/apiTypes";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";

interface Props {
  groupMonths: Month[];
  selectedMonth: number;
  videos: any;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}
const schema = yup.object({
  title: yup.string().required("Required"),
});

const GroupClassVideoBox: React.FC<Props> = ({
  groupMonths,
  selectedMonth,
  videos,
  setRefresh,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<Video>();

  const handleRowClick = (event: any, video: Video) => {
    setSelectedVideo(video);
    setOpenModal(true);
  };
  const handleSubmit = async (values: any) => {
    try {
      const data = {
        title: values.title,
        quizNo: values.quizNo,
        content: values.content,
        group: groupMonths[0].group.id,
        month: groupMonths.find((m) => m.month === selectedMonth)?.id,
        link: values.link,
        image: values.image,
      };
      if (selectedVideo) {
        const response = await axios.put(
          `${api}/videos/${selectedVideo.id}`,
          data,
          { withCredentials: true }
        );
      } else {
        const response = await axios.post(`${api}/videos`, data, {
          withCredentials: true,
        });
      }
    } catch (error: any) {}
    //random integer to force refresh
    setRefresh(Math.floor(Math.random() * 100));
    setOpenModal(false);
  };

  const addVideo = async () => {
    setSelectedVideo(undefined);
    setOpenModal(true);
  };

  const deleteVideo = async (id: string) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    try {
      await axios.delete(`${api}/videos/${id}`, {
        withCredentials: true,
      });
    } catch (error: any) {}
    //random integer to force refresh
    setRefresh(Math.floor(Math.random() * 100));
  };

  return (
    <div>
      <Button
        my="3"
        bg="blue.300"
        fontWeight="normal"
        leftIcon={<FaVideo />}
        color="gray.100"
        size="sm"
        onClick={addVideo}
        _hover={{
          bg: "blue.300",
        }}
      >
        Add Video
      </Button>
      <Box mb="6">
        <MaterialTable
          title="Videos"
          columns={[
            // { title: "ID", field: "id" },
            { title: "Title", field: "title" },
          ]}
          data={videos}
          options={{
            rowStyle: {
              backgroundColor: "#EEE",
            },
            actionsColumnIndex: -1,
            pageSizeOptions: [10, 20, 30, 100],
            pageSize: 10,
          }}
          onRowClick={(event: any, rowData: any) =>
            handleRowClick(event, rowData)
          }
          actions={[
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event: any, rowData: any) => {
                deleteVideo(rowData.id);
              },
            },
          ]}
        />
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedVideo ? "Edit" : "Add"} Video</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                onSubmit={(values) => handleSubmit(values)}
                initialValues={{
                  title: selectedVideo?.title || "",
                  quizNo: selectedVideo?.quizNo || "",
                  content: selectedVideo?.content || "",
                  link: selectedVideo?.link || "",
                  image: selectedVideo?.image || "",
                }}
                validationSchema={schema}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <Field
                      name="quizNo"
                      component={ChakraInput}
                      label="Quiz No"
                      fullWidth
                    />
                    <Field
                      name="title"
                      component={ChakraInput}
                      label="Title"
                      fullWidth
                    />

                    <Field
                      name="content"
                      component={ChakraTextArea}
                      label="Content"
                      fullWidth
                      multiline
                    />
                    <Field
                      name="link"
                      component={ChakraTextArea}
                      label="Video Embed Link"
                      multiline
                      fullWidth
                    />
                    <Box mt="5">
                      {values.image && (
                        <img
                          src={`${api}${values.image}`}
                          alt="a"
                          style={{ height: "100px", marginLeft: "10px" }}
                        />
                      )}
                      <FileUpload
                        handleSetFieldValue={setFieldValue}
                        handleFieldName={`image`}
                        text={`Upload thumbnail`}
                      />
                    </Box>
                    <SubmitBtn
                      loading={loading}
                      w="100%"
                      my="3"
                      btnText={selectedVideo ? "Update" : "Add"}
                    />
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};

export default GroupClassVideoBox;
