import { Box, Button, Grid, Select, useToast } from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import GoBackButton from "components/Misc/GoBackButton";
import { api } from "config";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {}

const RefreshPermission: React.FC<Props> = () => {
  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = React.useState<number>(
    new Date().getMonth()
  );
  const [selectedYear, setSelectedYear] = React.useState<number>(
    new Date().getFullYear()
  );
  const year = new Date().getFullYear() - 3;
  const years = Array.from(new Array(10), (val, index) => index + year);

  const handleRefreshPermissionClick = async () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    setLoading(true);
    try {
      await axios.post(
        `${api}/blocks/refresh`,
        { month: selectedMonth, year: selectedYear },
        { withCredentials: true }
      );
      toast({
        title: "Blockings Updated",
        position: "bottom-right",
        status: "success",
      });
      history.push("/admin/view-permission");
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Box shadow="base" bg="white" m="5" p="5">
      <GoBackButton />
      <Grid templateColumns={"1fr"} gridGap="3">
        <Select
          placeholder="Select month"
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          value={selectedMonth}
        >
          {months.map((month: string, index: number) => {
            return (
              <option key={index} value={index + 1}>
                {month}
              </option>
            );
          })}
        </Select>
        <Select
          placeholder="Select year"
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          value={selectedYear}
        >
          {years.map((year: number) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <Button
          colorScheme="red"
          onClick={handleRefreshPermissionClick}
          className="add-btn"
          m="3"
          size="sm"
          textTransform="uppercase"
          isLoading={loading}
          disabled={loading}
          loadingText={"Blockings Updating"}
        >
          Add Blockings
        </Button>
      </Grid>
    </Box>
  );
};

export default RefreshPermission;
