import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import { PayIcon } from "components/Icons/Icons";
import { api } from "config";
import React from "react";
import { Month } from "types/apiTypes";

interface Props {}

const PendingPayments: React.FC<Props> = () => {
  const [Months, setMonths] = React.useState<Month[]>([]);

  //get all  Month
  const getMonths = async () => {
    try {
      const response = await axios.get(
        `${api}/payments/find-my-pending-months`,
        {
          withCredentials: true,
        }
      );

      const sortedMonths = response.data.sort((a: Month, b: Month) => {
        return a.month - b.month;
      });
      // console.log(sortedMonths);
      setMonths(sortedMonths);
    } catch (error: any) {}
  };

  React.useEffect(() => {
    getMonths();
  }, []);

  return (
    <Box py="4" bg="white" p="4" my="3" shadow="sm">
      <Text
        fontWeight={"semibold"}
        color="red.700"
        textTransform={"uppercase"}
        letterSpacing="wider"
        py="2"
        pl="2"
        mb="2"
        bg="red.100"
        alignItems={"center"}
      >
        <Flex gridGap={"2"} alignItems="center">
          <PayIcon w="6" h="6" />
          Pending Payments
        </Flex>
      </Text>
      <TableContainer bg="white" maxW={"80vw"} mx="0" px="0">
        <Table variant="simple" overflowX="scroll" px="0">
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th isNumeric>Fee</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"sm"}>
            {Months.map((month: Month) => (
              <Tr key={month.id}>
                <Td wordBreak={"break-all"}>
                  {months[month.month - 1] + " (" + month.group.groupName + ")"}
                </Td>
                <Td isNumeric>{month.group.fee}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PendingPayments;
