import { Box, Button, Grid } from "@chakra-ui/react";
import * as React from "react";
import { Group } from "types/apiTypes";

interface Props {
  groupClassess: Group[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedClass: React.Dispatch<React.SetStateAction<number>>;
}

const SelectClassPage: React.FC<Props> = ({
  groupClassess,
  setStep,
  setSelectedClass,
}) => {
  const handleClickClass = (id: number) => {
    setSelectedClass(id);
    setStep(2);
  };

  return (
    <Box my="8">
      <Box fontSize={"md"} color="gray.600">
        Select the class you want to pay for:
      </Box>
      <Box my="6">
        <Grid gridGap="3">
          {groupClassess.length > 0 ? (
            groupClassess.map((group, index) => {
              return (
                <Grid key={index}>
                  <Button
                    w="full"
                    borderRadius={"md"}
                    shadow="base"
                    onClick={() => handleClickClass(index)}
                  >
                    {group.groupName}
                  </Button>
                </Grid>
              );
            })
          ) : (
            <Box>No classess</Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default SelectClassPage;
