import GroupPapers from "components/Group/GroupPapers";
import PaperSummary from "components/Group/PaperSummary";
import {
  CreditIcon,
  DocumentIcon, HomeIcon,
  MessageIcon,
  PayIcon,
  ProfileIcon,
  StatsIcon,
  TicketIcon,
  UserGroupIcon,
  UserIcon
} from "components/Icons/Icons";
import AppAccessPage from "pages/app-access";
import AppAccessFormPage from "pages/app-access-form";
import ClassLinkFormPage from "pages/class-link-form";
import ClassLinksPage from "pages/class-links";
import ClassViewPage from "pages/class-view";
import ClassViewContentPage from "pages/class-view-content";
import DeleteStudentPapers from "pages/delete-student-papers";
import GroupClassFormPage from "pages/group-class-form";
import ManualPaymentBlockingsPage from "pages/manual-payment-blockings";
import MyPaymentHistoryPage from "pages/my-payment-history";
import MyPreviousPapersPage from "pages/my-previous-papers";
import PaperCategoriesPage from "pages/paper-categories";
import PapersPage from "pages/papers";
import PaymentsPage from "pages/payments";
import PermissionsPage from "pages/permission";
import PermissionsViewPage from "pages/permission-view";
import ProfileFormPage from "pages/profile";
import PromoPage from "pages/promo";
import QuizzesFormPage from "pages/quizzes-form";
import StudentPaymentFormPage from "pages/student-payment-form";
import ViewMyLinkPage from "pages/view-my-link";
import DashboardPage from "../components/Dashboard/index";
import AnnouncementFormPage from "../pages/announcement-form";
import AnnouncementsPage from "../pages/announcements";
import CouponFormPage from "../pages/coupon-form";
import CouponsPage from "../pages/coupons";
import GroupFormPage from "../pages/group-form";
import GroupsPage from "../pages/groups";
import StudentFormPage from "../pages/student-form";
import StudentsPage from "../pages/students";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon w="6" h="6" color="inherit" />,
    component: DashboardPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/groups",
    name: "Classes",
    rtlName: "",
    icon: <UserGroupIcon w="6" h="6" color="inherit" />,
    component: GroupsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/group-form",
    name: "Create Class",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: GroupFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/group-class-form",
    name: "Manage Class",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: GroupClassFormPage,
    layout: "/admin",
    show: false,
  },

  {
    path: "/payments",
    name: "Payments",
    rtlName: "",
    icon: <PayIcon w="6" h="6" color="inherit" />,
    component: PaymentsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/students",
    name: "Students",
    rtlName: "",
    icon: <UserIcon w="6" h="6" color="inherit" />,
    component: StudentsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/student-form",
    name: "Create Student",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: StudentFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/announcements",
    name: "Announcements",
    rtlName: "",
    icon: <MessageIcon w="6" h="6" color="inherit" />,
    component: AnnouncementsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/announcement-form",
    name: "Create Announcement",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: AnnouncementFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/coupons",
    name: "Coupons",
    rtlName: "",
    icon: <TicketIcon w="6" h="6" color="inherit" />,
    component: CouponsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/coupon-form",
    name: "Create Coupon",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: CouponFormPage,
    layout: "/admin",
    show: false,
  },
  // {
  //   path: "/app-coupons",
  //   name: "App Coupons",
  //   rtlName: "",
  //   icon: <CreditIcon w="6" h="6" color="inherit" />,
  //   component: AppCouponsPage,
  //   layout: "/admin",
  //   show: true,
  // },
  {
    path: "/app-access",
    name: "App Access",
    rtlName: "",
    icon: <CreditIcon w="6" h="6" color="inherit" />,
    component: AppAccessPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/app-access-form",
    name: "App Access Form",
    rtlName: "",
    icon: <CreditIcon color="inherit" />,
    component: AppAccessFormPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/add-permission",
    name: "Restrict Access",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: PermissionsPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/view-permission",
    name: "Restrict Access",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: PermissionsViewPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/add-monthly-blockings",
    name: "Add Monthly Payment Blockings",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: ManualPaymentBlockingsPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/add-student-payment",
    name: "Add Student Payment",
    rtlName: "",
    icon: <PayIcon w="6" h="6" color="inherit" />,
    component: StudentPaymentFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/quizzes-form",
    name: "Add Quizzes",
    rtlName: "",
    icon: <PayIcon w="6" h="6" color="inherit" />,
    component: QuizzesFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/paper-categories",
    name: "Papers",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: PaperCategoriesPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/papers/:type",
    name: "Papers",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: PapersPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/group-papers/:id",
    name: "Group Papers",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: GroupPapers,
    layout: "/admin",
    show: false,
  },
  {
    path: "/paper-summary/:id",
    name: "Paper Summary",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: PaperSummary,
    layout: "/admin",
    show: false,
  },
  {
    path: "/add-class-link",
    name: "Add Class Link",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: ClassLinkFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/view-class-links",
    name: "View Class Links",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: ClassLinksPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/delete-student-papers",
    name: "Delete Student Papers",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: DeleteStudentPapers,
    layout: "/admin",
    show: false,
  },
];

export const studentRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon w="6" h="6" color="inherit" />,
    component: DashboardPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/class-view",
    name: "My Classes",
    rtlName: "",
    icon: <UserGroupIcon w="6" h="6" color="inherit" />,
    component: ClassViewPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/class-view-content",
    name: "My Class",
    rtlName: "",
    icon: <UserGroupIcon w="6" h="6" color="inherit" />,
    component: ClassViewContentPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/profile",
    name: "My Profile",
    rtlName: "",
    icon: <ProfileIcon w="6" h="6" color="inherit" />,
    component: ProfileFormPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/my-payment-history",
    name: "Payments",
    rtlName: "",
    icon: <PayIcon w="6" h="6" color="inherit" />,
    component: MyPaymentHistoryPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/announcements",
    name: "Announcements",
    rtlName: "",
    icon: <MessageIcon w="6" h="6" color="inherit" />,
    component: AnnouncementsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/my-previous-papers",
    name: "My Previous Papers",
    rtlName: "",
    icon: <TicketIcon w="6" h="6" color="inherit" />,
    component: MyPreviousPapersPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/promo",
    name: "Promotions",
    rtlName: "",
    icon: <CreditIcon w="6" h="6" color="inherit" />,
    component: PromoPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/:id",
    name: "Class Links",
    rtlName: "",
    icon: <TicketIcon w="6" h="6" color="inherit" />,
    component: ViewMyLinkPage,
    layout: "/class-links",
    show: false,
  },
];

export default dashboardRoutes;
