import axios from "axios";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import StudentForm from "../components/Student/StudentForm";
import { api } from "../config";
import { Student } from "../types/apiTypes";

const StudentFormPage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [student, setStudent] = React.useState<Student>();
  const match = useRouteMatch("/admin/student-form/:id");
  const isEdit = match !== null;
  let id = "";

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }
  const fetchStudent = React.useCallback(async () => {
    const { data } = await axios.get(`${api}/users/${id}`, {
      withCredentials: true,
    });
    setStudent(data);
    setLoading(false);
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetchStudent();
    } else {
      setLoading(false);
    }
  }, [fetchStudent, id]);

  if (loading) return <Loading />;

  if (id) {
    return (
      <div>
        <GoBackButton />
        <StudentForm student={student} />
      </div>
    );
  }
  return (
    <div>
      <GoBackButton />
      <StudentForm />
    </div>
  );
};

export default StudentFormPage;
