import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  papers: any;
  handleRestart: any;
  handleReview: any;
}

const PapersTable: React.FC<Props> = ({
  papers,
  handleRestart,
  handleReview,
}) => {
  return (
    <TableContainer bg="white" shadow="base">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Topic</Th>
            <Th>Last 1</Th>
            <Th>Last 2</Th>
            <Th>Last 3</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {papers.map((p: any) => {
            const scores = p.scores.split(",");
            return (
              <Tr key={p.id}>
                <Td>{p.topic}</Td>
                <Td>
                  <MarkBox mark={scores[scores.length - 1]} />
                </Td>
                <Td>
                  <MarkBox mark={scores[scores.length - 2]} />
                </Td>
                <Td>
                  <MarkBox mark={scores[scores.length - 3]} />
                </Td>
                <Td>
                  <Button
                    colorScheme={"blue"}
                    onClick={() => handleReview(p.id)}
                  >
                    Review
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme={"yellow"}
                    onClick={() => handleRestart(p.id)}
                  >
                    Try Again
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PapersTable;

const MarkBox = ({ mark }: any) => {
  let status = "null";
  if (mark) {
    const markSplit = mark.split("/");
    const marks = parseInt(markSplit[0]);
    const noOfQuizzes = parseInt(markSplit[1]);
    status = (marks / noOfQuizzes) * 100 >= 90 ? "pass" : "fail";
  }

  return (
    <Flex
      bg={status === "pass" ? "green.500" : status === "fail" ? "red.500" : ""}
      p="2"
      color="gray.100"
      justifyContent={"center"}
      alignItems="center"
    >
      {mark}
    </Flex>
  );
};
