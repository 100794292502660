import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton, useToast } from "@chakra-ui/react";
import axios from "axios";
import SelectClassPage from "components/PaymentStudent/SelectClassPage";
import SelectMonthPage from "components/PaymentStudent/SelectMonthPage";
import { api } from "config";
import React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";
import { PAYMENT_TYPE } from "types/core";
import { getErrors } from "utils/functions";

interface Props {
  id: number;
}

const StudentPaymentForm: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const toast = useToast();
  const [classes, setClasses] = React.useState<Group[]>([]);
  const [step, setStep] = React.useState(1);
  const [selectedClassIndex, setSelectedClassIndex] = React.useState(0);
  const [selectedMonthIds, setSelectedMonthIds] = React.useState<number[]>([]);
  const [selectedMonthId, setSelectedMonthId] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);

  const getStudentClasses = async () => {
    try {
      const response = await axios.get(
        `${api}/groups/my-classes?userId=${id}`,
        {
          withCredentials: true,
        },
      );
      setClasses(response.data);
    } catch (error: any) {}
  };

  const handleSubmit = async () => {
    const data = {
      slip: "",
      month: selectedMonthId,
      months: selectedMonthIds,
      status: 2,
      paidAmount: classes[selectedClassIndex].fee,
      type: "BS",
      paidDate: new Date().toISOString(),
      users_permissions_user: id,
    };
    try {
      setLoading(true);

      await axios.post(`${api}/payments`, data, { withCredentials: true });
      setLoading(false);
      toast({
        title: `Payment Added Succefully`,
        position: "bottom-right",
        status: "success",
      });

      setSelectedMonthIds([]);
      history.push("/admin/students");
    } catch (err: any) {
      const errors = getErrors(err);
      errors.forEach((e) => {
        toast({
          title: e,
          position: "bottom-right",
          status: "error",
        });
      });
    }
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return (
          <SelectClassPage
            groupClassess={classes}
            setSelectedClass={setSelectedClassIndex}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <SelectMonthPage
            userId={id}
            group={classes[selectedClassIndex]}
            setSelectedMonths={setSelectedMonthIds}
            setSelectedMonth={setSelectedMonthId}
            selectedMonthIds={selectedMonthIds}
            setStep={setStep}
            isMulti
            paymentType={PAYMENT_TYPE.RECEIPT}
            hideVoucherBtn
          />
        );
      case 3:
        return;
      // return (
      //   <SelectPaymentPage
      //     isCard={false}
      //     setSelectedPayment={setSelectedPaymentType}
      //     setStep={setStep}
      //     setVoucher={setVoucher}
      //     isVoucherValidate={isVoucherValidate}
      //     validateVoucher={validateVoucher}
      //     voucher={voucher}
      //   />
      // );
      case 4:
        return (
          <Box my="3">
            <Button
              onClick={handleSubmit}
              isLoading={loading}
              disabled={loading}
              loadingText="Adding Payment"
            >
              Add Payment
            </Button>
          </Box>
        );
      case 5:
        // stripe
        return;
      // return (
      //   <>
      //     <Box my="3">
      //       <Button
      //         onClick={handleSubmit}
      //         isLoading={loading}
      //         disabled={loading}
      //         loadingText="Adding Payment"
      //       >
      //         Add Payment
      //       </Button>
      //     </Box>
      //   </>
      // );
      case 6:
        //card
        return <></>;
      default:
        break;
    }
  };

  React.useEffect(() => {
    getStudentClasses();
  }, []);

  return (
    <Box bg="white" mx="3" shadow="base" p="4">
      <Box>{getStepContent()}</Box>

      <Box display="flex" flexDirection="row">
        {step > 1 && (
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label={""}
            size="lg"
            colorScheme="green"
            // disabled={step === 1}
            onClick={() => setStep(step === 4 ? 2 : step - 1)}
          />
        )}
      </Box>
    </Box>
  );
};

export default StudentPaymentForm;
