import ViewClasses from "components/ClassView/ViewClasses";
import React from "react";
import GoBackButton from "../components/Misc/GoBackButton";

const ClassViewPage: React.FC = () => {
  return (
    <div>
      <GoBackButton />
      <ViewClasses />
    </div>
  );
};

export default ClassViewPage;
