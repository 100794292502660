import axios from "axios";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GroupForm from "../components/Group/GroupForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import { api } from "../config";
import { Group } from "../types/apiTypes";

const GroupFormPage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [group, setGroup] = React.useState<Group>();
  const match = useRouteMatch("/admin/group-form/:id");
  const isEdit = match !== null;
  let id = "";

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }
  const fetchGroup = React.useCallback(async () => {
    const { data } = await axios.get(`${api}/groups/${id}`, {
      withCredentials: true,
    });
    setGroup(data);
    setLoading(false);
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetchGroup();
    } else {
      setLoading(false);
    }
  }, [fetchGroup, id]);

  if (loading) return <Loading />;

  if (id) {
    return (
      <div>
        <GoBackButton />
        <GroupForm group={group} />
      </div>
    );
  }
  return (
    <div>
      <GoBackButton />
      <GroupForm />
    </div>
  );
};

export default GroupFormPage;
