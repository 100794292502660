import { Box, useRadio } from "@chakra-ui/react";

export default function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: props.isReviewMode ? "gray.100" : "orange.500",
          color: props.isReviewMode ? "gray.800" : "gray.50",
          borderColor: props.isReviewMode ? "gray.200" : "orange.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        fontSize="sm"
      >
        {props.children}
      </Box>
    </Box>
  );
}
