import { Button, chakra } from "@chakra-ui/react";
import { createStyles, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { apiLink } from "../../../config";
import Loading from "../Loading";

const container: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#f2f2f2",
  padding: 10,
  margin: "10px 0px",
  maxHeight: 50,
};

const thumbsContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: React.CSSProperties = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner: React.CSSProperties = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img: React.CSSProperties = {
  display: "block",
  width: "auto",
  height: "100%",
};

const useStyles = makeStyles(() =>
  createStyles({
    containerText: {
      letterSpacing: "6px !important",
      padding: 10,
    },
  }),
);

interface Props {
  handleImageUpload?: (images: any[] | any) => void;
  multiple?: boolean;
  text?: string;
  folder?: string;
  colorCatIndex?: number;
  values?: any;
  handleFieldName?: string;
  handleSetFieldValue?: (field: string, value: any) => void;
  acceptFileTypes?: string;
}

const FileUpload: React.FC<Props> = ({
  handleImageUpload,
  multiple = false,
  text = "Drag 'n' drop some files here, or click to select files",
  folder = "",
  colorCatIndex,
  values,
  handleFieldName,
  handleSetFieldValue,
  acceptFileTypes = "application/pdf,image/*",
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileTypes,
    multiple,
    // minSize: 20000,
    // maxSize: 800000,
    onDrop: (acceptedFiles: any) => {
      const res = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFiles(res);
      uploadImages(res);

      setUploaded(false);
    },
    onDropRejected: () => {
      setError("Upload Failed");
      setTimeout(() => {
        setError("");
      }, 3000);
    },
  });

  const uploadImages = async (fs: any) => {
    // console.log(fs);
    try {
      if (fs.length === 0) {
        return false;
      }
      // console.log(images);
      setUploading(true);

      if (multiple === true) {
        let imagesUrls: string[] = [];
        let imageLinks = values ? [...values] : [];
        console.log(imageLinks);
        for (const image of fs) {
          let data = new FormData();
          data.append("files", image);
          data.append("path", "ads");
          const res = await axios.post(`${apiLink}/upload`, data, {
            withCredentials: true,
          });
          imagesUrls.push(res.data[0].url);
          const imageObj = {
            id: "",
            image: res.data[0].url,
          };
          imageLinks.push(imageObj);
          console.log("object");
        }
        console.log(imageLinks);
        if (handleImageUpload) {
          handleImageUpload((prevImages: any) => [
            ...prevImages,
            ...imagesUrls,
          ]);
        } else if (handleSetFieldValue && handleFieldName) {
          handleSetFieldValue(handleFieldName, imageLinks);
        }
      } else {
        let data = new FormData();
        data.append("files", fs[0]);

        const res = await axios.post(`${apiLink}/upload`, data, {
          withCredentials: true,
        });
        if (handleImageUpload) {
          handleImageUpload(res.data[0].url);
        } else if (handleSetFieldValue && handleFieldName) {
          handleSetFieldValue(handleFieldName, res.data[0].url);
        }
      }

      setUploading(false);
      setUploaded(true);

      // displayPopMessage(`Upload Successfull`, 4000);
    } catch (err: any) {
      setError("Upload Failed");
      setTimeout(() => {
        setError("");
      }, 2000);
      setUploading(false);
      setUploaded(false);
      console.log(err);
    }
  };

  const thumbs = uploading ? (
    <Loading />
  ) : (
    files.map((file: any) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img alt={file.name} src={file.preview} style={img} />
        </div>
      </div>
    ))
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button colorScheme="blue" leftIcon={<FiUpload />}>
          <chakra.p
            letterSpacing="wide"
            fontSize="sm"
            fontWeight="normal"
            fontFamily={"Lexend"}
          >
            {text}
          </chakra.p>
        </Button>
      </div>
      <div>
        <aside
          style={{
            flexWrap: "wrap",
          }}
        >
          {/* {thumbs} */}
        </aside>
        <aside style={thumbsContainer}></aside>
      </div>
      <div style={{ color: "red" }}>{error}</div>
    </>
  );
};

export default FileUpload;
