import Loading from "components/Misc/Loading";
import StudentPaymentForm from "components/Student/StudentPaymentForm";
import React from "react";
import { useRouteMatch } from "react-router-dom";

interface Props {}

const StudentPaymentFormPage: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(true);
  const match = useRouteMatch("/admin/add-student-payment/:id");

  //@ts-ignore
  const id = match?.params.id;

  React.useEffect(() => {
    if (id) {
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return <StudentPaymentForm id={parseInt(id)} />;
};

export default StudentPaymentFormPage;
