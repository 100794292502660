import { Box, Flex, Image, Text, useRadioGroup } from "@chakra-ui/react";
import { AiTwotoneSound } from "react-icons/ai";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { CheckIcon, CrossIcon } from "components/Icons/Icons";
import RadioCard from "components/Misc/FormComponents/RadioCard";
import { api, apiLink } from "config";
import React from "react";
import { PaperQuiz } from "types/apiTypes";
import { convertUserChoiceToBoolean } from "utils/functions";

interface Props {
  quiz: PaperQuiz;
  setAnswer?: (quizId: number, choice: string) => void;
  isReviewMode?: boolean;
}

const Quiz: React.FC<Props> = ({ quiz, setAnswer, isReviewMode = false }) => {
  const options = ["1", "0"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    onChange: (answer: string) => {
      if (isReviewMode === false) {
        //@ts-ignore
        setAnswer(quiz.id, answer);
      }
    },
    value: quiz.userChoice || "",
  });

  const group = getRootProps();

  return (
    <Box
      shadow="base"
      p="5"
      w="90%"
      mx="auto"
      mb="8"
      bg="white"
      display="flex"
      alignItems={"center"}
      gridGap="3"
      fontFamily={"Lexend"}
      position="relative"
    >
      <Box pl={isReviewMode ? 2 : 2} flexGrow={2}>
        <Flex gridGap={7} direction={{ base: "column", md: "column" }} mb="8">
          <Flex
            gridGap={isReviewMode ? 4 : 1}
            mt="5"
            justifyContent={"space-between"}
            w="full"
          >
            <Text
              fontSize={"base"}
              position="absolute"
              top="1.5"
              left="1.5"
              bg="yellow.400"
              w="30px"
              h="30px"
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              {quiz.qNo}
            </Text>

            <Text
              fontSize={{ base: "base", md: "lg" }}
              flexGrow={2}
              color={
                isReviewMode
                  ? quiz.isTrue ===
                    convertUserChoiceToBoolean(quiz.userChoice as string)
                    ? "green.500"
                    : "red.500"
                  : "gray.800"
              }
            >
              {quiz.question}
            </Text>

            {isReviewMode && (
              <Flex flexGrow={1} alignSelf="center" justifyContent={"flex-end"}>
                {quiz.isTrue ===
                convertUserChoiceToBoolean(quiz.userChoice as string) ? (
                  <CheckIcon
                    color="green.50"
                    bg="green.500"
                    borderRadius={"full"}
                    w="8"
                    h="8"
                    p="1"
                  />
                ) : (
                  <CrossIcon
                    color="red.50"
                    bg="red.500"
                    borderRadius={"full"}
                    w="8"
                    h="8"
                    p="1"
                  />
                )}
              </Flex>
            )}
          </Flex>
          {quiz.image && (
            <Image
              src={`${api}${quiz.image}`}
              maxHeight={"200px"}
              maxWidth={"200px"}
              w="100%"
              h="100%"
              objectFit="contain"
            />
          )}
        </Flex>
        <Flex direction="row">
          <Flex direction={{ base: "row", md: "row" }} {...group} gridGap="2">
            {options.map((value) => {
              //@ts-ignore
              const radio = getRadioProps({ value });
              const label = value === "1" ? "Vero" : "Falso";
              return (
                <Flex
                  direction={"column"}
                  alignItems="center"
                  gridGap="3"
                  justifyContent={"flex-start"}
                >
                  <RadioCard key={value} {...radio} isReviewMode={isReviewMode}>
                    {label}
                  </RadioCard>

                  {isReviewMode && quiz.isTrue && value === "1" && (
                    <Box
                      fontSize={"sm"}
                      color="green.500"
                      justifyContent={"flex-start"}
                    >
                      Correct Answer
                    </Box>
                  )}
                  {isReviewMode && quiz.isTrue === false && value === "0" && (
                    <Box
                      fontSize={"sm"}
                      color="green.500"
                      justifyContent={"flex-start"}
                    >
                      Correct Answer
                    </Box>
                  )}
                </Flex>
              );
            })}
          </Flex>
          <Box display={{ base: "none", md: "block" }}>
            {isReviewMode && quiz.sinhalaVoiceRecord && (
              <Accordion pt="5" allowToggle>
                <AccordionItem w={{ base: "100%", md: "13%" }}>
                  <h2>
                    <AccordionButton
                      w={{ base: "300px", md: "200px" }}
                      mt="-4"
                      style={{ border: "1px solid black" }}
                      bg="blue.100"
                    >
                      <Box as="span" pr="5" textAlign="left">
                        සිංහල පැහැදිලි කිරීම
                      </Box>
                      <AiTwotoneSound />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg="blue.100">
                    <audio controls>
                      <source
                        src={`${apiLink}${quiz.sinhalaVoiceRecord}`}
                        type="audio/mpeg"
                      />
                      <source
                        src={`${apiLink}${quiz.italianVoiceRecord}`}
                        type="audio/x-m4a"
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>
        </Flex>

        <Box display={{ base: "block", md: "none" }}>
          {isReviewMode && quiz.sinhalaVoiceRecord && (
            <Accordion pt="5" allowToggle>
              <AccordionItem w="70%">
                <h2>
                  <AccordionButton
                    bg="blue.100"
                    style={{ border: "1px solid black" }}
                  >
                    <Box as="span" pr="5" textAlign="left">
                      සිංහල පැහැදිලි කිරීම
                    </Box>
                    <AiTwotoneSound />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <audio controls>
                    <source
                      src={`${apiLink}${quiz.sinhalaVoiceRecord}`}
                      type="audio/mpeg"
                    />
                    <source
                      src={`${apiLink}${quiz.sinhalaVoiceRecord}`}
                      type="audio/x-m4a"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Quiz;
