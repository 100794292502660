import { Badge, Box, Select, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
//@ts-ignore
import dateFormat from "dateformat";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";
import createPersistedState from "use-persisted-state";
import { getErrors } from "utils/functions";
import { api } from "../../config";

const ViewPayments: React.FC = () => {
  const usePaymentState = createPersistedState("sortQueryPayments");
  const [sortQueryPayment, setSortQueryPayment]: any =
    usePaymentState("paidDate:desc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [filterGroup, setFilterGroup] = React.useState<string>("");
  const [filterMonth, setFilterMonth] = React.useState<string>("");
  const [filterStudent, setFilterStudent] = React.useState<string>("");

  const fetchPaymentsAndCount = async (
    start: number,
    limit: number,
    sort: any
  ) => {
    try {
      const { data: payments } = await axios.get(
        `${api}/payments?_limit=${limit}&_start=${start}&_sort=${sort}${
          filterGroup ? `&month.group.id=${filterGroup}` : ""
        }${filterMonth ? `&month.month=${filterMonth}` : ""}
        ${
          filterStudent
            ? `&users_permissions_user.username=${filterStudent}`
            : ""
        }`,
        { withCredentials: true }
      );
      const { data: totalCount } = await axios.get(
        `${api}/payments/count?id_ne=0${
          filterGroup ? `&month.group.id=${filterGroup}` : ""
        }${filterMonth ? `&month.month=${filterMonth}` : ""}
        ${
          filterStudent
            ? `&users_permissions_user.username=${filterStudent}`
            : ""
        }`,
        {
          withCredentials: true,
        }
      );
      return { payments, totalCount };
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { payments: [], totalCount: 0 };
    }
  };

  const fetchGroups = async () => {
    try {
      const { data: groupsData } = await axios.get(`${api}/groups`, {
        withCredentials: true,
      });
      setGroups(groupsData);
      // console.log(groupsData);
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };

  const handleSelectGroup = (group: any) => {
    setFilterGroup(group.target.value);
    //@ts-ignore
    tableRef.current.onQueryChange();
  };

  const handleSelectMonth = (Month: any) => {
    setFilterMonth(Month.target.value);
    //@ts-ignore
    tableRef.current.onQueryChange();
  };

  const handleSelectStudent = (Student: any) => {
    setFilterStudent(Student.target.value);
    //@ts-ignore
    tableRef.current.onQueryChange();
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure?")) {
      try {
        await axios.delete(`${api}/payments/${id}`, {
          withCredentials: true,
        });

        toast({
          title: "Payment Deleted Successfully",
          position: "bottom-right",
          status: "success",
        });

        //@ts-ignore
        tableRef.current.onQueryChange();
      } catch (err: any) {
        const errors = getErrors(err);
        console.log(errors);
        errors.forEach((e) => {
          toast({
            title: e,
            position: "bottom-right",
            status: "error",
          });
        });
      }
    }
  };

  React.useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <>
      <Box
        gridTemplateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        display="grid"
        gridGap={{ base: 3, md: 6 }}
        my={{ baes: 2, md: 6 }}
        bg="blue.100"
        p="2"
        shadow="base"
        py="3"
      >
        <Box>
          <Text>Filter by a class</Text>
          <Select
            placeholder="Select a class"
            onChange={(e) => handleSelectGroup(e)}
            bg="white"
          >
            {groups.map((group, index) => (
              <option key={group.id} value={group.id}>
                {group.groupName}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text>Filter by a month</Text>
          <Select
            placeholder="Select a month"
            onChange={(e) => handleSelectMonth(e)}
            bg="white"
          >
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text>Filter by a Student</Text>
          <Select
            placeholder="Select a class first"
            onChange={(e) => handleSelectStudent(e)}
            bg="white"
          >
            {filterGroup !== "" ? (
              groups
                .filter((x) => x.id.toString() === filterGroup)[0]
                .users_permissions_users.map((user, index) => (
                  <option key={index} value={user.username}>
                    {user.username}
                  </option>
                ))
            ) : (
              <></>
            )}
          </Select>
        </Box>
      </Box>
      <Box mt="3">
        <MaterialTable
          tableRef={tableRef}
          title="Payments"
          columns={[
            { title: "Group", field: "groupName", sorting: false },
            {
              title: "Codice",
              field: "username",
              sorting: false,
            },
            { title: "Month", field: "month", sorting: false },
            { title: "Paid Date", field: "paidDate", sorting: false },
            {
              title: "Paid Amount",
              field: "paidAmount",
              sorting: false,
              type: "currency",
              currencySetting: { locale: "eur", currencyCode: "eur" },
            },
            {
              title: "Payment Type",
              field: "type",
              sorting: false,
              render: (rowData) => (
                <>
                  {rowData.type === "BS" ? (
                    <Badge bg="blue.100">Receipt Uploaded</Badge>
                  ) : rowData.type === "PH" ? (
                    <Badge bg="green.100">Payhere Payment</Badge>
                  ) : (
                    <Badge bg="yellow.100">Stripe Card Payment</Badge>
                  )}
                </>
              ),
            },
            {
              title: "Receipt",
              field: "slip",
              sorting: false,
              render: (rowData) => (
                <>
                  {rowData.slip ? (
                    <a href={`${api}${rowData.slip}`} target="_blank">
                      <img
                        src={`${api}${rowData.slip}`}
                        style={{ width: 50 }}
                      />
                    </a>
                  ) : (
                    <>-</>
                  )}
                </>
              ),
            },
          ]}
          options={{
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
            search: false,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: "delete",
              tooltip: "Delete Payment",
              onClick: (event, rowData: any) => {
                handleDelete(rowData.id);
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchPaymentsAndCount(
                query.page * query.pageSize,
                query.pageSize,
                sortQueryPayment
              )
                .then(({ payments, totalCount }) => {
                  const newData = payments.map((item: any) => ({
                    username: item.users_permissions_user.username,
                    month: `${months[item.month.month - 1]} ${item.month.year}`,
                    paidDate: dateFormat(
                      item.paidDate,
                      "yyyy-mm-dd, h:MM:ss TT"
                    ),
                    paidAmount: item.paidAmount,
                    type: item.type,
                    groupName: item.month.group.groupName,
                    slip: item.slip,
                    id: item.id,
                  }));

                  return resolve({
                    data: newData,
                    page: query.page,
                    totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </Box>
    </>
  );
};

export default ViewPayments;
