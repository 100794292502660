// Chakra Imports
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useToast
} from "@chakra-ui/react"
import axios from "axios"
// import GitHubButton from "react-github-btn";
import { Separator } from "components/Separator/Separator"
import { api } from "config"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FaTwitter, FaFacebook } from "react-icons/fa"
import { useLocation } from "react-router-dom"
import { getRole } from "utils/functions"

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props
  const [switched, setSwitched] = useState(props.isChecked)

  const { colorMode, toggleColorMode } = useColorMode()
  // Chakra Color Mode
  let fixedDisplay = "flex"
  if (props.secondary) {
    fixedDisplay = "none"
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  )
  let colorButton = useColorModeValue("white", "gray.700")
  const secondaryButtonBg = useColorModeValue("white", "transparent")
  const secondaryButtonBorder = useColorModeValue("gray.700", "white")
  const secondaryButtonColor = useColorModeValue("gray.700", "white")
  const settingsRef = React.useRef()

  const location = useLocation()
  const toast = useToast()
  const role = getRole() // localStorage.getItem("role");
  const [noti, setNoti] = useState()

  useEffect(() => {
    const getLinkNotification = async () => {
      try {
        const res = await axios.get(`${api}/group-links/link-notification`, {
          withCredentials: true
        })
        console.log(res)
        console.log(location)
        if (
          res.data &&
          location.pathname &&
          location.pathname.substring(0, 12) !== "/class-links"
        ) {
          setNoti(res.data)
        } else {
          setNoti(null)
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (role !== "Client") {
      getLinkNotification()
    }
  }, [location.pathname, toast, role, location])

  return (
    <>
      {noti && (
        <Alert
          position={"fixed"}
          bottom={2}
          translateX="-50%"
          left={0}
          zIndex={999}
          right={0}
          onClick={() => {
            window.location.href = noti.generatedLink
          }}
          display="flex"
          flexDir={"column"}
          boxShadow="lg"
          w={{ base: "80%", md: "30%" }}
          mx="auto"
          bg="gray.50"
          borderRadius={"md"}
          // color="white"
          border="2px"
          borderBottom={"8px"}
          borderColor={"red.500"}
          cursor="pointer"
        >
          <Flex
            justifyContent={"space-around"}
            flexDir={{ base: "column", md: "row" }}
            alignItems="center"
            w="full"
          >
            <Flex
              bg="red.600"
              alignItems={"center"}
              px="3"
              py="1"
              rounded="full"
            >
              <Box color="white" fontWeight={"bold"}>
                LIVE
              </Box>
              <Box
                bg="red.600"
                ml="1"
                rounded={"full"}
                border="2px"
                borderColor={"white"}
                className="animate-ping"
              >
                <Box bg="white" m="0.5" w="4" h="4" rounded={"full"}></Box>
              </Box>
            </Flex>
            <Box>
              <AlertTitle w="full" textAlign="center">
                Join to Class / පංතියට පිවිසෙන්න.
              </AlertTitle>
              <Box>{new Date(noti.datetime).toUTCString()}</Box>
            </Box>
          </Flex>
        </Alert>
      )}
    </>
  )
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool
}
