import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";

const ViewStudents: React.FC = () => {
  const useCounterState = createPersistedState("sortQueryStudent");
  const [sortQueryStudent, setSortQueryStudent]: any =
    useCounterState("username:asc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();

  const fetchStudentsAndCount = async (
    start: number,
    limit: number,
    sort: any,
    studentName: string
  ) => {
    try {
      const { data: students } = await axios.get(
        `${api}/users?role=1&_limit=${limit}&_start=${start}&_sort=${sort}&username_contains=${
          studentName || ""
        }`,
        { withCredentials: true }
      );
      const { data: totalCount } = await axios.get(`${api}/users/count`, {
        withCredentials: true,
      });
      return { students, totalCount };
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { students: [], totalCount: 0 };
    }
  };

  const handleEditClick = (id: string) => {
    history.push(`/admin/student-form/${id}`);
  };

  const handlePaymentClick = (id: string) => {
    history.push(`/admin/add-student-payment/${id}`);
  };

  const handleAddClick = () => {
    history.push("/admin/student-form");
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Student
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Students"
          columns={[
            { title: "Codice", field: "username", sorting: false },
            { title: "Email", field: "email", sorting: false },
            {
              title: "Inactive",
              field: "blocked",
              sorting: false,
              render: (rowData) =>
                rowData.blocked ? (
                  <Box color="red.500">Inactive</Box>
                ) : (
                  <Box color="green.500">Active</Box>
                ),
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                {/* <MaterialTableCustomSort
                  sort={sortQueryStudent}
                  setSort={setSortQueryStudent}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Student",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
            {
              icon: "paid",
              tooltip: "Add Payment",
              onClick: (event, rowData: any) => {
                handlePaymentClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchStudentsAndCount(
                query.page * query.pageSize,
                query.pageSize,
                sortQueryStudent,
                query.search
              )
                .then(({ students, totalCount }) => {
                  const newData = students.map((item: any) => ({
                    id: item.id,
                    username: item.username,
                    email: item.email,
                    blocked: item.blocked,
                  }));

                  return resolve({
                    data: newData,
                    page: query.page,
                    totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewStudents;
