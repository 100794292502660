import {
  Box,
  Button,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import GoBackButton from "components/Misc/GoBackButton";
import { api } from "config";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

interface Props {}

const GroupPapers: React.FC<Props> = () => {
  const history = useHistory();
  const match = useRouteMatch<any>("/admin/group-papers/:id");
  const groupId = match?.params?.id;
  const [papers, setPapers] = React.useState([]);

  React.useEffect(() => {
    const getPapers = async () => {
      try {
        const response = await axios.get(`${api}/groups/papers/${groupId}`, {
          withCredentials: true,
        });
        setPapers(response.data);
      } catch (error: any) {}
    };
    getPapers();
  }, [groupId]);

  const handleClickPaper = (id: number) => {
    history.push(`/admin/paper-summary/${groupId}/${id}`);
  };

  return (
    <Box>
      <GoBackButton />
      <Heading p="2">Papers</Heading>
      <TableContainer bg="white" shadow="base">
        <Table variant="simple">
          <TableCaption>Papers</TableCaption>
          <Thead>
            <Tr>
              <Th>Code</Th>
              <Th>Topic</Th>
            </Tr>
          </Thead>
          <Tbody>
            {papers.map((p: any, i) => (
              <Tr key={p.id}>
                <Td>{p.code}</Td>
                <Td>{p.topic}</Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => handleClickPaper(p.id)}
                  >
                    View
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Code</Th>
              <Th>Topic</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GroupPapers;
