import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import Quiz from "./Quiz";

interface Props {
  paperConfig: any;
  setPaperConfig: any;
}

const ReviewPaper: React.FC<Props> = ({ paperConfig, setPaperConfig }) => {
  const paper = paperConfig.paper;
  const topicRef = useRef<any>(null);

  useEffect(() => {
    return () => setPaperConfig({ stage: 0, paper: null });
  }, []);

  useEffect(() => {
    if (topicRef.current) {
      topicRef.current.scrollIntoView();
    }
  }, []);

  const handleRestart = () => {
    setPaperConfig({ stage: 0, paper: null });
  };

  return (
    <Box fontFamily={"Lexend"}>
      <Text
        textAlign={"center"}
        fontSize={{ base: "2xl", md: "3xl" }}
        py="6"
        fontWeight={"bold"}
        color="orange.500"
        ref={topicRef}
      >
        {paper.topic} - Review
      </Text>
      <Flex justifyContent="center" py="2">
        <Text
          textAlign={"center"}
          fontSize={{ base: "lg", md: "2xl" }}
          border="1px solid"
          borderRadius={"full"}
          p="4"
          borderColor={"green"}
          display="inline-block"
          mb="4"
        >
          Result - {paper.marks} / {paper.noOfQuizzes}
        </Text>
      </Flex>
      <Box>
        {paper.paper_quizzes.map((q: any) => (
          <Quiz quiz={q} key={q.id} isReviewMode />
        ))}
      </Box>
      <Flex justifyContent={"center"} mb="4">
        <Button
          w="90%"
          // loading={submitting}
          // loadingText="Processing"
          colorScheme={"yellow"}
          onClick={handleRestart}
        >
          Restart
        </Button>
      </Flex>
    </Box>
  );
};

export default ReviewPaper;
