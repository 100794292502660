import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Select, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";

const ViewPermissions: React.FC = () => {
  const useCounterState = createPersistedState("sortQueryPermission");
  const [sortQueryPermission, setSortQueryPermission]: any =
    useCounterState("id:asc");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();
  const [selectedPermissionIds, setSelectedPermissionIds] = React.useState<
    number[]
  >([]);
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [filterGroup, setFilterGroup] = React.useState<string>("");
  const [filterMonth, setFilterMonth] = React.useState<string>("");
  const [filterStudent, setFilterStudent] = React.useState<string>("");

  const fetchPermissionsAndCount = async (
    start: number,
    limit: number,
    sort: any
  ) => {
    try {
      const { data: Permissions } = await axios.get(
        `${api}/blocks?_limit=${limit}&_start=${start}&_sort=${sort}${
          filterGroup ? `&month.group.id=${filterGroup}` : ""
        }${filterMonth ? `&month.month=${filterMonth}` : ""}
        ${
          filterStudent
            ? `&users_permissions_user.username=${filterStudent}`
            : ""
        }`,
        { withCredentials: true }
      );
      const { data: totalCount } = await axios.get(
        `${api}/blocks/count?id_ne=0${
          filterGroup ? `&month.group.id=${filterGroup}` : ""
        }${filterMonth ? `&month.month=${filterMonth}` : ""}
        ${
          filterStudent
            ? `&users_permissions_user.username=${filterStudent}`
            : ""
        }`,
        {
          withCredentials: true,
        }
      );
      return { Permissions, totalCount };
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
      return { Permissions: [], totalCount: 0 };
    }
  };

  const handleAddClick = () => {
    history.push("/admin/add-permission");
  };
  const handleSubmit = async () => {
    try {
      const idList = selectedPermissionIds.join(",");
      const resp = await axios.delete(`${api}/blocks/${idList}`, {
        withCredentials: true,
      });
      toast({
        title: "Permissions Deleted",
        position: "bottom-right",
        status: "success",
      });
      // @ts-ignore
      tableRef.current.onQueryChange();
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  const handleSelectPermissions = (rows: any) => {
    setSelectedPermissionIds(rows.map((row: any) => row.id));
  };

  const fetchGroups = async () => {
    try {
      const { data: groupsData } = await axios.get(`${api}/groups`, {
        withCredentials: true,
      });
      setGroups(groupsData);
      // console.log(groupsData);
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  React.useEffect(() => {
    fetchGroups();
  }, []);
  const handleSelectGroup = (group: any) => {
    setFilterGroup(group.target.value);
    //@ts-ignore
    tableRef.current.onQueryChange();
  };
  const handleSelectMonth = (Month: any) => {
    setFilterMonth(Month.target.value);
    //@ts-ignore
    tableRef.current.onQueryChange();
  };
  const handleSelectStudent = (Student: any) => {
    setFilterStudent(Student.target.value);
    //@ts-ignore
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        fontSize={{ base: "xs", md: "sm" }}
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Permission
      </Button>
      <Button
        colorScheme="red"
        onClick={() => {
          history.push("/admin/add-monthly-blockings");
        }}
        m="3"
        size="sm"
        fontSize={{ base: "xs", md: "sm" }}
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Monthly Manual Payment Blockings
      </Button>
      <Box
        gridTemplateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        display="grid"
        gridGap={{ base: 3, md: 6 }}
        my={{ baes: 2, md: 6 }}
        bg="blue.100"
        p="2"
        shadow="base"
      >
        <Box>
          <Text>Filter by a class</Text>
          <Select
            placeholder="Select a class"
            onChange={(e) => handleSelectGroup(e)}
            bg="white"
          >
            {groups.map((group, index) => (
              <option key={group.id} value={group.id}>
                {group.groupName}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text>Filter by a month</Text>
          <Select
            placeholder="Select a month"
            onChange={(e) => handleSelectMonth(e)}
            bg="white"
          >
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text>Filter by a Student</Text>
          <Select
            placeholder="Select a class first"
            onChange={(e) => handleSelectStudent(e)}
            bg="white"
          >
            {filterGroup !== "" ? (
              groups
                .filter((x) => x.id.toString() === filterGroup)[0]
                .users_permissions_users.map((user, index) => (
                  <option key={index} value={user.username}>
                    {user.username}
                  </option>
                ))
            ) : (
              <></>
            )}
          </Select>
        </Box>
      </Box>
      <Box mt="3">
        <MaterialTable
          tableRef={tableRef}
          title="Permissions"
          columns={[
            { title: "Group", field: "groupName", sorting: false },
            { title: "Month", field: "month", sorting: false },
            {
              title: "Codice",
              field: "username",
              sorting: false,
            },
          ]}
          options={{
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
            selection: true,
            search: false,
          }}
          onSelectionChange={(rows) => handleSelectPermissions(rows)}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchPermissionsAndCount(
                query.page * query.pageSize,
                query.pageSize,
                sortQueryPermission
              )
                .then(({ Permissions, totalCount }) => {
                  const newData = Permissions.map((item: any) => ({
                    groupName: item.month.group.groupName,
                    month: months[item.month.month - 1],
                    username: item.users_permissions_user.username,
                    id: item.id,
                  }));

                  return resolve({
                    data: newData,
                    page: query.page,
                    totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </Box>
      <Box my="5" display="flex" justifyContent="center">
        <Button
          onClick={handleSubmit}
          colorScheme="red"
          isDisabled={selectedPermissionIds.length ? false : true}
        >
          Remove restrictions
        </Button>
      </Box>
    </>
  );
};

export default ViewPermissions;
