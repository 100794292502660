import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  HStack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { FieldProps, getIn } from "formik";
import React from "react";

interface Props extends FieldProps {
  label: string;
  labelProps?: FormLabelProps;
  containerProps?: FormControlProps;
  options: any;
}

const ChakraRadio: React.FC<Props> = ({
  field,
  form,
  label,
  labelProps,
  containerProps,
  options,
  ...props
}) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <FormControl isInvalid={!!errorText} my="2" {...containerProps}>
      <FormLabel
        fontFamily="Lexend, sans-serif"
        color="gray.700"
        htmlFor={`${field.name}-id`}
        {...labelProps}
      >
        {label}
      </FormLabel>
      <RadioGroup
        // defaultValue="P"
        id={`${field.name}-id`}
        shadow="sm"
        {...field}
        {...props}
      >
        <HStack spacing="24px">
          {options.map((o: any) => (
            <Radio value={o.value}>{o.label}</Radio>
          ))}
        </HStack>
      </RadioGroup>
      <FormErrorMessage>{errorText}</FormErrorMessage>
    </FormControl>
  );
};

export default ChakraRadio;
