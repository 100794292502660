import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { months } from "components/Group/GroupClassBox";
import ChakraInput from "components/Misc/FormComponents/ChakraInput";
import ChakraTextArea from "components/Misc/FormComponents/ChakraTextArea";
import CheckboxFormField from "components/Misc/FormComponents/CheckboxFormField";
import GoBackButton from "components/Misc/GoBackButton";
import { Field, Form, Formik } from "formik";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Group } from "types/apiTypes";
import { uuid } from "uuidv4";
import * as yup from "yup";
import { api, frontendLink } from "../../config";
//@ts-ignore
import dateFormat from "dateformat";

const schema = yup.object({
  topic: yup.string().required("Topic is Required"),
  zoomLink: yup.string().required("Zoom Link is Required"),
  // meetingId: yup.string().required("Meeting ID is Required"),
  datetime: yup.string().required("Date & Time is Required"),
  generatedLink: yup.string(),
});

interface Props {
  classLink?: any;
}

const AddClassLink: React.FC<Props> = ({ classLink }) => {
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = React.useState<number>();
  const [newMonthIndexes, setNewMonthIndexes] = React.useState<number[]>([]);
  const [monthId, setMonthId] = React.useState<number>(-1);
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([]);
  const tableRef = React.useRef(null);
  const history = useHistory();
  const toast = useToast();
  let isEdit = classLink ? true : false;

  const fetchGroups = async () => {
    try {
      const { data: groupsData } = await axios.get(`${api}/groups`, {
        withCredentials: true,
      });
      setGroups(groupsData);
      // console.log(groupsData);
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };

  React.useEffect(() => {
    fetchGroups();
  }, []);

  React.useEffect(() => {
    if (groups.length && isEdit) {
      setSelectedGroupIndex(
        groups.findIndex((g) => g.id === classLink.month.group)
      );
      setMonthId(classLink.month.id);
    }
  }, [groups]);

  const handleSelectUsers = (rows: any) => {
    setSelectedUserIds(rows.map((row: any) => row.id));
  };

  const handleSubmit = async (values: any) => {
    try {
      if (selectedGroupIndex === undefined || isNaN(selectedGroupIndex)) {
        throw new Error("Please select a class");
      }
      if (monthId === -1) {
        throw new Error("Please select a month");
      }
      const users = selectedUserIds.map((u) => ({ users_permissions_user: u }));
      console.log(values);
      if (isEdit) {
        await axios.put(
          `${api}/group-links/${classLink.id}`,
          { ...values, month: monthId, users },
          {
            withCredentials: true,
          }
        );
      } else {
        // const avail = await axios.get(`${api}/group-links?month=${monthId}`, {
        //   withCredentials: true,
        // });
        // if (avail.data.length) {
        //   toast({
        //     title: "Link already added",
        //     position: "bottom-right",
        //     status: "error",
        //   });
        //   return;
        // }
        await axios.post(
          `${api}/group-links`,
          { ...values, month: monthId, users },
          {
            withCredentials: true,
          }
        );
      }
      toast({
        title: `Successfully ${isEdit ? "Updated" : "Added"}`,
        position: "bottom-right",
        status: "success",
      });
      history.push("/admin/view-class-links");
    } catch (error: any) {
      toast({
        title: error.message,
        position: "bottom-right",
        status: "error",
      });
    }
  };
  const handleSelectGroup = (e: any) => {
    setSelectedGroupIndex(parseInt(e.target.value));
    setSelectedUserIds([]);
    setNewMonthIndexes([]);
    setMonthId(-1);
  };

  const tableData = React.useMemo(() => {
    let allowedUsers: any = [];
    if (isEdit) {
      allowedUsers = classLink.gl_allowed_users.map(
        (u: any) => u.users_permissions_user
      );
    }
    const data =
      selectedGroupIndex !== undefined
        ? groups[selectedGroupIndex].users_permissions_users.map((u) =>
            allowedUsers.includes(u.id)
              ? { ...u, tableData: { checked: true } }
              : u
          )
        : [];
    return data;
  }, [selectedGroupIndex, groups]);

  console.log();
  return (
    <>
      <div>
        <GoBackButton />
        <Box bg="white" p="3" shadow="base">
          <Heading p="2">{isEdit ? "Update" : "Add"} Class Link</Heading>
          <Formik
            onSubmit={(values) => handleSubmit(values)}
            initialValues={{
              topic: isEdit ? classLink?.topic : "",
              zoomLink: isEdit ? classLink?.zoomLink : "",
              generatedLink: isEdit
                ? classLink?.generatedLink
                : `${frontendLink}/class-links/${uuid()}`,
              meetingId: isEdit ? classLink?.meetingId : "",
              passCode: isEdit ? classLink?.passCode : "",
              datetime: isEdit
                ? dateFormat(classLink?.datetime, "yyyy-mm-dd'T'H:MM")
                : dateFormat(new Date(), "yyyy-mm-dd'T'H:MM"),
              showNotification: isEdit ? classLink?.showNotification : false,
            }}
            validationSchema={schema}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap="4">
                  <Field
                    name="topic"
                    component={ChakraInput}
                    label="Topic"
                    fullWidth
                    autoFocus
                  />
                  <Field
                    name="zoomLink"
                    component={ChakraInput}
                    label="Zoom Link"
                    fullWidth
                  />
                  <Field
                    name="meetingId"
                    component={ChakraInput}
                    label="Meeting ID"
                    fullWidth
                  />
                  <Field
                    name="passCode"
                    component={ChakraInput}
                    label="Pass Code"
                    fullWidth
                  />
                  <Field
                    name="datetime"
                    component={ChakraInput}
                    label="Date & Time"
                    type="datetime-local"
                    fullWidth
                  />
                  <Field
                    name="showNotification"
                    component={CheckboxFormField}
                    label="Show Notification"
                    type="checkbox"
                    fullWidth
                  />
                  <Field
                    name="generatedLink"
                    component={ChakraTextArea}
                    label="Generated Link (Copy This)"
                    isReadOnly={true}
                    fullWidth
                    onClick={() => {
                      navigator.clipboard.writeText(values.generatedLink);
                      toast({
                        title: "Copied",
                        position: "bottom-right",
                        status: "success",
                      });
                    }}
                  />
                  <div></div>
                  <Box my="5">
                    <Text>Select Class</Text>
                    <Select
                      placeholder="Select Class"
                      onChange={(e) => handleSelectGroup(e)}
                      value={selectedGroupIndex}
                    >
                      {groups.map((group, index) => (
                        <option key={group.id} value={index}>
                          {group.groupName}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box my="5">
                    <Text>Select Month</Text>
                    <HStack spacing={4}>
                      <Select
                        placeholder="Select month"
                        onChange={(e) => setMonthId(parseInt(e.target.value))}
                        value={monthId}
                      >
                        {selectedGroupIndex !== undefined ? (
                          groups[selectedGroupIndex].months?.map(
                            (month, index) => {
                              return (
                                <option key={month.id} value={month.id}>
                                  {months[month.month - 1]}
                                </option>
                              );
                            }
                          )
                        ) : (
                          <> </>
                        )}
                      </Select>
                    </HStack>
                  </Box>
                </Grid>
                <Box>
                  <MaterialTable
                    columns={[
                      {
                        title: "Codice",
                        field: "username",
                      },
                      {
                        title: "Nome",
                        field: "name",
                      },
                      {
                        title: "Email",
                        field: "email",
                      },
                    ]}
                    options={{
                      selection: true,
                      // selectionProps: (rowData: any) => ({
                      //   checked: allowedUsers.includes(rowData.id),
                      // }),
                    }}
                    onSelectionChange={(rows) => handleSelectUsers(rows)}
                    title="Select Extra Allowed Students"
                    data={tableData}
                  />
                </Box>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Button colorScheme="blue" w="full" type="submit">
                  Submit Link
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </>
  );
};

export default AddClassLink;
